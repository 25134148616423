define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/LinkingPinsModule/collections/JobSchedule',
  'upx.modules/BillingIntegrationModule/collections/SendInvoiceEmail',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  JobScheduleCollection, SendInvoiceEmailCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.BOOKKEEPING_EMAIL_INTEGRATION_APP_ID,
      icon: 'fal fa-books',
      title: Locale.translate('setup_bookkeeping_email_integration'),
      summary: Locale.translate('send_email_to_your_bookkeeping_on_each_new_invoice'),
      link_open: 'apps/bookkeeping-email',
      app_name: Feature.APP_NAME_CORE_EMAILONINVOICE_INTEGRATION,
    },
  ),

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getShopMarketingJob(),
      this.getSendInvoiceEmail(),
    ).then((jobModel, emailModel) => {
      const onSuccess = () => LinkModel.prototype.uninstall.call(this).then(
        def.resolve,
        def.reject,
      );
      $.when(
        !jobModel || jobModel.destroy(),
        !emailModel || emailModel.destroy(),
      )
        .then(
          onSuccess,
          def.reject,
        );
    });
    return def.promise();
  },

  getShopMarketingJob() {
    const def = new $.Deferred();

    const jobScheduleCollection = new JobScheduleCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'job_type/module_name__=',
        val: 'BillingIntegrationModule',
      }],
    };

    jobScheduleCollection.fetch({ params })
      .then(() => {
        def.resolve(jobScheduleCollection.first());
      }, def.reject);

    return def;
  },
  getSendInvoiceEmail() {
    const def = new $.Deferred();

    const sendInvoiceEmailCollection = new SendInvoiceEmailCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
    };

    sendInvoiceEmailCollection.fetch({ params })
      .then(() => {
        def.resolve(sendInvoiceEmailCollection.first());
      }, def.reject);

    return def;
  },
}));
