define([
  'underscore',
  'backbone',
  'modules/common/acls/acl',
  'modules/shop.common/components/commerceAuth',
],
(_, Backbone, Acl, CommerceAuth) => {
  const ThisAcl = Acl.extend({

    initialize(options) {
      // Set prototypes
      Acl.prototype.initialize.call(this, options);

      this.name = options.name;
      if (!this.name) {
        throw new Error('Capability name is required for this ACL');
      }
    },
    authorized() {
      return CommerceAuth.hasRole(this.name);
    },

    error() {
      Backbone.history.navigate('error/forbidden', { trigger: true });
    },

  });
  return _.extend(ThisAcl, {
    ...CommerceAuth.ALL_CAPABILITIES,
  });
});
