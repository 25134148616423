define([
  'jquery',
  'underscore',
  'modules/shop/models/App/WebShopWithSyncApp.js',

  'modules/common/components/locale',
  'modules/shop/components/apps',
  'modules/shop/components/feature',

  'modules/shop/collections/ShopType',
], (
  $, _, WebShopWithSyncApp,
  Locale, AppsComponent, Feature,
  ShopTypeCollection,
) => WebShopWithSyncApp.extend({
  defaults: _.extend(
    {},
    WebShopWithSyncApp.prototype.defaults,
    {
      id: AppsComponent.NEW_WEBSHOP_WITH_SYNC_SHOPTRADER_APP_ID,
      logo: '/images/apps/shoptrader.png',
      icon: 'fal fa-plus',
      title: Locale.translate('shoptrader_advanced'),
      summary: Locale.translate('this_integration_allows_you_to_manage_your_shoptrader_online_shop_s_you_can_take_advantage_of_shoptrader_while_having_storekeeper_for_central_management'),
      flavour: ShopTypeCollection.WEBSHOP_WP_WOOCOMMERCE_FLAVOUR,
      link_open: 'apps/create-webshop-with-sync/shoptrader',
      app_name: Feature.APP_CORE_SHOP_MODULE_WEBSHOP_WITH_SYNCSHOP_TRADER,
    },
  ),
}));
