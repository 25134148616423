define([
  'jquery',
  'upx.modules/BlogModule/collections/AttributeSetAssign',
],
(
  $, UpxCollection,
) => {
  const Collection = UpxCollection.extend({

    offline: false,

    loadParameters: {
      start: 0,
      limit: 1000,
      sort: [
        { name: 'attribute_id', dir: 'asc' },
        { name: 'attribute_set_id', dir: 'asc' },
      ],
    },

    getAttributesIdForSet(attribute_set_id) {
      this.checkIfLoaded();
      return this.where({ attribute_set_id: parseInt(attribute_set_id, 10) }).map(
        (model) => model.get('attribute_id'),
      );
    },

    getByAttributeSetId(attribute_set_id) {
      this.checkIfLoaded();
      return this.where({ attribute_set_id: parseInt(attribute_set_id, 10) });
    },

    getAttributeSetIdForAttribute(attribute_id) {
      this.checkIfLoaded();
      return this.where({ attribute_id: parseInt(attribute_id, 10) }).map(
        (model) => model.get('attribute_set_id'),
      );
    },
  });

  return new Collection();
});
