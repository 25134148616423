define([
  'jquery',
  'upx.modules/CommerceModule/models/Configuration',
  'modules/upx/components/upx',
  'modules/common/components/locale',

], ($, CommerceConfig, Upx, Locale) => {
  const model = CommerceConfig.extend({

    SHELF_CARD_TYPE_A4_45x26: 'a4_45x26',
    SHELF_CARD_TYPE_A4_66x27: 'a4_66x27',

    offline: true,

    loaded: false,
    accountType: null,

    load(force) {
      this.SHELF_CARD_TYPES = [];
      this.SHELF_CARD_TYPES[this.SHELF_CARD_TYPE_A4_45x26] = Locale.translate('a4_45mm_x_26mm');
      this.SHELF_CARD_TYPES[this.SHELF_CARD_TYPE_A4_66x27] = Locale.translate('a4_66mm_x_27mm');

      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('CommerceModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'is_default__=',
              val: 'true',
            }],
          })).then((response) => {
          self.set(response.data[0]);
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    isB2bFocused() {
      return this.get('b2b_focused');
    },

    setB2bFocused(b2b_focused) {
      b2b_focused = !!b2b_focused;
      this.set('b2b_focused', b2b_focused);
      return Upx.call('CommerceModule', 'updateConfiguration',
        {
          fields: { b2b_focused },
          id: this.get('id'),
        });
    },
    /**
         * get array so easier to pass to templates
         * @returns {{val: *, name: *}[]}
         */
    getShelfCardTypesArray() {
      return _.pairs(this.SHELF_CARD_TYPES).map((obj) => ({
        val: obj[0],
        name: obj[1],
      }));
    },

    getShelfCardType() {
      let type = this.get('shelf_card_type');
      if (!type || !(type in this.SHELF_CARD_TYPES)) {
        // empty or not supported
        type = this.SHELF_CARD_TYPE_A4_45x26;
      }
      return type;
    },

    getShelfCardTypeName() {
      const type = this.getShelfCardType();
      return this.SHELF_CARD_TYPES[type];
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },
  });

  return new model();
});
