define([
  'upx.modules/ShopModule/collections/OrderPayment',
  'modules/upx/components/upx',
  'modules/common/components/locale',
  'modules/common/collections/DeepModelCollection',
], (OrderPaymentCollection, Upx, Locale, DeepModelCollection) => ({

  STATUS_OPEN: 'open',
  STATUS_PAID: 'paid',
  STATUS_AUTHORIZED: 'authorized',
  STATUS_EXPIRED: 'expired',
  STATUS_ERROR: 'error',
  STATUS_CANCELLED: 'cancelled',
  STATUS_PARTIAL_REFUND: 'partial_refund',
  STATUS_REFUNDED: 'refunded',
  STATUS_REFUNDING: 'refunding',
  STATUS_VERIFY: 'verify',
  STATUS_PAID_OUT: 'paidout',

  PROVIDER_METHOD_TYPE_ALIAS_TERMINAL_DEVICE: 'TerminalDevice',

  getTranslatedStatus(status) {
    switch (status) {
      case this.STATUS_OPEN:
        return Locale.translate('open');
      case this.STATUS_PAID:
        return Locale.translate('paid');
      case this.STATUS_AUTHORIZED:
        return Locale.translate('authorized');
      case this.STATUS_EXPIRED:
        return Locale.translate('expired');
      case this.STATUS_ERROR:
        return Locale.translate('error');
      case this.STATUS_CANCELLED:
        return Locale.translate('cancelled');
      case this.STATUS_PARTIAL_REFUND:
        return Locale.translate('partially_refunded');
      case this.STATUS_REFUNDED:
        return Locale.translate('refunded');
      case this.STATUS_REFUNDING:
        return Locale.translate('refunding');
      case this.STATUS_VERIFY:
        return Locale.translate('verifying_payment');
      case this.STATUS_PAID_OUT:
        return Locale.translate('paid_out');
    }
  },

  getIsPaymentProduct(name, ppu_wt) {
    return {
      sku: 'PAYMENTS',
      name,
      ppu_wt,
      tax: 0,
      quantity: 1,
      is_payment: true,
    };
  },
  isStatusFinal(status) {
    return [
      this.STATUS_EXPIRED,
      this.STATUS_ERROR,
      this.STATUS_CANCELLED,
      this.STATUS_PARTIAL_REFUND,
      this.STATUS_REFUNDED,
      this.STATUS_REFUNDING,
      this.STATUS_PAID_OUT,
      this.STATUS_PAID,
    ].includes(status);
  },
  isStatusInProgress(status) {
    return !this.isStatusFinal(status);
  },
  isStatusSuccess(status) {
    return [
      this.STATUS_PARTIAL_REFUND,
      this.STATUS_REFUNDED,
      this.STATUS_REFUNDING,
      this.STATUS_PAID_OUT,
      this.STATUS_PAID,
    ].includes(status);
  },

  getPaymentProducts(orderModel, amount) {
    const orderItemCollection = new DeepModelCollection(orderModel.get('order_items'));
    const products = [];
    orderItemCollection.each((model) => {
      const ppu_wt = model.get('ppu_wt');
      products.push(
        {
          sku: model.get('sku'),
          name: model.get('name'),
          ppu_wt,
          tax: model.get('tax'),
          quantity: model.get('quantity'),
          is_payment: model.get('is_payment'),
          is_shipping: model.get('is_shipping'),
          is_discount: model.get('is_discount'),
        },
      );
      const subitems = model.get('sub_items') || model.get('subitems') || [];
      subitems.forEach((item) => {
        products.push(
          {
            sku: item.sku,
            name: item.name,
            ppu_wt: item.ppu_wt,
            tax: item.tax,
            quantity: item.quantity,
            is_payment: item.is_payment,
            is_shipping: item.is_shipping,
            is_discount: item.is_discount,
          },
        );
      });
    });

    const paid = orderModel.get('paid_value_wt');
    if (paid > 0) {
      products.push(
        this.getIsPaymentProduct(
          Locale.translate('other_payments'),
          -paid,
        ),
      );
    }

    const toBePaid = orderModel.getToBePaidWt();
    if (toBePaid > amount) {
      products.push(
        this.getIsPaymentProduct(
          Locale.translate('pay_later'),
          amount - toBePaid,
        ),
      );
    }

    return products;
  },
}));
