var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-header\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTitle") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":8,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCancelTop") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h3 class=\"modal-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"titleIcon") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":44},"end":{"line":7,"column":115}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"titleText") || (depth0 != null ? lookupProperty(depth0,"titleText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"titleText","hash":{},"data":data,"loc":{"start":{"line":7,"column":115},"end":{"line":7,"column":128}}}) : helper)))
    + "</h3>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"modal-title-icon "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"titleIcon") || (depth0 != null ? lookupProperty(depth0,"titleIcon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"titleIcon","hash":{},"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":101}}}) : helper)))
    + "\"></i> ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button type=\"button\" class=\"close\" data-action=\"modal-cancel-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":10,"column":82},"end":{"line":10,"column":89}}}) : helper)))
    + "\"><i class=\"fa fa-times\"></i></button>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"contentText") || (depth0 != null ? lookupProperty(depth0,"contentText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"contentText","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":54}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"modal-footer\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCancel") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAccept") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "            </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"cancelExternalHref") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":36,"column":27}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelClass") || (depth0 != null ? lookupProperty(depth0,"cancelClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelClass","hash":{},"data":data,"loc":{"start":{"line":23,"column":38},"end":{"line":23,"column":53}}}) : helper)))
    + " cancel\" target=\"_blank\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelExternalHref") || (depth0 != null ? lookupProperty(depth0,"cancelExternalHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelExternalHref","hash":{},"data":data,"loc":{"start":{"line":23,"column":84},"end":{"line":23,"column":106}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cancelIconCls") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":32},"end":{"line":26,"column":39}}})) != null ? stack1 : "")
    + "                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelText") || (depth0 != null ? lookupProperty(depth0,"cancelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelText","hash":{},"data":data,"loc":{"start":{"line":27,"column":32},"end":{"line":27,"column":46}}}) : helper)))
    + "\r\n                            </a>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cancelIconCls") || (depth0 != null ? lookupProperty(depth0,"cancelIconCls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cancelIconCls","hash":{},"data":data,"loc":{"start":{"line":25,"column":46},"end":{"line":25,"column":63}}}) : helper)))
    + "\"></i>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelClass") || (depth0 != null ? lookupProperty(depth0,"cancelClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelClass","hash":{},"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":68}}}) : helper)))
    + " cancel\" data-action=\"modal-cancel-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":30,"column":103},"end":{"line":30,"column":110}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cancelIconCls") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":33,"column":35}}})) != null ? stack1 : "")
    + "                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"cancelText") || (depth0 != null ? lookupProperty(depth0,"cancelText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelText","hash":{},"data":data,"loc":{"start":{"line":34,"column":28},"end":{"line":34,"column":42}}}) : helper)))
    + "\r\n                        </button>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cancelIconCls") || (depth0 != null ? lookupProperty(depth0,"cancelIconCls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cancelIconCls","hash":{},"data":data,"loc":{"start":{"line":32,"column":42},"end":{"line":32,"column":59}}}) : helper)))
    + "\"></i>\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"acceptExternalHref") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":53,"column":27}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptClass") || (depth0 != null ? lookupProperty(depth0,"acceptClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptClass","hash":{},"data":data,"loc":{"start":{"line":40,"column":34},"end":{"line":40,"column":49}}}) : helper)))
    + " ok\" target=\"_blank\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptExternalHref") || (depth0 != null ? lookupProperty(depth0,"acceptExternalHref") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptExternalHref","hash":{},"data":data,"loc":{"start":{"line":40,"column":76},"end":{"line":40,"column":98}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"acceptIconCls") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":43,"column":35}}})) != null ? stack1 : "")
    + "                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptText") || (depth0 != null ? lookupProperty(depth0,"acceptText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptText","hash":{},"data":data,"loc":{"start":{"line":44,"column":28},"end":{"line":44,"column":42}}}) : helper)))
    + "\r\n                        </a>\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"acceptIconCls") || (depth0 != null ? lookupProperty(depth0,"acceptIconCls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"acceptIconCls","hash":{},"data":data,"loc":{"start":{"line":42,"column":42},"end":{"line":42,"column":59}}}) : helper)))
    + "\"></i>\r\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <button type=\"button\" class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptClass") || (depth0 != null ? lookupProperty(depth0,"acceptClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptClass","hash":{},"data":data,"loc":{"start":{"line":47,"column":53},"end":{"line":47,"column":68}}}) : helper)))
    + " ok\" data-action=\"modal-accept-"
    + alias4(((helper = (helper = lookupProperty(helpers,"cid") || (depth0 != null ? lookupProperty(depth0,"cid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cid","hash":{},"data":data,"loc":{"start":{"line":47,"column":99},"end":{"line":47,"column":106}}}) : helper)))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"acceptIconCls") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":50,"column":35}}})) != null ? stack1 : "")
    + "                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"acceptText") || (depth0 != null ? lookupProperty(depth0,"acceptText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"acceptText","hash":{},"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":51,"column":42}}}) : helper)))
    + "\r\n                        </button>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-dialog "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"modalSize") || (depth0 != null ? lookupProperty(depth0,"modalSize") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"modalSize","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":38}}}) : helper)))
    + "\">\r\n    <div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\r\n    <div class=\"modal-content\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showHeader") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"modal-body\">\r\n            <div data-region=\"alert-box\"></div>\r\n            <div data-region=\"content\"></div>\r\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contentView") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":61}}})) != null ? stack1 : "")
    + "\r\n        </div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFooter") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":56,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n";
},"useData":true});