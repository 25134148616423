define([
  '@storekeeper/sentry',
  'application',
  'jquery',
  'backbone',
  'module',

  'modules/common/components/managers/configuration',

  'modules/common/components/locale',
  'modules/common/components/module',
  'modules/common/components/layout/options',
  'modules/admin/components/sidebar',
  'modules/common/components/idle',
  'modules/shop.common/components/onScreenKeyboard',
  'modules/shop.common/components/browserCompatibility',
  'modules/shop/components/mode',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.common/components/keyboardLayout',

  'modules/shop/routers/shippingMethod',
  'modules/shop/routers/relations',
  'modules/shop/routers/products',

  'modules/shop/routers/stocks',
  'modules/shop/routers/orders',

  'modules/shop/routers/productAttributes',

  'modules/shop/routers/onlinePayments',
  'modules/admin/routers/tasks',
  'modules/shop/routers/payments',
  'modules/shop/routers/translations',

  'modules/shop/routers/configurableProductKind',
  'modules/shop/routers/files',
  'modules/shop/routers/quotes',
  'modules/shop/routers/cashMutations',

  'modules/shop/routers/dashboard',
  'modules/shop/routers/reports',
  'modules/shop/routers/importExport',
  'modules/shop/routers/repairs',

  'modules/shop/routers/invoices',
  'modules/shop/routers/serviceRequests',

  'modules/shop/routers/termsAndConditions',
  'modules/shop/routers/activities',
  'modules/shop/routers/giftCards',
  'modules/shop/routers/cashRegisterSettings',

  'modules/shop/routers/couponCodes',
  'modules/shop/routers/outstandingInvoices',
  'modules/shop/routers/productAddonGroups',
  'modules/shop/routers/import',
  'modules/shop/routers/redirect',
  'modules/shop/routers/home',

  'modules/shop/routers/settings',
  'modules/shop/routers/manualPin',
  'modules/shop/routers/apps',
  'modules/shop/routers/salesChannels',
  'modules/shop/routers/marginReports',
  'modules/shop/routers/intracommunityReport',

  'modules/shop/subscribers/configure/userLogin',
  'modules/shop/subscribers/pwa/sourcesUpdate',
  'modules/shop/subscribers/user/loggedOut',
  'modules/admin/subscribers/error/auth',
  'modules/shop/subscribers/redirect',

  'modules/upx/collections/users',
  'modules/shop/routers/suppliers',
  'modules/shop/routers/profile',
  'modules/shop/routers/outsideEuropeReport',

  'modules/shop/routers/companyInformation',
  'modules/shop/routers/systemUsers',
  'modules/shop/routers/authentication',
  'modules/shop/routers/productGroups',
  'modules/shop/routers/supplierProducts',

  'modules/shop/routers/stockTurnover',
  'modules/shop/routers/stockValuation',
  'modules/shop/routers/errors',
  'modules/shop/routers/hospitality',
  'modules/shop/routers/parcels',
  'modules/shop/routers/sentEmails',
  'modules/shop/routers/scheduledDiscounts',

  'modules/shop/routers/narrowcastTv',
  'modules/shop/routers/loyaltyProgram',
  'modules/shop/routers/mergeIntoConfigurable',
  'modules/shop/routers/featuredAttributes',

  'modules/shop/routers/productLabelPrint',
  'modules/shop/routers/productSerials.js',
  'modules/shop/routers/usedGoods',
  'modules/shop/routers/productImageCdn',

  'modules/shop/routers/shopProductFeed.js',
  'modules/shop/routers/cashRegisterJournals',
  'modules/shop/routers/supplierEdiIntegrationApp.js',
  'modules/shop/routers/fashionUnited',

  'modules/shop/routers/orderWriter',
  'modules/shop/routers/bestseller',
  'modules/shop/routers/featuredAttributeCategories',

  'modules/shop/routers/storeKeeperPayment.js',
  'modules/shop/components/googleAnalytics',
  'modules/shop/components/pendo.js',
  'modules/shop/routers/stockBalancing.js',

  'modules/shop/routers/locations.js',
  'modules/shop/routers/test.js',
  'modules/shop/routers/companyFeed',

  'modules/shop/routers/posOnly.js',
  'modules/shop/routers/customPaymentMethods.js',

  'modules/shop/routers/orderPicking',

  '@fortawesome/fontawesome-free/css/all.css',
  '@fortawesome/fontawesome-free/css/v4-shims.css',
  '@fortawesome/fontawesome-free/css/v5-font-face.css',
  '@fortawesome/fontawesome-pro/css/all.css',
  '@fortawesome/fontawesome-pro/css/v4-shims.css',
  '@fortawesome/fontawesome-pro/css/v5-font-face.css',
  'typeface-montserrat/index.css',

  // add helpers for some reason they do not load automatically
  'modules/shop/templates/helpers/isMultiLocationEnabled.js',
],
(
  SKSentry, App, $, Backbone, Module,
  ConfigurationManager,
  Locale, AppModule, LayoutOptions, SidebarComponent, Idle, OnScreenKeyboard, BrowserCompatibility, Mode, DeviceConfig, KeyboardLayout,
  ShippingMethodRouter, RelationsRouter, ProductsRouter,
  StocksRouter, OrderRouter,
  ProductAttributeRoute,
  OnlinePaymentsRouter, TasksRouter, PaymentsRouter, TranslationsRouter,
  ConfigurableProductKindRoute, FilesRouter, QuotesRoute, CashMutationsRouter,
  DashboardRouter, ReportsRouter, ImportExportRouter, RepairsRouter,
  InvoiceRouter, ServiceRequestsRouter,
  TermsAndConditionsRouter, ActivitiesRouter, GiftCardsRouter, CashRegisterSettingsRouter,
  CouponCodesRouter, OutstandingInvoicesRouter, ProductAddonGroupsRouter, ImportRouter, RedirectRouter, HomeRouter,
  SettingsRouter, ManualPinRouter, AppsRouter, SalesChannelsRouter, MarginReportRouter, IntracommunityReportRouter,
  LoginSubscriber, SourcesUpdateSubscriber, LoggedOutSubscriber, AuthSubscriber, RedirectSubscriber,
  UserCollection, SuppliersRouter, ProfileRouter, OutsideEuropeReportRouter,
  CompanyInformationRouter, SystemUsersRouter, AuthenticationRouter, ProductGroupsRouter, SupplierProductsRouter,
  StockTurnoverRouter, StockValuationRouter, ErrorRouter, HospitalityRouter, ParcelsRouter, SentEmailsRouter, ScheduledDiscountsRouter,
  NarrowcastTvRouter, LoyaltyProgramRouter, MergeIntoConfigurableRouter, FeaturedAttributesRouter,
  ProductLabelPrintRouter, ProductSerialsRouter, UsedGoodsRouter, ProductImageCdnRouter,
  ShopProductFeedRouter, CashRegisterJournalsRouter, SupplierEdiIntegrationAppRouter, FashionUnitedRouter,
  OrderWriterRouter, BestsellerRouter, FeaturedAttributeCategoriesRouter,
  StoreKeeperPaymentRouter, GoogleAnalytics, Pendo, StockBalancingRouter,
  LocationsRouter, TestRouter, CompanyFeedRouter,
  PosOnlyRouter, CustomPaymentMethodsRouter, OrderPickingRouter
) => {
  const module = new AppModule({
    id: 'modules/shop/application',

    initialize() {
      this.routerClasses = {
        invoices: InvoiceRouter,
        relations: RelationsRouter,
        products: ProductsRouter,
        stocks: StocksRouter,
        orders: OrderRouter,
        payments: PaymentsRouter,
        shippingMethod: ShippingMethodRouter,
        onlinePaymentsRouter: OnlinePaymentsRouter,
        tasks: TasksRouter,
        productAttribute: ProductAttributeRoute,
        translationsCenter: TranslationsRouter,
        configurableProductKind: ConfigurableProductKindRoute,
        supplierProductsRouter: SupplierProductsRouter,
        files: FilesRouter,
        stockTurnoverRouter: StockTurnoverRouter,
        importExport: ImportExportRouter,
        serviceRequests: ServiceRequestsRouter,
        quotes: QuotesRoute,
        cashMutations: CashMutationsRouter,
        dashboard: DashboardRouter,
        reports: ReportsRouter,
        suppliers: SuppliersRouter,
        companyInformation: CompanyInformationRouter,
        systemUsers: SystemUsersRouter,
        activitiesRouter: ActivitiesRouter,
        termsAndConditionsRouter: TermsAndConditionsRouter,
        giftCardsRouter: GiftCardsRouter,
        cashRegisterSettingsRouter: CashRegisterSettingsRouter,
        auth: AuthenticationRouter,
        profile: ProfileRouter,
        OutsideEuropeReportRouter,
        couponCodes: CouponCodesRouter,
        outstandingInvoices: OutstandingInvoicesRouter,
        cashRegisterJournals: CashRegisterJournalsRouter,
        productAddonGroups: ProductAddonGroupsRouter,
        importRouter: ImportRouter,
        redirectRouter: RedirectRouter,
        homeRouter: HomeRouter,
        settingsRouter: SettingsRouter,
        appsRouter: AppsRouter,
        productGroupsRouter: ProductGroupsRouter,
        SalesChannelsRouter,
        ErrorRouter,
        hospitalityRouter: HospitalityRouter,
        ParcelsRouter,
        SentEmailsRouter,
        MarginReportRouter,
        IntracommunityReportRouter,
        ScheduledDiscountsRouter,
        LoyaltyProgramRouter,
        RepairsRouter,
        NarrowcastTvRouter,
        MergeIntoConfigurableRouter,
        FeaturedAttributesRouter,
        ProductLabelPrintRouter,
        ProductSerialsRouter,
        UsedGoodsRouter,
        ProductImageCdnRouter,
        SupplierEdiIntegrationAppRouter,
        FashionUnitedRouter,
        StockValuationRouter,
        ShopProductFeedRouter,
        OrderWriterRouter,
        BestsellerRouter,
        FeaturedAttributeCategoriesRouter,
        StockBalancingRouter,
        StoreKeeperPaymentRouter,
        LocationsRouter,
        ManualPinRouter,
        TestRouter,
        PosOnlyRouter,
        CustomPaymentMethodsRouter,
        CompanyFeedRouter,
        OrderPickingRouter
      };

      this.components = {
        sidebar: new SidebarComponent(),
      };
    },

    onStart(settings) {
      Idle.register();
      const loginSubscriberDef = new $.Deferred();
      App.com(ConfigurationManager).get('shop').set(App.settings.shop);
      DeviceConfig.load().then(() => {
        Mode.setMode();
        if (Mode.getMode() === Mode.MODE_KASSA) {
          let onScreenKeyboardSettings = {
            move_input_into_view_offset: -100,
            enabled_by_default: true,
            keyboardLayout: KeyboardLayout.getDefault(),
          };
          const keyboardLayoutConfig = DeviceConfig.getConfig('keyboard_layout');
          if (keyboardLayoutConfig) {
            onScreenKeyboardSettings = {
              move_input_into_view_offset: -100,
              enabled_by_default: keyboardLayoutConfig.get('extra.id') !== KeyboardLayout.TYPE_NONE,
              keyboardLayout: keyboardLayoutConfig.get('extra.id'),
            };
          }

          OnScreenKeyboard.register(onScreenKeyboardSettings);
        }
      }, () => {
        console.error(Locale.translate('failed_to_load_device_config'));
      });

      // Set the correct class on the body to change styling based on the api-url
      BrowserCompatibility.check();

      this.subscribers = {
        'user/loggedOut': new LoggedOutSubscriber(),
        'error/auth': new AuthSubscriber(),
        sources: new SourcesUpdateSubscriber(),
        redirect: new RedirectSubscriber(),
        userLogin: new LoginSubscriber({
          preloadDef: loginSubscriberDef,
        }),
      };

      AppModule.prototype.onStart.call(this, settings);

      GoogleAnalytics.init();
      Pendo.init('85ef97f7-cbe6-42c3-4fd1-add4702dca40');

      if (UserCollection.findWhere({
        active: true,
      })) {
        Backbone.Preloader.queue = Backbone.Preloader.queue || {};
        Backbone.Preloader.queue.shop_data_cache_loader = loginSubscriberDef.promise();

        if ('profile' in Backbone.Preloader.queue) {
          // reject if profile fails
          $.when(Backbone.Preloader.queue.profile).fail(loginSubscriberDef.reject);
        }
      }

      if (App.settings && App.settings.upx && App.settings.upx.account) {
        SKSentry.Sentry.setTag('account', App.settings.upx.account);
      }
    },
  });

  LayoutOptions.set(
    'modules/shop/views/layout/header',
    {
      navCls: 'navbar-inverse navbar-fixed-top',
    },
  );

  return module.register();
});
