define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
  'modules/shop/components/payment',

  'upx.modules/PaymentModule/collections/RaboBankSmartPayProvider',
  'upx.modules/PaymentModule/collections/Payment',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent, Payment,
  ProviderCollection, PaymentCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.RABOBANK_SMART_PAY_APP_ID,
      logo: '/images/apps/rabobank.jpg',
      icon: 'fa-light fa-money-bill',
      app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_RABOBANK_SMART_PAY,
      title: Locale.translate('app[core.PaymentModule.RaboBankSmartPay]title'),
      summary: Locale.translate('app[core.PaymentModule.RaboBankSmartPay]summary'),
      description: Locale.translate('app[core.PaymentModule.RaboBankSmartPay]description'),
      link_open: 'apps/rabobank-smartpay',
    },
  ),
  loadUninstallVars() {
    const def = new $.Deferred();
    this.getProvider().then(
      (provider) => {
        let confirmText = Locale.translate('are_you_sure_that_you_want_to_disconnect_your_{title}_payments', {
          title: this.get('title'),
        });
        let confirmIcon = 'fas fa-exclamation-triangle';

        if (!provider) {
          def.resolve({ confirmText, confirmIcon });
          return;
        }
        const paymentsCollection = new PaymentCollection();
        const params = {
          start: 0,
          limit: 1,
          filters: [
            {
              name: 'provider_id__=',
              val: provider.get('provider_id'),
            },
            {
              name: 'status__in_list',
              multi_val: [
                Payment.STATUS_OPEN,
                Payment.STATUS_AUTHORIZED,
                Payment.STATUS_VERIFY,
              ],
            },
          ],
        };
        paymentsCollection.fetch({ params })
          .then(
            () => {
              if (paymentsCollection.length > 0) {
                confirmText = Locale.translate('there_are_still_ongoing_payments_that_might_not_be_synchronized_if_you_disconnect_your_{title}_payments_app_we_recommend_that_you_wait_for_them_to_be_finishedexpired_proceed', {
                  title: this.get('title'),
                });
                confirmIcon = 'fas fa-exclamation-triangle blink';
              }
              def.resolve({ confirmText, confirmIcon });
            },
            def.reject,
          );
      },
      def.reject,
    );
    return def.promise();
  },

  getUninstallContent({ confirmText }) {
    return confirmText;
  },

  getUninstallPopupOptions({ confirmIcon }) {
    return {
      titleIcon: confirmIcon,
      titleText: Locale.translate('are_you_sure_you_want_to_remove_{title}',
        { title: this.get('title') }),
    };
  },

  uninstall() {
    const def = new $.Deferred();
    this.getProvider().then((provider) => {
      $.when(
        !provider || Upx.call('PaymentModule', 'deleteRaboBankSmartPayProvider',
          {
            provider_id: provider.get('provider_id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  getProvider() {
    const def = new $.Deferred();

    const providerCollection = new ProviderCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'provider_id',
        dir: 'asc',
      }],
      filters: [{
        name: 'provider/deleted__=',
        val: false,
      }],
    };

    providerCollection
      .fetch({ params })
      .then(
        () => {
          def.resolve(providerCollection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
