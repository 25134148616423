define([
        'modules/upx/models/model',
        './structs/ExactOnlineIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BillingIntegrationModule',
            idAttribute: 'id',
            object: 'ExactOnlineIntegration',
            setDivisionCode: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setExactOnlineIntegrationDivisionCode',parameters, ajaxOptions);
            },
            deactivate: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deactivateExactOnlineIntegration',parameters, ajaxOptions);
            }
        });
    }
);