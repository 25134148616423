define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
], (AclRouter, LoggedIn, CapabilityAuthAcl) => AclRouter.extend({

  appRoutes: {
    'system-users': 'systemUsers',
    'system-users/delete/:id': 'deleteSystemUser',
    'system-users/edit/:id': 'editSystemUser',
    'system-users/create': 'createSystemUser',
  },

  acls: [
    new LoggedIn(),

    new CapabilityAuthAcl({
      name: CapabilityAuthAcl.USERS_CAPABILITY,
    }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/systemUsers'], callback);
  },

}));
