var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"navbar "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"navCls") : stack1), depth0))
    + "\">\r\n    <!-- Left Header Navigation -->\r\n    <ul class=\"nav navbar-nav-custom\">\r\n        <!-- Main Sidebar Toggle Button -->\r\n        <li>\r\n            <a href=\"javascript:void(0)\" id=\"menu-toggle\">\r\n                <i class=\"fa fa-th-list fa-fw\"></i>\r\n            </a>\r\n        </li>\r\n        <!-- END Main Sidebar Toggle Button -->\r\n    </ul>\r\n    <!-- END Left Header Navigation -->\r\n\r\n    <!-- Right Header Navigation -->\r\n    <ul class=\"nav navbar-nav-custom pull-right\">\r\n        <li class=\"nav-help "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"help") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":61}}})) != null ? stack1 : "")
    + "\">\r\n            <a href=\"javascript:void(0)\" id=\"help-toggle\">\r\n                <i class=\"fa fa-question-circle fa-2x fa-fw\"></i>\r\n            </a>\r\n        </li>\r\n        <!-- User Dropdown -->\r\n        <li class=\"dropdown\">\r\n            <a href=\"javascript:void(0)\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\r\n                <img src=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"avatarUrl") || (depth0 != null ? lookupProperty(depth0,"avatarUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"avatarUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":26},"end":{"line":24,"column":41}}}) : helper)))
    + "\" alt=\"avatar\"> <i class=\"fa fa-angle-down\"></i>\r\n            </a>\r\n            <ul class=\"dropdown-menu dropdown-custom dropdown-menu-right\">\r\n                <li class=\"dropdown-header text-center\">"
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"account",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":86}}}))
    + "</li>\r\n                <li>\r\n                    <a href=\""
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/appBaseUrl.js").call(alias2,{"name":"common$appBaseUrl","hash":{},"data":data,"loc":{"start":{"line":29,"column":29},"end":{"line":29,"column":50}}}))
    + "#profile\">\r\n                        <i class=\"fa fa-user fa-fw pull-right\"></i>\r\n                        "
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"profile",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":54}}}))
    + "\r\n                    </a>\r\n                    <!--<a href=\""
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/appBaseUrl.js").call(alias2,{"name":"common$appBaseUrl","hash":{},"data":data,"loc":{"start":{"line":33,"column":33},"end":{"line":33,"column":54}}}))
    + "#modal-user-settings\" data-toggle=\"modal\">-->\r\n                        <!--<i class=\"fa fa-cog fa-fw pull-right\"></i>-->\r\n                        <!--"
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"settings",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":35,"column":59}}}))
    + "-->\r\n                    <!--</a>-->\r\n                </li>\r\n                <li>\r\n                    <a href=\""
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/appBaseUrl.js").call(alias2,{"name":"common$appBaseUrl","hash":{},"data":data,"loc":{"start":{"line":39,"column":29},"end":{"line":39,"column":50}}}))
    + "#change-password\">\r\n                        <i class=\"fa fa-key fa-fw pull-right\"></i>\r\n                        "
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"change_password",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":41,"column":62}}}))
    + "\r\n                    </a>\r\n                </li>\r\n                <li class=\"divider\"></li>\r\n                <li>\r\n                    <a href=\""
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/appBaseUrl.js").call(alias2,{"name":"common$appBaseUrl","hash":{},"data":data,"loc":{"start":{"line":46,"column":29},"end":{"line":46,"column":50}}}))
    + "#\" data-action=\"signout\"><i class=\"fa fa-ban fa-fw pull-right\"></i> "
    + alias1(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias2,"logout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":46,"column":118},"end":{"line":46,"column":147}}}))
    + "</a>\r\n                </li>\r\n            </ul>\r\n        </li>\r\n        <!-- END User Dropdown -->\r\n    </ul>\r\n\r\n    <h4 class=\"nav-title "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"title") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":53,"column":25},"end":{"line":53,"column":59}}})) != null ? stack1 : "")
    + "\">"
    + alias1(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":53,"column":61},"end":{"line":53,"column":70}}}) : helper)))
    + "</h4>\r\n    <!-- END Right Header Navigation -->\r\n</header>\r\n";
},"useData":true});