define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/models/Configuration',
  'modules/upx/components/upx',
  'modules/common/components/locale',

  'upx.modules/ShopModule/models/ShopFlatProductByLanguage',
  'upx.modules/ProductsModule/models/FlatProductByLanguage',
], (
  $, _, Backbone, ShopModule, Upx, Locale,
  ShopFlatProductByLanguageModel, FlatProductByLanguageModel,
) => {
  const model = ShopModule.extend({

    offline: true,

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ProductsModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'published__=',
              val: true,
            }],
          })).then((response) => {
          self.set(response.data[0]);
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },

    hasProductDataSheet() {
      return this.has('product_datasheet_url');
    },

    getProductDataSheet(product_id) {
      const flatProductByLanguageModel = new FlatProductByLanguageModel();
      return flatProductByLanguageModel.getDatasheetUrl({
        product_id,
        lang: Locale.getLocale(),
      });
    },

    getShopProductDataSheet(shop_product_id) {
      const shopFlatProductByLanguageModel = new ShopFlatProductByLanguageModel();
      return shopFlatProductByLanguageModel.getDatasheetUrl({
        shop_product_id,
        lang: Locale.getLocale(),
      });
    },
  });

  return new model();
});
