define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',
  'modules/common/components/locale',
], (
  $, _, LinkModel,
  Locale,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {

      icon: 'fal fa-sync',
    },
  ),

  configureModel() {
    this.shopModel = this.get('shopModel');
    this.unset('shopModel');

    this.store();

    LinkModel.prototype.configureModel.call(this);

    this.set({
      id: `sales-channel-${this.shopModel.get('id')}`,
      link_open: `sales-channels/dashboard/${this.shopModel.get('id')}`,
      is_installed: true,
      is_upgradable: false,
      is_visible: true,
    });
  },
  needsFeature() {
    return false;
  },
  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  getExtraButtons() {
    const def = new $.Deferred();

    const buttons = [];
    if (this.get('is_installed')) {
      this.shopModel.fetch().then(
        () => {
          if (this.shopModel.has('hook_id')) {
            buttons.push(this.buildExtraButton({
              title: Locale.translate('sync_is_configured'),
              btnCls: 'btn-success',
              link: `#sales-channels/details/${this.shopModel.get('id')}`,
              iconCls: 'far fa-cog',
            }));
          } else {
            buttons.push(this.buildExtraButton({
              title: Locale.translate('configure_sync'),
              btnCls: 'btn-warning',
              link: `#sales-channels/details/${this.shopModel.get('id')}`,
              iconCls: 'far fa-cog',
            }));
          }
          def.resolve(buttons);
        },
      );
    } else {
      def.resolve(buttons);
    }
    return def.promise();
  },

}));
