define([
  'backbone',
  './../models/user',
  'modules/common/components/appLocalStorage',
],
(Backbone, User, AppLocalStorage) => {
  const users = Backbone.Collection.extend({
    /**
             * When initialized fetch
             */
    initialize() {
      this.fetch();
    },
    /**
             * User model
             */
    model: User,
    /**
             * Store our users in localstorage
             */
    localStorage: AppLocalStorage.get('users'),

    getActiveUser() {
      return this.findWhere({ active: true });
    },

    hasActiveUser() {
      const user = this.getActiveUser();
      return !!user;
    },

    clearActiveData() {
      const self = this;
      this.where({ active: true }).forEach((model) => {
        self.remove(model);
        self.localStorage.destroy(model);
      });
    },

  });

  return new users();
});
