define([
  'backbone',
  'modules/common/routers/aclRouter',
  'modules/admin/acls/loggedOut',
], (Backbone, AclRouter, LoggedOut) => AclRouter.extend({
  appRoutes: {
    'auth/loginByHash(/:data)': 'loginByHash',
    'auth(/:swapTo)': 'auth',
    'passwordRecovery/:hash/:subuser/:subaccount': 'passwordRecovery',
  },

  acls: [
    new LoggedOut(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/authentication'], callback);
  },
}));
