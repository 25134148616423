define([
  'jquery',
  'underscore',
  'modules/shop/models/App/SupplierEdiIntegrationApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
], (
  $, _, SupplierEdiIntegrationAppModel,
  Locale, Feature, Upx, AppsComponent,
) => SupplierEdiIntegrationAppModel.extend({

  defaults: _.extend(
    {},
    SupplierEdiIntegrationAppModel.prototype.defaults,
    {
      id: AppsComponent.FLORA_AT_HOME_INTEGRATION_APP_ID,
      logo: '/images/apps/flora-at-home.png',
      title: Locale.translate('flora_home'),
      summary: Locale.translate('make_orders_using_flora_home_and_deliver_them_straight_to_your_customers'),
      app_name: Feature.APP_NAME_SHOP_MODULE_FLORA_AT_HOME_INTEGRATION,
      type_module_alias: 'FloraAtHome',
    },
  ),

  newIntegration() {
    return Upx.call('ShopModule', 'newFloraAtHomeIntegration');
  },
}));
