var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\r\n    <div class=\"col-md-12\">\r\n        <div class=\"block full\">\r\n            <div class=\"block-title\">\r\n                <h2><strong>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"task_progress",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":64}}}))
    + "</strong></h2>\r\n            </div>\r\n            <div class=\"text-center\">\r\n                <h4>"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":28}}}) : helper)))
    + "</h4>\r\n                <br>\r\n                <div class=\"progress progress-striped active\">\r\n                    <div class=\"progress-bar progress-bar-info\" role=\"progressbar\" style=\"width: 100%\"></div>\r\n                </div>\r\n                <h3>\r\n                    <span id=\"percentage\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"task_execution_last") : depth0)) != null ? lookupProperty(stack1,"perdone") : stack1), depth0))
    + " %</span>\r\n                </h3>\r\n                <small id=\"message\"></small>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});