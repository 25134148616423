var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "\r\n"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"are_you_sure_you_want_to_end_loyalty_program",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":67}}}))
    + "\r\n<ul>\r\n    <li>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"this_cannot_be_undone",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":52}}}))
    + "</li>\r\n    <li>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"all_customer_points_will_be_removed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":66}}}))
    + "</li>\r\n    <li>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"you_can_start_new_loyalty_program_program_any_time_all_customers_will_have_0_point_in_it",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":119}}}))
    + "</li>\r\n    <li>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"you_must_quickreload_all_pos_devices_after_stopping_the_program_to_clean_local_memory",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":116}}}))
    + "</li>\r\n</ul>\r\n";
},"useData":true});