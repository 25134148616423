define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'order-picking': 'overview',
    'order-picking/:order_id(/:swap_to)': 'wizard'
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['../controllers/orderPicking'], callback);
  },
}));
