define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/ShopModule/collections/Shop',
  'modules/shop/collections/ShopType',

  'modules/shop/models/upx/Shop',

  'modules/shop/components/feature',
  'modules/shop.common/components/commerceAuth',
], (
  $, _, Backbone,
  ShopCollection, ShopTypeCollection,
  ShopModel,
  Feature, CommerceAuth,
) => {
  const Collection = ShopCollection.extend({

    offline: false,

    model: ShopModel,
    /**
         * @description Checks if the attribute exists with a similar name, returns false when not loaded.
         * @return {boolean}
         */
    existsByName(name) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        name,
      });
    },

    getDefault() {
      return this.findWhere({ is_default: true });
    },

    getBackoffice() {
      return this.findWhere({ 'shop_type.alias': ShopTypeCollection.ALIAS_BACKOFFICE });
    },

    isOrderPrefixUsed(orderPrefix) {
      orderPrefix = orderPrefix || '';
      return !!this.findWhere({ order_prefix: orderPrefix.toUpperCase() });
    },

    getMainCategoryIds() {
      return this
        .pluck('category_id')
        .filter(
          (n, index, self) => !!n && self.indexOf(n) === index, // non empty + unique
        ) || [];
    },

    isActive(model) {
      return model.get('visible') && !model.get('deleted') && model.get('shop_type.alias') !== ShopTypeCollection.ALIAS_FALLBACK;
    },
    listActiveShops() {
      const result = this.filter((model) => this.isActive(model));

      return new Backbone.Collection(result);
    },

    listActiveShopsWithAuth() {
      const showAll = CommerceAuth.hasRole(CommerceAuth.ALL_CHANNELS_CAPABILITY);
      const showStores = showAll || CommerceAuth.hasRole(CommerceAuth.STORES_CAPABILITY);
      const showWebsite = showAll || CommerceAuth.hasRole(CommerceAuth.WEBSHOPS_CAPABILITY);

      const result = this.filter((model) => {
        if (this.isActive(model)) {
          const alias = model.get('shop_type.alias');
          if (alias === ShopTypeCollection.ALIAS_WEB_WITH_SYNC) {
            return showWebsite;
          }
          if (alias === ShopTypeCollection.ALIAS_POINT_OF_SALE) {
            return showStores;
          }
          return showAll;
        }
        return false;
      });

      return new Backbone.Collection(result);
    },

    listActiveMarketplacesWithAuth() {
      const showAll = CommerceAuth.hasRole(CommerceAuth.ALL_CHANNELS_CAPABILITY);
      const showWebsite = showAll || CommerceAuth.hasRole(CommerceAuth.WEBSHOPS_CAPABILITY);

      const result = this.filter((model) => {
        if (this.isActive(model)) {
          const alias = model.get('shop_type.alias');
          if (alias === ShopTypeCollection.ALIAS_BOL_FLUSE) {
            return showWebsite;
          }
          return showAll;
        }
        return false;
      });

      return new Backbone.Collection(result);
    },
    listActiveStores() {
      const result = this.filter(
        (model) => this.isActive(model)
          && model.get('shop_type.alias') === ShopTypeCollection.ALIAS_POINT_OF_SALE,
      );

      return new Backbone.Collection(result);
    },
    listShopsByLocationId(locationId) {
      const collection = this.listActiveShops();
      if (!Feature.isMultiLocationFeatureEnabled()) {
        return collection;
      }

      return collection
        .filter((model) => model.get('location_id') === locationId && model.get('name'));
    },

    listUsableShops() {
      return this.chain()
        .filter((model) => model.get('shop_type.alias') !== ShopTypeCollection.ALIAS_FALLBACK)
        .map((model) => model.toJSON())
        .value();
    },

    listShopIdsWithTypeAlias(alias) {
      return this.chain()
        .filter((model) => model.get('shop_type.alias') === alias)
        .map((model) => model.get('id'))
        .value();
    },

    listShopsForProductFeed() {
      const types = [
        ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
        ShopTypeCollection.ALIAS_SK_AS_POS,
        ShopTypeCollection.ALIAS_BOL_FLUSE,
      ];
      return this.chain()
        .filter((model) => this.isActive(model) && types.includes(model.get('shop_type.alias')))
        .value();
    },
    listShopsForProductPage() {
      const types = [
        ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
        ShopTypeCollection.ALIAS_WEB,
        ShopTypeCollection.ALIAS_BOL_FLUSE,
      ];
      return this.listActiveShopsWithAuth().chain()
        .filter((model) => types.includes(model.get('shop_type.alias')));
    },

    makeAttributeSelectOption(model) {
      return {
        id: model.get('id'),
        text: model.get('name'),
      };
    },
  });

  return new Collection();
});
