define([
  'backbone',
  'modules/common/routers/aclRouter',
], (Backbone, AclRouter) => AclRouter.extend({
  appRoutes: {
    'test/money-input': 'moneyInput',
    'test/:lang/date-input': 'dateInput',
    'test/loyalty-component': 'loyaltyProgramComponent',
  },

  acls: [],

  getController(callback) {
    return require(['modules/shop/controllers/test'], callback);
  },
}));
