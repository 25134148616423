define([
  'modules/common/routers/aclRouter',
  'modules/admin/controllers/tasks',
  'modules/upx/acls/loggedIn',
], (AclRouter, Controller, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'tasks/progress/:id': 'progress',
  },

  acls: [
    new LoggedIn(),
  ],

  initialize(options) {
    this.controller = new Controller(options);
  },
}));
