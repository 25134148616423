define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
], (
  AclRouter, LoggedIn, CapabilityAuthAcl,
) => AclRouter.extend({

  appRoutes: {
    settings: 'overview',
    'setting/default(/:tabName)': 'defaultSettings',
    'setting/technical(/:tabName)': 'technicalSettings',
    'setting/advanced(/:tabName)': 'advancedSettings',
    'setting/payments': 'redirectPayments',
    'setting/:name': 'details',
  },

  acls: [
    new LoggedIn(),
    new CapabilityAuthAcl({
      name: CapabilityAuthAcl.SETTINGS_CAPABILITY,
    }),
  ],

  overwriteAcls: [{
    routes: [
      'setting/technical(/:tabName)',
    ],
    acls: [
      new LoggedIn(),
      new CapabilityAuthAcl({
        name: CapabilityAuthAcl.TECH_SETTINGS_CAPABILITY,
      }),
    ],
  }],

  getController(callback) {
    return require(['modules/shop/controllers/settings'], callback);
  },
}));
