define([
  'jquery',
  'backbone',
  'communicator',
  'backbone.preloader',
  'backbone.routefilter',
  'backbone.marionette',
  'modules/common/components/appUrl.js',
],

($, Backbone, Communicator, { getAppRoot }) => {
  function loadConfig() {
    if (window.Upx && !!window.Upx.jsConfig) {
      // it's set in the index.html
      const configDef = $.Deferred();
      configDef.resolve(window.Upx.jsConfig);
      return configDef.promise();
    }
    // it needs to be loaded using ajax
    let configUrl;
    if (window.Upx && !!window.Upx.jsConfigUrl) {
      configUrl = window.Upx.jsConfigUrl;
    } else {
      const scripts = document.getElementsByTagName('script');
      const currentTag = scripts[scripts.length - 1];
      const scriptPath = currentTag.src;
      const scriptFolder = scriptPath.substr(0, scriptPath.lastIndexOf('/') + 1);

      configUrl = `${scriptFolder}../static/config.json`;
    }

    const configDef = $.ajax({
      url: configUrl,
      dataType: 'json',
    });
    return configDef.promise();
  }

  const configDef = loadConfig();
  const Application = Backbone.Marionette.Application.extend({
    start(options) {
      const self = this;
      configDef.then((settings) => {
        self.settings = settings;

        self.trigger('config:loaded', settings);
        Backbone.Marionette.Application.prototype.start.call(self, options);

        // Initialize the suite
        const suite = require('suite/suite');
        suite().then(() => {
          // Start Preloader
          Backbone.Preloader.timeout = 60;
          Backbone.Preloader.start();

          // If we have nothing to preload, trigger complete event
          if (Backbone.Preloader.loading === 0) {
            Backbone.Preloader.complete();
          }
        });
      }, (error) => {
        console.error('Failed to load', error);
        alert('Failed to load app configuration.');
      });
    },
  });

  // Create a new Backbone Marionette Application
  const App = new Application();

  // Add backbone refresh
  Backbone.history.refresh = function () {
    Backbone.history.stop();
    Backbone.history.start({
      root: getAppRoot(),
    });
  };

  // Save our Communicator
  App.communicator = Communicator;

  // Add intializers
  App.addInitializer(() => {
    Communicator.mediator.trigger('application:start');
  });

  // Execute the following code after initialization
  App.on('start', () => {
    if (Backbone.history) {
      Backbone.Preloader.on('complete', () => {
        if (!Backbone.History.started && !Backbone.history.start()) {
          Backbone.history.navigate('/', { trigger: true });
        }
      });

      Backbone.Preloader.on('timeout', () => {
        if (Backbone.Preloader.queue) {
          const errors = [];
          $.each(Backbone.Preloader.queue, (name, def) => {
            if (def.state() == 'pending' || def.state() == 'rejected') {
              let e = `Backbone.Preloader[${name}] state: ${def.state()}`;
              if (def.state() == 'rejected') {
                $.when(def).fail((response) => {
                  e += ' Error: ';
                  if (typeof (response) === 'string') {
                    e += response;
                  } else {
                    e += JSON.stringify(response);
                  }
                });
              }
              errors.push(e);
            }
          });
          if (errors.length) {
            console.error('Backbone.Preloader timeout reached with errors.');
            $.each(errors, (i, error) => {
              console.error(error);
            });
          }
        }
      });
    }
  });

  return App;
});
