define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'files/internal': 'internalList',
    'files/shared': 'sharableList',
    'files/delete/:id(/:goto)': 'remove',
    'files/details/:id(/:goto)': 'details',
    'file-categories': 'fileCategories',

    'files/upload/internal': 'uploadInternal',
    'files/upload/internal(/:object_instance_id)': 'uploadInternal',
    'files/upload/shared': 'uploadShared',
    'files/upload/shared(/:object_instance_id)': 'uploadShared',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/files'], callback);
  },
}));
