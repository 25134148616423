define([
  'jquery',
], (
  $,
) => ({

  /**
     * Gets a callable object (Async or (resolve, reject)) And returns a promise
     * @param callable
     * @return {*}
     */
  functionToPromise(callable) {
    return new Promise((resolve, reject) => {
      // Call the callable with resolve and reject
      const promise = callable(resolve, reject);

      // Handle callable being an async function
      if (promise instanceof Promise) {
        promise
          .then(resolve)
          .catch(reject);
      }
    });
  },

  /**
     * Resolved/rejects deferred if promised is resolved/caught
     * @param promise
     * @param deferred
     */
  bindPromiseToDeferred(promise, deferred) {
    promise
      .then(deferred.resolve)
      .catch(deferred.reject);
  },

  /**
     * Uses the promise to resolve or reject the deferred object
     * @param promise
     * @return {{}}
     */
  promiseToDeferred(promise) {
    const deferred = new $.Deferred();

    this.bindPromiseToDeferred(promise, deferred);

    return deferred;
  },

  /**
     * Uses the deferred object to resolve or reject the promise
     * @param deferred
     * @return {*}
     */
  deferredToPromise(deferred) {
    return new Promise((resolve, reject) => {
      deferred.then(resolve, reject);
    });
  },

  extractTryCatchErrorMessage(err) {
    let message = err;

    if ('error' in err) {
      // Get backend error
      message = err.error;
    } else if ('message' in err) {
      // Get code error
      message = err.message;
    }

    return message;
  },

}));
