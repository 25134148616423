define([
  'modules/common/components/locale',
], (Locale) => ({

  TYPE_QWERTY: 'qwerty',
  TYPE_QWERTZ: 'qwertz',
  TYPE_NONE: 'none',

  getDefault() {
    return Locale.getLocale() === 'de' ? this.TYPE_QWERTZ : this.TYPE_QWERTY;
  },
}));
