define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
], (
  $, _, LinkModel,
  Locale, Feature,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: 'new-localtomorrow-shop',
      logo: '/images/apps/bakeronline.png',
      title: Locale.translate('new_localtomorrow_sales_channel'),
      summary: Locale.translate('connect_your_localtommorow_account_and_fulfill_the_order_from_pos'),
      link_open: 'apps/create-localtomorrow-shop',
      app_name: Feature.APP_CORE_ONLINE_FOOD_ORDER_MODULE_LOCALTOMORROW,
    },
  ),

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
