define([
  'application',
  'module',
  'jquery',
  'underscore',
  'upx.modules/RelationsModule/models/MyProfile',
  'upx.modules/RelationsModule/models/Relation',
  'modules/profile/events/profile/beforeLoad',
  'modules/profile/events/profile/load',
  'modules/profile/events/profile/afterLoad',
  'modules/profile/events/profile/unload',
  'modules/upx/components/upx',

  'modules/upx/collections/users',
],
(App, Module, $, _, UpxModel, RelationModel, BeforeLoadEvent, LoadEvent, AfterLoadEvent, UnloadEvent, Upx,
  UserCollection) => {
  const model = UpxModel.extend({
    offline: true,

    idAttribute: 'fetchId',

    loaded: false,

    /**
     * Load the profile
     */
    load(user, options) {
      options = options || {};

      const self = this;
      const def = $.Deferred();

      if (_.has(options, 'skip_when_loaded') && !!options.skip_when_loaded && !!this.loaded) {
        return def.resolve();
      }

      this.set({ fetchId: 'modules/profile/models/profile' });

      if (!!user && user.get('rights') == 'user') {
        this.set({
          super_admin: true,
          relation_data: {
            name: user.get('name'),
            contact_person: {
              firstname: 'System',
              familyname: 'Administrator',
              image_url: 'images/default_avatar.png',
            },
          },
        });

        const event = new LoadEvent({
          profile: self,
        });
        event.trigger();

        if (event.subDef) {
          $.when(event.subDef.promise()).then(
            () => {
              self.loaded = true;
              const afterLoadEvent = new AfterLoadEvent({
                profile: self,
              });
              afterLoadEvent.trigger();
              def.resolve();
            },
            (resp) => {
              if (resp.class === 'Auth' && !resp.success) {
                UserCollection.clearActiveData();
              }

              def.reject(resp);
            },
          );
        } else {
          self.loaded = true;
          const afterLoadEvent = new AfterLoadEvent({
            profile: self,
          });
          afterLoadEvent.trigger();
          def.resolve();
        }
      } else {
        const beforeLoadEvent = new BeforeLoadEvent();
        beforeLoadEvent.trigger();

        const fields = beforeLoadEvent.getFetchFields();

        $.when(this.fetch({ parameters: { fields } })).then(
          () => {
            const event = new LoadEvent({
              profile: self,
            });
            event.trigger();

            if (event.subDef) {
              $.when(event.subDef.promise()).then(
                () => {
                  self.loaded = true;
                  const afterLoadEvent = new AfterLoadEvent({
                    profile: self,
                  });
                  afterLoadEvent.trigger();
                  def.resolve();
                },
                (resp) => {
                  def.reject(resp);
                },
              );
            } else {
              self.loaded = true;
              const afterLoadEvent = new AfterLoadEvent({
                profile: self,
              });
              afterLoadEvent.trigger();
              def.resolve();
            }
          },
          (resp) => {
            if (resp.class === 'Auth' && !resp.success) {
              UserCollection.clearActiveData();
            }
            def.reject('Failed to load the profile');
          },
        );
      }

      return def.promise();
    },

    /**
             * Unload the profile
             */
    unload() {
      this.clear();
      this.getCacheStorage().cleanup(); // clean the cache to not load old profile

      const event = new UnloadEvent();
      event.trigger();

      this.loaded = false;
    },

    /**
             * Get full profile name
             * @returns {string}
             */
    getFullProfileName() {
      return `${this.getProfileFirstName()} ${this.getProfileLastName()}`.trim();
    },

    getProfileFirstName() {
      const profile = this.getRelationDataProfile();
      if (profile !== undefined && profile.contact_person !== undefined) {
        return `${profile.contact_person.firstname}`.trim();
      }
      return '';
    },
    getProfileLastName() {
      const profile = this.getRelationDataProfile();
      if (profile !== undefined && profile.contact_person !== undefined) {
        if (!!profile.contact_person.familyname_prefix && profile.contact_person.familyname_prefix.trim() !== '') {
          return `${profile.contact_person.familyname_prefix} ${profile.contact_person.familyname}`.trim();
        }
        return `${profile.contact_person.familyname}`.trim();
      }
      return '';
    },
    getProfileCompanyName() {
      return this.get('relation_data.business_data.name');
    },

    getProfileCompanyGln() {
      return this.get('relation_data.business_data.gln');
    },
    getProfileCompanyVat() {
      return this.get('relation_data.business_data.vat_number');
    },
    getCompanyWww(removeHttp = false) {
      let www = this.get('relation_data.contact_set.www');
      if (removeHttp && _.isString(www)) {
        const regex = new RegExp('^\\s*https?://', 'gm');
        www = www.replace(regex, '');
      }
      return www;
    },

    getProfileEmail() {
      if (this.has('relation_data_profile.contact_set.email')) {
        return this.get('relation_data_profile.contact_set.email');
      }
      return this.get('subuser.email');
    },

    isSupport() {
      const email = this.get('subuser.email') || '';
      if (_.isString(email)) {
        return email.endsWith('@storekeeper.nl') || email.endsWith('@storekeeper.com');
      }
      return false;
    },
    /**
             * Get product with certain rights
             * @param module
             * @param typeAlias
             * @param rights group member type alias
             * @returns {boolean}
             */
    getProductWithMember(module, typeAlias) {
      let data = false;

      $.each(this.getProducts(), (key, product) => {
        if (module == product.product_type.module_name && typeAlias == product.product_type.alias) {
          if (product.membership !== undefined) {
            data = product;
          }
        }
      });

      return data;
    },

    /**
             *
             * @returns {*|Array}
             */
    getProducts() {
      return this.get('products') || [];
    },

    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getRelationDataProfile() {
      return this.get('relation_data_profile') || this.get('relation_data');
    },

    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getProfileLetters(relation_data) {
      const data = relation_data || this.getRelationDataProfile();
      const contact_person = data.contact_person || {};
      let letters = '';

      if (contact_person.firstname) {
        letters += contact_person.firstname.charAt(0);
      }
      if (contact_person.familyname) {
        letters += contact_person.familyname.charAt(0);
      }
      letters = letters.toUpperCase();
      return letters;
    },
    /**
             * Get relation data profile
             * fallbacks to relation data if no profile set
             * @returns {*}
             */
    getAvatarUrl(relationData, profileData, fallbackUrl) {
      if (this.get('super_admin') === true) {
        return this.get('relation_data.contact_person.image_url');
      }

      if (fallbackUrl == undefined || fallbackUrl == false) {
        fallbackUrl = 'images/default_avatar.png';
      }
      let avatarUrl = fallbackUrl;

      relationData = relationData || this.get('relation_data');
      const url1 = this.getAvatarUrlFromRelationData(relationData);
      if (url1) {
        avatarUrl = url1;
      }

      profileData = profileData || this.get('relation_data_profile');
      const url2 = this.getAvatarUrlFromRelationData(profileData);
      if (url2) {
        avatarUrl = url2;
      }

      return avatarUrl;
    },
    /**
             *
             * @param relationData
             * @returns {boolean|string}
             */
    getAvatarUrlFromRelationData(relationData) {
      let avatarUrl = false;
      if (relationData) {
        if (relationData.image_url) {
          avatarUrl = Upx.getResourceUrl(relationData.image_url);
        } else if (relationData.business_data && relationData.business_data.logo_url) {
          avatarUrl = Upx.getResourceUrl(relationData.business_data.logo_url);
        } else if (relationData && relationData.contact_person && relationData.contact_person.image_url) {
          avatarUrl = Upx.getResourceUrl(relationData.contact_person.image_url);
        }
      }
      return avatarUrl;
    },

    /**
             *
             * @param relationData
             * @returns {boolean|string}
             */
    getRelationProfileDataModel() {
      const data = this.getRelationDataProfile();
      data.data_id = data.id;
      return new RelationModel(data);
    },

    /**
             * Returns the relation data id
             * @return {integer}
             */
    getRelationDataProfileId() {
      return parseInt(this.get('relation_data_profile.id'));
    },
    getRelationDataId() {
      return parseInt(this.get('relation_data.id'));
    },
    getCountryIso2() {
      return this.get('relation_data.address_billing.country_iso2')
        || this.get('relation_data.business_data.country_iso2')
        || this.get('relation_data.contact_address.country_iso2');
    },

    getBlackAndWhitePrintLogoUrl() {
      const url = this.get('relation_data.business_data.logo_black_white_url')
          || this.get('relation_data.business_data.logo_url');
      if (url) {
        return Upx.getResourceUrl(url);
      }
      return null;
    },
  });

  return new model();
});
