define([
        'modules/upx/models/model',
        './structs/Parcel'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ParcelModule',
            idAttribute: 'id',
            object: 'Parcel',
            getPossibleOptions: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getPossibleParcelOptions',parameters, ajaxOptions);
            },
            import: function (parameters, ajaxOptions) {
                return this.callObjectMethod('importParcel',parameters, ajaxOptions);
            },
            newGrouped: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newGroupedParcel',parameters, ajaxOptions);
            },
            syncCron: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncParcelsCron',parameters, ajaxOptions);
            },
            syncParcel: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncParcel',parameters, ajaxOptions);
            }
        });
    }
);