define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
], (
  AclRouter, LoggedIn, CapabilityAuthAcl,
) => AclRouter.extend({

  appRoutes: {
    'storekeeper-payments': 'details',
  },

  acls: [
    new LoggedIn(),

    new CapabilityAuthAcl({
      name: CapabilityAuthAcl.PAYMENT_SETTINGS_CAPABILITY,
    }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/storeKeeperPayment'], callback);
  },
}));
