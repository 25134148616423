define([
        'modules/upx/collections/collection',
        '../models/GroupCardProvider',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'PaymentModule',
            object: 'GroupCardProvider',
            collection_method: 'listGroupCardProviders',
            model: Model
        });
    }
);