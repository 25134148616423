define([],
  () => ({
    /**
     * returns app root url
     */
    getAppRoot() {
      const {
        protocol, port, hostname, pathname, search,
      } = window.location;

      let appUrl = `${protocol}//${hostname}`;
      if (!(protocol === 'https:' && port === '443') && !(protocol === 'http:' && port === '80')) {
        appUrl = `${appUrl}:${port}`;
      }
      appUrl = `${appUrl}${pathname}${search}`;
      return appUrl;
    },
  }));
