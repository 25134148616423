define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop/models/App/LinkApp',
  'modules/shop/models/App/SalesChannelApp',
  'modules/shop/models/App/MailChimpApp',
  'modules/shop/models/App/SumUpApp',
  'modules/shop/models/App/GroupCardApp',
  'modules/shop/models/App/CCVLabGiftCardApp',

  'modules/shop/models/App/SnelStartApp',
  'modules/shop/models/App/TwinfieldApp',
  'modules/shop/models/App/LoyaltyProgramApp.js',
  'modules/shop/models/App/PayNlApp',
  'modules/shop/models/App/RaboBankSmartPayApp',
  'modules/shop/models/App/FiskalyApp.js',
  'modules/shop/models/App/PosApp',

  'modules/shop/models/App/BookkeepingEmailIntegrationApp',
  'modules/shop/models/App/ParcelApp',
  'modules/shop/models/App/SkAsShopApp.js',
  'modules/shop/models/App/ExactOnlineApp',
  'modules/shop/models/App/WebShopWithSyncApp',

  'modules/shop/models/App/SiteDishApp',
  'modules/shop/models/App/LocalTomorrowApp',
  'modules/shop/models/App/WinkelStraatApp',
  'modules/shop/models/App/OrderRequestApp',
  'modules/shop/models/App/BolViaFluseApp',

  'modules/shop/models/App/ImageCdn',
  'modules/shop/models/App/FloraAtHomeSupplierEdiIntegrationApp.js',
  'modules/shop/models/App/EDCSupplierEdiIntegrationApp.js',

  'modules/shop/models/App/FashionUnitedEdiIntegrationApp',
  'modules/shop/models/App/OrderWriterEdiIntegrationApp',
  'modules/shop/models/App/BestsellerEdiIntegrationApp',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',

  'modules/shop/collections/upx/Shops',
  'modules/shop/collections/ShopType',
  'modules/shop/models/DefaultAdapter.js',

  'modules/shop/models/App/Magento2WebshopWithSyncApp.js',
  'modules/shop/models/App/WooCommerceWebshopWithSyncApp.js',
  'modules/shop/models/App/ShoptraderWebshopWithSyncApp',
], (
  $, _, Backbone,
  AppModel, SalesChannelAppModel, MailChimpAppModel, SumUpApp, GroupCardApp, CCVLabGiftCardApp,
  SnelStartApp, TwinfieldApp, LoyaltyProgramApp, PayNlApp, RaboBankSmartPayApp, FiskalyApp, PosApp,
  BookkeepingEmailIntegrationApp, ParcelApp, SkAsShopApp, ExactOnlineApp, WebShopWithSyncApp,
  SiteDishApp, LocalTomorrowApp, WinkelStraatApp, OrderRequestApp, BolViaFluseApp,
  ImageCdnApp, FloraAtHomeSupplierEdiIntegrationApp, EDCSupplierEdiIntegrationApp,
  FashionUnitedEdiIntegrationApp, OrderWriterEdiIntegrationApp, BestsellerEdiIntegrationApp,
  Locale, Feature, AppsComponent,
  ShopsCollection, ShopTypeCollection, DefaultAdapterModel,
  Magento2WebshopWithSyncApp, WooCommerceWebshopWithSyncApp, ShoptraderWebshopWithSyncApp,
) => Backbone.Collection.extend({

  model: AppModel,

  comparator(a, b) {
    const aUp = a.get('is_upgradable');
    const bUp = b.get('is_upgradable');
    // push upgradables to the end
    if (aUp && !bUp) {
      return 1;
    }
    if (!aUp && bUp) {
      return -1;
    }
    const aTitle = a.get('title') || '';
    const bTitle = b.get('title') || '';
    return aTitle.localeCompare(bTitle);
  },

  loadDeferred: false,

  initialize() {
    // we need to recalculate the apps
    ShopsCollection.on('reset', this.unload, this);
    ShopsCollection.on('remove', this.unload, this);
    Feature.on('unload', this.unload, this);
  },

  isLoaded() {
    return this.loadDeferred && this.loadDeferred.state() === 'resolved';
  },
  isLoading() {
    return this.loadDeferred && this.loadDeferred.state() === 'pending';
  },

  load() {
    if (this.isLoading() || this.isLoaded()) {
      return this.loadDeferred;
    }
    const def = new $.Deferred();
    $.when(
      Feature.load(),
      ShopsCollection.load(),
      DefaultAdapterModel.load(),
    ).then(() => {
      let apps = this.getDefaultApps();
      apps = apps.concat(this.getWebshopWithSync());
      apps = apps.concat(this.getSitedishShops());
      apps = apps.concat(this.getLocalTomorrowShops());
      apps = apps.concat(this.getOrderRequestShops());
      apps = apps.concat(this.getWinkelStraatShops());

      this.add(apps);
      this.checkData();
      this.sort();
      def.resolve();
    }, def.reject);

    this.loadDeferred = def.promise();
    return this.loadDeferred;
  },

  unload() {
    this.loadDeferred = false;
    this.reset(null, { silent: true });
  },

  reload() {
    this.unload();
    return this.load();
  },

  checkData() {
    const without_app_names = {};
    let has_without_app_names = false;
    const app_names = [];
    const without_app_summary = [];

    this.each((m) => {
      if (!m.get('app_name')) {
        if (m.needsFeature()) {
          without_app_names[m.get('id')] = m.get('title');
          has_without_app_names = true;
        }
      } else {
        app_names.push(m.get('app_name'));
      }
      if (!m.get('summary')) {
        without_app_summary.push(`${m.get('id')}: ${m.get('title')}`);
      }
    });

    if (without_app_summary.length) {
      console.debug('Without app summary:', without_app_summary);
    }
    if (has_without_app_names) {
      console.error('App without names:', without_app_names);
    }
    const all_feature_names = Feature.getFeatureNames();
    const unused = all_feature_names.filter((i) => app_names.indexOf(i) < 0);
    if (unused.length) {
      console.debug('Unused names:', unused);
    }

    const notVisible = this.where({ is_visible: false });
    if (notVisible.length) {
      console.debug('Not visible apps:', _.map(notVisible, (m) => m.get('title')));
    }
  },

  getDefaultApps() {
    return [
      {
        id: AppsComponent.HOSPITALITY_SETTINGS_APP_ID,
        title: Locale.translate('hospitality'),
        summary: Locale.translate('manage_tables_and_kitchen_print_groups_for_hospitality'),
        icon: 'fal fa-utensils',
        link_open: 'hospitality-settings',
        app_name: Feature.APP_CORE_SHOP_MODULE_HOSPITALITY,
      },
      {
        id: AppsComponent.FEATURED_BRANDS_APP_ID,
        icon: 'fal fa-copyright',
        title: Locale.translate('brands'),
        summary: Locale.translate('manage_brands_you_sell_in_your_shop'),
        link_open: 'fa/brands',
        app_name: Feature.APP_CORE_SHOP_MODULE_BRAND,
      },
      {
        id: AppsComponent.REPORTS_APP_ID,
        icon: 'fal fa-analytics',
        title: Locale.translate('reports'),
        summary: Locale.translate('generate_reports_from_your_sales_data'),
        link_open: 'reports',
        app_name: Feature.APP_NAME_CORE_REPORT_MODULE_REPORTS,
      },
      new MailChimpAppModel(),
      new PayNlApp(),
      new RaboBankSmartPayApp(),
      new ParcelApp({
        id: AppsComponent.VELOYD_APP_ID,
        logo: '/images/apps/veloyd.png',
        title: Locale.translate('msg_parcel_delivery'),
        app_name: Feature.APP_NAME_CORE_VELOYD_PARCEL_MODULE_VELOYD_PARCEL_MODULE_PROVIDER,
        summary: Locale.translate('set_up_msg_as_parcel_delivery_using_veloyd_delivery_platform'),
        link_open: 'apps/veloyd',
        type: 'VeloydParcel',
      }),
      new ParcelApp({
        id: AppsComponent.PARCEL_NL_APP_ID,
        logo: '/images/apps/parcelnl.png',
        title: 'Parcel.nl',
        app_name: Feature.APP_NAME_CORE_PARCEL_NL_MODULE_PARCEL_NL_PROVIDER,
        summary: Locale.translate('set_up_parcel_nl_as_parcel_provider'),
        link_open: 'apps/parcelnl',
        type: 'ParcelNl',
      }),
      new ParcelApp({
        id: AppsComponent.POST_NL_APP_ID,
        logo: '/images/apps/postnl.png',
        title: 'PostNL',
        app_name: Feature.APP_NAME_CORE_POST_NL_MODULE_POST_NL_PROVIDER,
        link_open: 'apps/postnl',
        summary: Locale.translate('set_up_postnl_as_parcel_provider'),
        type: 'PostNl',
      }),
      new ParcelApp({
        id: AppsComponent.MY_PARCEL_NL_APP_ID,
        logo: '/images/apps/myparcelnl.png',
        title: 'MyParcel.nl',
        app_name: Feature.APP_NAME_CORE_MY_PARCEL_NL_MODULE_MY_PARCEL_NL_PROVIDER,
        link_open: 'apps/myparcelnl',
        summary: Locale.translate('set_up_myparcel_nl_as_parcel_provider'),
        type: 'MyParcelNl',
      }),
      new ParcelApp({
        id: AppsComponent.SENDY_NL_APP_ID,
        logo: '/images/apps/sendynl.jpg',
        title: 'Sendy',
        app_name: Feature.APP_NAME_CORE_SENDY_NL_PARCEL_MODULE_PROVIDER,
        link_open: 'apps/sendynl',
        summary: Locale.translate('set_up_sendy_nl_as_parcel_provider'),
        type: 'SendyNlParcel',
      }),
      new BookkeepingEmailIntegrationApp(),
      new SumUpApp(),
      new GroupCardApp(),
      new CCVLabGiftCardApp(),
      new SnelStartApp(),
      new TwinfieldApp(),
      new LoyaltyProgramApp(),
      new FiskalyApp(),
      new ExactOnlineApp(),
      new BolViaFluseApp(),
      {
        id: AppsComponent.PRODUCT_BLUEPRINTS_APP_ID,
        icon: 'fal fa-object-group',
        title: Locale.translate('product_blueprints'),
        summary: Locale.translate('manage_product_blueprints_for_configurable_products'),
        link_open: 'configurable-product-kind',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_CONFIGURABLE_PRODUCT,
      },
      {
        id: 'sent-emails',
        icon: 'fal fa-paper-plane',
        title: Locale.translate('sent_emails'),
        summary: Locale.translate('check_open_status_and_clicks_of_all_sent_emails'),
        link_open: 'sent-emails',
        app_name: Feature.APP_NAME_CORE_EMAIL_MODULE_EMAIL,
      },
      {
        id: AppsComponent.SALES_CHANNELS_APP_ID,
        icon: 'fal fa-store',
        title: Locale.translate('sales_channels'),
        summary: Locale.translate('overview_of_all_your_sales_channels'),
        link_open: 'sales-channels',
        app_name: Feature.APP_CORE_SHOP_MODULE_SALES_CHANNELS_MANAGEMENT,
      },
      {
        id: AppsComponent.SALES_CHANNEL_TURNOVER_OVERVIEW_APP_ID,
        icon: 'fal fa-money-bill-wave',
        title: Locale.translate('sales_channel_turnover_overview'),
        summary: Locale.translate('total_turnover_dashboard'),
        link_open: 'sales-channels/reports',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_SALES_CHANNEL_TURNOVER_OVERVIEW,
      },
      {
        id: AppsComponent.TURNOVER_GROUPS_APP_ID,
        icon: 'fal fa-tags',
        title: Locale.translate('turnover_groups'),
        summary: Locale.translate('assign_products_for_different_turnover_groups'),
        link_open: 'product-groups',
        app_name: Feature.APP_CORE_BILLING_MODULE_PRODUCT_GROUP,
      },
      {
        id: 'cash-register-settings',
        icon: 'fal fa-cash-register',
        title: Locale.translate('cash_register_settings'),
        summary: Locale.translate('configure_your_physical_point_of_sale_devices'),
        link_open: 'cash-register-settings',
        app_name: Feature.APP_CORE_SHOP_MODULE_DEVICE_SETTING,
      },
      {
        id: AppsComponent.PRODUCT_ATTRIBUTES_APP_ID,
        icon: 'fal fa-object-group',
        title: Locale.translate('product_attributes'),
        summary: Locale.translate('configure_product_attribute_and_options_on_them'),
        link_open: 'product-attributes',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_PRODUCT_ATTRIBUTE,
      },
      new WebShopWithSyncApp(),
      new Magento2WebshopWithSyncApp(),
      new WooCommerceWebshopWithSyncApp(),
      new ShoptraderWebshopWithSyncApp(),
      new SkAsShopApp({
        // we use that app only for counting, now visible in app store
        shop_type: 'generic',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_P_O_S,
      }),
      new SkAsShopApp({
        icon: 'fa-brands fa-shopify',
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_SHOPIFY),
        shop_type: ShopTypeCollection.WEBSHOP_SHOPIFY,
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_SHOPIFY,
      }),
      new SkAsShopApp({
        logo: '/images/apps/ccv-shop.png',
        icon: 'fal fa-plus',
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_CCV),
        shop_type: ShopTypeCollection.WEBSHOP_CCV,
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_C_C_V_SHOP,
      }),
      new SkAsShopApp({
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_PRESTASHOP),
        shop_type: ShopTypeCollection.WEBSHOP_PRESTASHOP,
        logo: '/images/apps/prestashop-logo.png',
        icon: 'fal fa-plus',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_PRESTA_SHOP,
      }),
      new SkAsShopApp({
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_MAGENTO2),
        shop_type: ShopTypeCollection.WEBSHOP_MAGENTO2,
        icon: 'fa-brands fa-magento',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_MAGENTO2,
      }),
      new SkAsShopApp({
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_SHOPTRADER),
        shop_type: ShopTypeCollection.WEBSHOP_SHOPTRADER,
        icon: 'fal fa-plus',
        logo: '/images/apps/shoptrader.png',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_SHOP_TRADER,
      }),
      new SkAsShopApp({
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_WOOCOMMERCE),
        shop_type: ShopTypeCollection.WEBSHOP_WOOCOMMERCE,
        icon: 'fal fa-plus',
        logo: '/images/apps/pos-for-woocom.png',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_WOO_COMMERCE,
      }),
      new SkAsShopApp({
        shop_type_name: ShopTypeCollection.getShopNameByType(ShopTypeCollection.WEBSHOP_SHOPWARE6),
        shop_type: ShopTypeCollection.WEBSHOP_SHOPWARE6,
        icon: 'fal fa-plus',
        logo: '/images/apps/shopware6.png',
        app_name: Feature.APP_CORE_SHOP_MODULE_SK_AS_SHOPWARE6,
      }),
      new PosApp({
        id: AppsComponent.NEW_RETAIL_POS_APP_ID,
      }),
      new PosApp({
        id: AppsComponent.NEW_FOOD_POS_APP_ID,
      }),
      new WinkelStraatApp(),
      new OrderRequestApp(),
      new SiteDishApp(),
      new LocalTomorrowApp(),
      new ImageCdnApp(),
      {
        id: AppsComponent.PRODUCT_ADD_ON_GROUPS_APP_ID,
        icon: 'fal fa-object-group',
        title: Locale.translate('product_add_on_groups'),
        summary: Locale.translate('manage_add_ons_on_products'),
        link_open: 'product-addon-groups',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_ADDON,
      },
      {
        id: AppsComponent.PRODUCT_ATTRIBUTE_CATEGORIES_APP_ID,
        icon: 'fal fa-object-group',
        title: Locale.translate('product_attribute_categories'),
        summary: Locale.translate('configure_categories_of_product_attibutes'),
        link_open: 'product-attribute-categories',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_PRODUCT_ATTRIBUTE_CATEGORIES,
      },
      {
        id: AppsComponent.PRODUCT_IMAGE_CATEGORIES_APP_ID,
        icon: 'fal fa-images',
        title: Locale.translate('product_image_categories'),
        summary: Locale.translate('configure_categories_of_product_images'),
        link_open: 'product-image-categories',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_PRODUCT_IMAGE_CATEGORIES,
      },
      {
        id: AppsComponent.PRODUCT_SERIALS_APP_ID,
        icon: 'fal fa-barcode-read',
        title: Locale.translate('product_serial_numbers'),
        summary: Locale.translate('add_serial_numbers_change_ownership_and_see_history'),
        link_open: 'product-serials',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_SERIAL,
      },
      {
        id: AppsComponent.CUSTOMER_IMPORT_APP_ID,
        icon: 'fal fa-cloud-upload',
        title: Locale.translate('customer_import'),
        summary: Locale.translate('easy_import_of_customers'),
        link_open: 'apps/customer-import',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.CUSTOMER_NOTES_IMPORT_APP_ID,
        icon: 'fal fa-cloud-upload',
        title: Locale.translate('customer_notes_import'),
        summary: Locale.translate('easy_import_of_customer_notes'),
        link_open: 'apps/customer-notes-import',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.LOYALTY_CUSTOMER_POINTS_IMPORT_APP_ID,
        icon: 'fal fa-cloud-upload',
        title: Locale.translate('loyalty_customer_points_import'),
        summary: Locale.translate('easy_import_of_loyalty_customer_points'),
        link_open: 'apps/loyalty-customer-points-import',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.GIFT_CARD_IMPORT_APP_ID,
        icon: 'fal fa-cloud-upload',
        title: Locale.translate('gift_card_import'),
        summary: Locale.translate('easy_import_of_gift_cards'),
        link_open: 'apps/gift-card-import',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.PRODUCT_IMPORT_APP_ID,
        icon: 'fal fa-cloud-upload',
        title: Locale.translate('product_import'),
        summary: Locale.translate('mass_image_import_for_products'),
        link_open: 'apps/product-import',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.PRODUCT_LABELS_PRINT_APP_ID,
        icon: 'fas fa-print',
        title: Locale.translate('print_labels'),
        summary: Locale.translate('print_labels_shelf_cards_and_hang_tags'),
        link_open: 'product-labels-print',
        app_name: Feature.APP_CORE_SHOP_MODULE_PRODUCT_LABEL_PRINTING,
      },
      {
        id: AppsComponent.STOCK_TURNOVER_APP_ID,
        icon: 'fal fa-warehouse-alt',
        title: Locale.translate('stock_turnover'),
        summary: Locale.translate('analysis_on_dead_and_fast_moving_stock'),
        link_open: 'stock-turnover',
        app_name: Feature.APP_CORE_SHOP_MODULE_STOCK_TURNOVER_ANALYSIS,
      },
      {
        id: AppsComponent.GIFT_CARDS_APP_ID,
        icon: 'fal fa-gift-card',
        title: Locale.translate('gift_cards'),
        summary: Locale.translate('managed_customer_gift_cards'),
        link_open: 'gift-cards',
        app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_GIFT_CARD,
      },
      {
        id: 'member-cards',
        icon: 'fal fa-address-card',
        title: Locale.translate('member_cards'),
        summary: Locale.translate('managed_customer_member_cards'),
        link_open: 'customers',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_MEMBER_CARD,
      },
      {
        id: AppsComponent.CCV_PIN_APP_ID,
        logo: '/images/apps/ccv-payment.png',
        icon: 'fab fa-money-bill-wave',
        title: Locale.translate('ccv_payment_terminals'),
        summary: Locale.translate('use_ccv_payment_terminals_on_pos_over_local_network_supported_models_{models}', {
          models: [
            'CCV Smart P400',
            'CCV Smart VX820',
          ].join(', '),
        }),
        link_open: 'apps/ccv-pin',
        app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_CCV_PIN,
      },
      {
        id: AppsComponent.REPAIRS_APP_ID,
        icon: 'fal fa-wrench',
        title: Locale.translate('repairs'),
        summary: Locale.translate('process_repairs_in_pos_and_other_billed_by_hour_work'),
        link_open: 'repairs',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_REPAIRS,
      },
      {
        id: AppsComponent.NARROWCAST_TV_APP_ID,
        icon: 'fal fa-tv',
        title: Locale.translate('narrowcast'),
        summary: Locale.translate('show_slideshow_of_images_with_narrowcast_app_on_your_tv'),
        link_open: 'narrowcast-tv',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_NARROWCAST,
      },
      {
        id: AppsComponent.INTERNAL_FILES_APP_ID,
        icon: 'fal fa-cabinet-filing',
        title: Locale.translate('internal_files'),
        summary: Locale.translate('managed_internal_files'),
        link_open: 'files/internal',
        app_name: Feature.APP_NAME_CORE_DOCUMENT_MODULE_INTERNAL_DOCUMENT,
      },
      {
        id: AppsComponent.SHARED_FILES_APP_ID,
        icon: 'fal fa-file-user',
        title: Locale.translate('shared_files'),
        summary: Locale.translate('managed_files_shared_with_customers'),
        link_open: 'files/shared',
        app_name: Feature.APP_CORE_DOCUMENT_MODULE_SHARED_DOCUMENT,
      },
      {
        id: AppsComponent.PARCEL_OVERVIEW_APP_ID,
        icon: 'fal fa-truck',
        title: Locale.translate('parcel_overview'),
        summary: Locale.translate('delivery_status_os_all_your_parcels'),
        link_open: 'parcels',
        app_name: Feature.APP_CORE_SHOP_MODULE_SHIPMENT_PARCEL_OVERVIEW,
      },
      {
        id: 'online-payments',
        icon: 'fal fa-credit-card-front',
        title: Locale.translate('online_payments'),
        summary: Locale.translate('list_all_payments_and_payout_status_straight_from_pay_nl'),
        link_open: 'online-payments',
        app_name: Feature.APP_CORE_COMMERCE_MODULE_ONLINE_SK_PAYMENTS_LEGACY2020,
      },
      {
        id: AppsComponent.CONTACTS_APP_ID,
        icon: 'fal fa-address-book',
        title: Locale.translate('contacts'),
        summary: Locale.translate('list_of_contacts_from_your_wab_contact_forms'),
        link_open: 'contacts',
        app_name: Feature.APP_CORE_SHOP_MODULE_RELATION_CONTACTS,
      },
      {
        id: 'terms-and-conditions',
        icon: 'fal fa-handshake',
        title: Locale.translate('terms_and_conditions'),
        summary: Locale.translate('manage_terms_and_conditions_for_your_customers'),
        link_open: 'terms-and-conditions',
        app_name: Feature.APP_CORE_COMMERCE_MODULE_CUSTOMER_TERM_AND_CONDITIONS,
      },
      {
        id: AppsComponent.ACTIVITIES_APP_ID,
        icon: 'fal fa-tasks',
        title: Locale.translate('activities'),
        summary: Locale.translate('make_and_receive_tasks_and_actions'),
        link_open: 'activities',
        app_name: Feature.APP_NAME_CORE_COMMERCE_MODULE_INTRANET_FEATURE,
      },
      {
        id: AppsComponent.COUPON_CODES_APP_ID,
        icon: 'fal fa-ticket-alt',
        title: Locale.translate('coupon_codes'),
        summary: Locale.translate('create_coupon_codes_which_can_be_used_in_your_webshop'),
        link_open: 'coupon-codes',
        app_name: Feature.APP_CORE_SHOP_MODULE_COUPON_CODES,
      },
      {
        id: AppsComponent.POS_JOURNALS_APP_ID,
        icon: 'fal fa-cash-register',
        title: Locale.translate('pos_journals'),
        summary: Locale.translate('open_and_close_report_for_pos'),
        link_open: 'cash-register-journals',
        app_name: Feature.APP_CORE_SHOP_MODULE_POS_JOURNALS,
      },
      {
        id: AppsComponent.PAYMENTS_APP_ID,
        icon: 'fal fa-money-bill-wave',
        title: Locale.translate('payments'),
        summary: Locale.translate('list_of_all_payments'),
        link_open: 'payments',
        app_name: Feature.APP_CORE_SHOP_MODULE_ORDER_PAYMENTS,
      },
      {
        id: AppsComponent.CASH_MUTATIONS_APP_ID,
        icon: 'fal fa-money-check-edit-alt',
        title: Locale.translate('cash_mutations'),
        summary: Locale.translate('list_of_pos_cash_mutations'),
        link_open: 'cash-mutations',
        app_name: Feature.APP_CORE_SHOP_MODULE_CASH_MUTATIONS,
      },
      {
        id: AppsComponent.SUPPORT_APP_ID,
        icon: 'fal fa-life-ring',
        title: Locale.translate('conversations'),
        summary: Locale.translate('simple_support_system_for_your_customers'),
        link_open: 'conversations',
        app_name: Feature.APP_NAME_CORE_SUPPORT_MODULE_SUPPORT_REQUEST,
      },
      {
        id: AppsComponent.SUPPLIERS_APP_ID,
        icon: 'fal fa-people-carry',
        title: Locale.translate('suppliers'),
        summary: Locale.translate('manage_suppliers_and_import_their_products'),
        link_open: 'suppliers',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_SUPPLIER,
      },
      {
        id: AppsComponent.SUPPLIER_ORDERS_APP_ID,
        icon: 'fa-light fa-money-check-pen',
        title: Locale.translate('supplier_orders'),
        summary: Locale.translate('manage_supplier_orders_and_create_new_from_suggestions'),
        link_open: 'supplier-orders',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_SUPPLIER_ORDER,
      },
      {
        id: AppsComponent.QUOTES_APP_ID,
        icon: 'fa-light fa-memo-circle-info',
        title: Locale.translate('quotes'),
        summary: Locale.translate('manage_quotes'),
        link_open: 'quotes',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_QUOTES,
      },
      {
        id: 'create-configurable-product',
        icon: 'fal fa-puzzle-piece',
        title: Locale.translate('create_configurable_product'),
        summary: Locale.translate('enable_creation_of_configurable_products'),
        link_open: 'products',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_CONFIGURABLE_PRODUCT,
      },
      {
        id: AppsComponent.OUTSTANDING_INVOICES_APP_ID,
        icon: 'fal fa-file-invoice-dollar',
        title: Locale.translate('outstanding_invoices'),
        summary: Locale.translate('list_of_all_outstanding_invoice_with_totals'),
        link_open: 'outstanding-invoices',
        app_name: Feature.APP_NAME_CORE_BILLING_MODULE_INVOICING,
      },
      {
        id: AppsComponent.OUTSIDE_EUROPE_REPORT_APP_ID,
        icon: 'fal fa-file-invoice-dollar',
        title: Locale.translate('world_0percent_vat_report'),
        summary: Locale.translate('report_on_0percent_vat_outside_eu_given_on_invoices'),
        link_open: 'outside-europe-report',
        app_name: Feature.APP_CORE_BILLING_MODULE_OUTSIDE_EUROPE_VAT_REPORT,
      },
      {
        id: AppsComponent.INVOICE_APP_ID,
        icon: 'fal fa-file-invoice',
        title: Locale.translate('invoice'),
        summary: Locale.translate('list_of_all_invoices'),
        link_open: 'invoices',
        app_name: Feature.APP_NAME_CORE_BILLING_MODULE_INVOICING,
      },
      {
        id: AppsComponent.USED_GOODS_APP_ID,
        icon: 'fa-light fa-people-arrows-left-right',
        title: Locale.translate('used_goods'),
        summary: Locale.translate('buy_and_resell_used_goods'),
        link_open: 'used-goods',
        app_name: Feature.APP_NAME_CORE_SHOP_USED_GOODS,
      },
      {
        id: AppsComponent.MARGIN_REPORT_APP_ID,
        icon: 'fal fa-percent',
        title: Locale.translate('margin_report'),
        summary: Locale.translate('maring_reports_on_product_groups_sales_people_and_products'),
        link_open: 'margin-reports',
        app_name: Feature.APP_NAME_CORE_BILLING_MODULE_MARGIN,
      },
      {
        id: AppsComponent.INTRACOMMUNITY_REPORT_APP_ID,
        icon: 'fal fa-percent',
        title: Locale.translate('intracommunity_report'),
        summary: Locale.translate('report_on_0percent_vat_intracommunity_eu_given_on_invoices'),
        link_open: 'intracommunity-report',
        app_name: Feature.APP_CORE_BILLING_MODULE_INTRACOMMUNITY_REPORT,
      },
      {
        id: AppsComponent.PRODUCT_IMAGE_IMPORT_APP_ID,
        icon: 'fal fa-images',
        title: Locale.translate('product_image_import'),
        summary: Locale.translate('mass_image_import_for_your_products'),
        link_open: 'apps/product-image-import',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_IMAGE_IMPORT,
      },
      {
        id: AppsComponent.TRANSLATION_CENTER_APP_ID,
        icon: 'fal fa-language',
        title: Locale.translate('translation_center'),
        summary: Locale.translate('translations_for_attributes_products_shipping_and_payments'),
        link_open: 'apps/translation-center',
        app_name: Feature.APP_CORE_TRANSLATION_MODULE_TRANSLATION_CENTER,
      },
      {
        id: AppsComponent.STOCK_MANAGER_APP_ID,
        icon: 'fal fa-boxes',
        title: Locale.translate('stock_manager'),
        summary: Locale.translate('addremoveset_stock_of_multiple_products_at_once'),
        link_open: 'stocks',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_STOCK,
      },
      {
        id: AppsComponent.STOCK_MUTATIONS_APP_ID,
        icon: 'fas fa-history',
        title: Locale.translate('stock_mutations'),
        summary: Locale.translate('see_full_history_of_stock_changes_on_products'),
        link_open: 'stock-mutations',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_STOCK,
      },
      {
        id: AppsComponent.IMPORT_EXPORT_CENTER_APP_ID,
        icon: 'fal fa-cloud-download',
        title: Locale.translate('import_and_export_center'),
        summary: Locale.translate('import_and_export_customers_products_suppliers_and_more'),
        link_open: 'apps/import-export-center',
        app_name: Feature.APP_CORE_IMPORT_EXPORT_MODULE_IMPORT_EXPORT_CENTER,
      },
      {
        id: AppsComponent.PAYMENT_STATISTICS_APP_ID,
        icon: 'fal fa-chart-line',
        title: Locale.translate('payment_statistics'),
        summary: Locale.translate('statistics_and_listing_of_all_your_payments'),
        link_open: 'apps/payment-statistics',
        app_name: Feature.APP_CORE_SHOP_MODULE_PAYMENT_STATISTICS,
      },
      {
        id: AppsComponent.SCHEDULED_DISCOUNTS_APP_ID,
        icon: 'fal fa-badge-percent',
        title: Locale.translate('schedule_product_discounts'),
        summary: Locale.translate('prepare_promotions_for_you_products_ahead_of_time_they_will_be_applied_automatically_during_night'),
        link_open: 'scheduled-discounts',
        app_name: Feature.APP_CORE_SHOP_MODULE_SCHEDULED_DISCOUNTS,
      },
      {
        id: AppsComponent.MERGE_INTO_CONFIGURABLE_APP_ID,
        icon: 'fal fa-code-merge',
        title: Locale.translate('merge_products_into_product_with_variations'),
        summary: Locale.translate('you_can_mass_combine_multiple_simple_products_into_configurable_ones'),
        link_open: 'merge-into-configurable',
        app_name: Feature.APP_CORE_SHOP_MODULE_MERGE_PRODUCTS_INTO_CONFIGURABLE,
      },
      {
        id: AppsComponent.PRODUCT_ATTRIBUTE_MERGE_INTO_APP_ID,
        icon: 'fal fa-code-merge',
        title: Locale.translate('merge_product_attributes_to_another'),
        summary: Locale.translate('choose_2_product_attributes_and_write_combined_value_to_3rd_one_applied_straight_on_products'),
        link_open: 'product-attribute/merge-into',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_MERGE_PRODUCT_ATTRIBUTE_INTO_ANOTHER,
      },
      {
        id: AppsComponent.FEATURED_SEASONS_APP_ID,
        icon: 'fal fa-mug-marshmallows',
        title: Locale.translate('seasons'),
        summary: Locale.translate('manage_seasons_like_fallwinter_2022_ect'),
        link_open: 'fa/seasons',
        app_name: Feature.APP_CORE_SHOP_MODULE_SEASONS,
      },
      {
        id: AppsComponent.FEATURED_FASHION_COLLECTIONS_APP_ID,
        icon: 'fal fa-shirt',
        title: Locale.translate('fashion_collections'),
        summary: Locale.translate('manage_fashion_collections'),
        link_open: 'fa/fashion-collections',
        app_name: Feature.APP_CORE_SHOP_MODULE_FASHION_COLLECTIONS,
      },
      {
        id: AppsComponent.FEATURED_STYLE_ID_APP_ID,
        icon: 'fal fa-shirt',
        title: Locale.translate('style_ids'),
        summary: Locale.translate('in_fashion_it_s_often_used_for_grouping_the_configurable_products_together_for_example_one_tshirt_in_3_sizes_would_have_same_style_id'),
        link_open: 'fa/style-id',
        app_name: Feature.APP_CORE_SHOP_MODULE_STYLE_ID,
      },
      {
        id: AppsComponent.FEATURED_ATTRIBUTE_CATEGORIES_APP_ID,
        icon: 'fal fa-object-group',
        title: Locale.translate('colors_and_sizes_charts'),
        summary: Locale.translate('assign_category_like_color_comma_size_etc_dot_to_attributes'),
        link_open: 'featured-attribute-categories',
        app_name: Feature.APP_CORE_SHOP_MODULE_FEATURED_ATTRIBUTE_CATEGORIES,
      },
      {
        id: AppsComponent.REPORT_STOCK_BY_ATTRIBUTE_OPTION_APP_ID,
        icon: 'fal fa-chart-bar',
        title: Locale.translate('stock_product_variation_report'),
        summary: Locale.translate('generate_report_about_stock_value_changes_size_vs_color_for_given_product_selection'),
        link_open: 'report/stock-by-attribute-option',
        app_name: Feature.APP_CORE_PRODUCTS_MODULE_PRODUCT_STOCK_VARIATION_REPORT,
      },
      {
        id: AppsComponent.SUPPLIER_PRODUCTS_APP_ID,
        icon: 'fal fa-list',
        title: Locale.translate('supplier_products'),
        summary: Locale.translate('see_the_full_list_of_all_supplier_products'),
        link_open: 'supplier-products',
        app_name: Feature.APP_NAME_CORE_SHOP_MODULE_SUPPLIER,
      },
      {
        id: AppsComponent.PRODUCT_VALUATION_APP_ID,
        icon: 'fal fa-box-dollar',
        title: Locale.translate('stock_valuation_reporting'),
        summary: Locale.translate('you_can_get_the_valuation_of_your_current_stock_in_any_point_in_time_here'),
        link_open: 'stock-valuation',
        app_name: Feature.APP_NAME_CORE_PRODUCTS_MODULE_PRODUCT_VALUATION,
      },
      new FloraAtHomeSupplierEdiIntegrationApp(),
      new EDCSupplierEdiIntegrationApp(),
      new FashionUnitedEdiIntegrationApp(),
      {
        id: AppsComponent.SHOP_PRODUCT_FEED_APP_ID,
        icon: 'fa-regular fa-rss',
        title: Locale.translate('product_feeds'),
        summary: Locale.translate('use_product_feeds_as_input_for_facebook_comma_instagram_comma_channable_comma_etc_dot'),
        link_open: 'shop-product-feed',
        app_name: Feature.APP_CORE_SHOP_MODULE_SHOP_PRODUCT_FEED,
      },
      {
        id: AppsComponent.COMPANY_FEED_APP_ID,
        icon: 'fa-regular fa-rss',
        title: Locale.translate('company_feed'),
        summary: Locale.translate('get_your_company_data_using_url_endpoint'),
        link_open: 'company-feed',
        app_name: Feature.APP_NAME_REPORT_COMPANY_FEED,
      },
      {
        id: AppsComponent.STOCK_BALANCING_APP_ID,
        icon: 'fa-solid fa-shelves',
        title: Locale.translate('stock_balancing_count'),
        summary: Locale.translate('counting_your_whole_stock_to_do_balancing_dot'),
        link_open: 'stock-balancing',
        app_name: Feature.APP_CORE_SHOP_MODULE_STOCK_BALANCING,
      },
      {
        id: AppsComponent.RABOBANK_LOAN_APP_ID,
        logo: '/images/apps/rabo_loan_icon.png',
        icon: 'fa-light fa-money-bill',
        app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_RABOBANK_BUSINESS_LOAN,
        title: Locale.translate('app[core.PaymentModule.RaboBankBusinessLoan]title'),
        summary: Locale.translate('app[core.PaymentModule.RaboBankBusinessLoan]summary'),
        description: Locale.translate('app[core.PaymentModule.RaboBankBusinessLoan]description'),
        link_open: 'apps/rabobank-loan',
      },
      new OrderWriterEdiIntegrationApp(),
      new BestsellerEdiIntegrationApp(),
      {
        id: AppsComponent.LOCATIONS_APP_ID,
        icon: 'fa-regular fa-location-dot',
        title: Locale.translate('multiple_locations'),
        summary: Locale.translate('manage_multiple_locations_for_your_stores_and_stock'),
        link_open: 'locations',
        app_name: Feature.APP_CORE_RELATIONS_MODULE_MULTI_LOCATION,
      },
      {
        id: AppsComponent.ORDER_PICKING_APP_ID,
        icon: 'isax isax-d-rotate',
        title: Locale.translate('app[core.ShopModule.OrderPicking]title'),
        summary: Locale.translate('app[core.ShopModule.OrderPicking]summary'),
        description: Locale.translate('app[core.ShopModule.OrderPicking]description'),
        link_open: 'order-picking',
        app_name: Feature.APP_NAME_ORDER_PICKING,
      },
    ];
  },

  getWebshopWithSync() {
    return ShopsCollection.chain()
      .filter((model) => model.isTypeAlias(ShopTypeCollection.ALIAS_WEB_WITH_SYNC))
      .map((model) => new SalesChannelAppModel({
        shopModel: model,
        title: Locale.translate('webshop_{0}', model.get('name')),
        summary: Locale.translate('manage_your_webshop_connection'),
      }))
      .value();
  },

  getWinkelStraatShops() {
    return ShopsCollection.chain()
      .filter((model) => model.isTypeAlias(ShopTypeCollection.ALIAS_WINKELSTRAAT))
      .map((model) => new SalesChannelAppModel({
        shopModel: model,
        logo: '/images/apps/winkelstraat.png',
        title: Locale.translate('winkelstraat_integration_{0}', model.get('name')),
        summary: Locale.translate('manage_your_winkelstraat_integration'),
      }))
      .value();
  },

  getSitedishShops() {
    return ShopsCollection.chain()
      .filter((model) => model.isTypeAlias(ShopTypeCollection.ALIAS_SITEDISH) && model.get('published'))
      .map((model) => new SalesChannelAppModel({
        shopModel: model,
        logo: '/images/apps/sitedish.jpg',
        title: Locale.translate('manage_{name}_sitedish', { name: model.get('name') }),
        summary: Locale.translate('manage_sitedish_connection'),
      }))
      .value();
  },

  getLocalTomorrowShops() {
    return ShopsCollection.chain()
      .filter((model) => model.isTypeAlias(ShopTypeCollection.ALIAS_LOCALTOMORROW) && model.get('published'))
      .map((model) => new SalesChannelAppModel({
        shopModel: model,
        logo: '/images/apps/bakeronline.png',
        title: Locale.translate('manage_{name}_localtomorrow', { name: model.get('name') }),
        summary: Locale.translate('manage_localtomorrow_connection'),
      }))
      .value();
  },

  getOrderRequestShops() {
    return ShopsCollection.chain()
      .filter((model) => model.isTypeAlias(ShopTypeCollection.ALIAS_ORDER_REQUEST) && model.get('published'))
      .map((model) => new SalesChannelAppModel({
        shopModel: model,
        icon: 'fal fa-bags-shopping',
        title: Locale.translate('manage_{name}_order_request_channel', { name: model.get('name') }),
        summary: Locale.translate('send_order_request_with_predefined_rows_using_email_and_let_you_customers_pay_online_for_it'),
      }))
      .value();
  },
}));
