define([
        'modules/upx/models/model',
        './structs/RaboBankSmartPayProvider'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'provider_id',
            object: 'RaboBankSmartPayProvider',
            requestPartnerUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('requestRaboBankSmartPayProviderPartnerUrl',parameters, ajaxOptions);
            },
            verify: function (parameters, ajaxOptions) {
                return this.callObjectMethod('verifyRaboBankSmartPayProvider',parameters, ajaxOptions);
            }
        });
    }
);