define([
  'jquery',
  'underscore',
  'modules/shop/models/App/SupplierEdiIntegrationApp.js',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
], (
  $, _, SupplierEdiIntegrationAppModel, LinkModel,
  Locale, Feature, Upx, AppsComponent,
) => SupplierEdiIntegrationAppModel.extend({

  defaults: _.extend(
    {},
    SupplierEdiIntegrationAppModel.prototype.defaults,
    {
      id: AppsComponent.BESTSELLER_INTEGRATION_APP_ID,
      icon: 'fal fa-clothes-hanger',
      logo: '/images/apps/bestseller-icon.png',
      title: Locale.translate('app[core.ShopModule.Bestseller]title'),
      summary: Locale.translate('app[core.ShopModule.Bestseller]summary'),
      description: Locale.translate('app[core.ShopModule.Bestseller]description'),
      link_open: 'bestseller',
      app_name: Feature.APP_NAME_CORE_SHOP_MODULE_BESTSELLER_INTEGRATION,
      type_module_alias: 'Bestseller',
    },
  ),

  install() {
    const def = new $.Deferred();
    this.getIntegration()
      .then(
        (integration) => {
          $.when(integration)
            .then(
              () => {
                $.when(
                  LinkModel.prototype.install.call(this),
                ).then(
                  def.resolve,
                  def.reject,
                );
              },
              def.reject,
            );
        },
        def.reject,
      );
    return def.promise();
  },

  newIntegration() {
    return Upx.call('ShopModule', 'newBestsellerIntegration');
  },
}));
