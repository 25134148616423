define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop/templates/apps/overview/loyaltyProgramPopup.hbs',

  'modules/common/components/locale',
  'modules/common/components/currency',
  'modules/shop/components/loyaltyProgram.js',

  'modules/shop/models/shopConfiguration',
], (
  $, _, Backbone, Template,
  Locale, Currency, LoyaltyProgramComponent,
  ShopConfiguration,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  serializeData() {
    const currency_iso3 = ShopConfiguration.getCurrencyIso3();
    return _.extend({}, this.model.toJSON(), {
      currency_iso3,
      earn_on_euro: LoyaltyProgramComponent.earnOnInvoiceConvertForDisplay(this.model.get('cents_per_point_on_invoice')),
      get_for_point: LoyaltyProgramComponent.asPaymentConvertForDisplay(this.model.get('points_per_cent_with_payment')),
      translateVars: {
        one_euro: Currency.format(currency_iso3, 1),
      },
    });
  },

}));
