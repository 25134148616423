define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/BillingIntegrationModule/collections/ExactOnlineIntegration',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  IntegrationCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.EXACT_ONLINE_INTEGRATION_APP_ID,
      logo: '/images/apps/exactonline.png',
      title: Locale.translate('exact_online_integration'),
      summary: Locale.translate('synchronize_on_account_invoices_to_exact'),
      type: this.TYPE_LINK,
      link_open: 'apps/exact-online',
      app_name: Feature.APP_NAME_CORE_EXACT_ONLINE_INTEGRATION,
    },
  ),

  getUninstallContent() {
    return Locale.translate('disconnecting_will_remove_the_connection_to_exact_online_it_won_t_remove_any_data_from_exact_online_it_needs_to_be_if_needed_done_manually');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getIntegration(),
    ).then((model) => {
      $.when(
        !model || Upx.call('BillingIntegrationModule', 'deactivateExactOnlineIntegration',
          {
            id: model.get('id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  ensureIntegration() {
    const def = new $.Deferred();

    $.when(
      this.getIntegration(),
    ).then((integrationModel) => {
      if (integrationModel) {
        def.resolve(integrationModel); // there is model
      } else {
        // create new model
        const model = new (IntegrationCollection.prototype.model)();
        model.save().then(
          (id) => {
            model.set({ id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  connect() {
    const def = new $.Deferred();
    this.ensureIntegration()
      .then(
        (model) => {
          Upx.call('BillingIntegrationModule', 'getExactOnlineAuthorizationUrl',
            {
              exact_online_integration_id: model.get('id'),
              redirect_url: window.location.href,
            }).then(
            (url) => {
              window.location = url;
              def.resolve();
            },
            def.reject,
          );
        },
        def.reject,
      );
    return def.promise();
  },

  getIntegration() {
    const def = new $.Deferred();

    const collection = new IntegrationCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'active__=',
        val: '1',
      }],
    };

    collection
      .fetch({ params })
      .then(
        () => {
          def.resolve(collection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
