define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',
  'upx.modules/ShopModule/models/LoyaltyProgram',

  'modules/shop/views/apps/overview/loyaltyProgramPopup',

  'modules/common/components/locale',
  'modules/shop/components/popups',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
  'modules/shop/templates/loyaltyProgram/details/endAlertContent.hbs',

  'modules/shop/models/upx/LoyaltyProgram',

  'toastr',
], (
  $, _, LinkModel, UpxLoyaltyProgramModel,
  LoyaltyProgramPopupView,
  Locale, Popups, Feature, Upx, AppsComponent, EndAlertContentTemplate,
  LoyaltyProgramModel,
  Toastr,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.LOYALTY_PROGRAM_APP_ID,
      icon: 'fal fa-grin-hearts',
      title: Locale.translate('loyalty_program'),
      summary: Locale.translate('allow_your_customers_to_earn_points_on_each_paid_order_than_exchange_them_for_bonuses'),
      link_open: 'loyalty-program',
      app_name: Feature.APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM,
    },
  ),

  install() {
    const def = new $.Deferred();

    const startInstall = () => {
      const installDef = new $.Deferred();
      Feature.changeFeatureStatus(this.get('app_name'), true)
        .then(
          () => {
            Toastr.success(
              Locale.translate('app_{title}_was_installed_successfully', {
                title: this.get('title'),
              }),
            );
            this.restore();
            this.setFeature();
            this.configureModel();
            installDef.resolve();
          },
          (error) => {
            Toastr.error(
              Locale.translate('failed_to_install_{title}_app', {
                title: this.get('title'),
              }),
              error.error || error.mgs || error.mes,
            );
            installDef.reject(error);
          },
        );

      return installDef.promise();
    };

    Upx.call('ShopModule', 'listLoyaltyPrograms',
      {
        start: 0,
        limit: 1,
        sort: [{
          name: 'date_created',
          dir: 'desc',
        }],
        filters: [{
          name: 'deleted__=',
          val: true,
        }],
      }).then(({ data }) => {
      if (data.length) {
        const previousLoyaltyProgramModel = new UpxLoyaltyProgramModel(data[0]);
        const contentView = new LoyaltyProgramPopupView({
          model: previousLoyaltyProgramModel,
        });
        Popups.popup({
          modalSize: 'modal-lg',
          showCancel: true,
          titleIcon: false,
          titleText: Locale.translate('loyalty_program_settings'),
          contentView,
          acceptClass: 'btn btn-success',
          cancelClass: 'btn btn-alt btn-success',
          acceptText: Locale.translate('use_previous_settings'),
          cancelText: Locale.translate('create_new_settings'),
          showCancelTop: false,
        }).then(() => {
          startInstall().then(() => {
            this.getProgram().then((model) => {
              model.setFromModel(previousLoyaltyProgramModel);

              model.save().then(def.resolve, def.reject);
            }, def.reject);
          }, def.reject);
        }, () => {
          startInstall().then(def.resolve, def.reject);
        });
      } else {
        startInstall().then(def.resolve, def.reject);
      }
    }, def.reject);

    return def.promise();
  },

  getUninstallContent() {
    return EndAlertContentTemplate;
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getProgram(),
    ).then((model) => {
      $.when(
        !model.get('id') || Upx.call('ShopModule', 'deleteLoyaltyProgram',
          {
            id: model.get('id'),
          }),
      )
        .then(
          () => {
            LoyaltyProgramModel.unload();
            LinkModel.prototype.uninstall.call(this).then(
              def.resolve,
              def.reject,
            );
          },
          def.reject,
        );
    });
    return def.promise();
  },

  getProgram() {
    const def = new $.Deferred();
    LoyaltyProgramModel.load(true).then(
      () => def.resolve(LoyaltyProgramModel),
      def.reject,
    );
    return def.promise();
  },

}));
