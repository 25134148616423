define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',

  'upx.modules/ParcelModule/collections/Provider',
  'upx.modules/ParcelModule/collections/Parcel',
  'modules/shop/components/popups',

  'upx.modules/VeloydParcelModule/models/Provider',
  'modules/shop/components/parcelProvider.js',
], (
  $, _, LinkModel,
  Locale, Feature, Upx,
  ProviderCollection, ParcelCollection, Popups,
  VeloydProviderModel, ParcelProvider,
) => LinkModel.extend({

  initialize() {
    if (!this.get('type')) {
      throw new Error('Type needs to be set for ParcelApp');
    }
    LinkModel.prototype.initialize.call(this);
  },

  loadUninstallVars() {
    const def = new $.Deferred();
    this.getProvider().then(
      (provider) => {
        let hasParcels = false;

        if (!provider) {
          def.resolve({ hasParcels });
          return;
        }
        const paymentsCollection = new ParcelCollection();
        const params = {
          start: 0,
          limit: 1,
          filters: [
            {
              name: 'provider_id__=',
              val: provider.get('id'),
            },
          ],
        };
        paymentsCollection.fetch({ params })
          .then(
            () => {
              hasParcels = paymentsCollection.length > 0;
              def.resolve({ hasParcels });
            },
            def.reject,
          );
      },
      def.reject,
    );
    return def.promise();
  },

  uninstallPopupWithVars({ hasParcels }) {
    const def = new $.Deferred();
    if (hasParcels) {
      Popups.information(
        Locale.translate('this_provider_cannot_be_removed_because_it_already_has_parcels_created'),
      );
      def.reject();
    } else {
      Popups.confirmRemove(
        this.getUninstallContent(),
        this.getUninstallPopupOptions(),
      ).then(
        () => this.uninstall().then(
          def.resolve, def.reject,
        ),
        def.reject,
      );
    }
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    this.getProvider().then((provider) => {
      $.when(
        !provider || provider.destroy(),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  getProvider() {
    const def = new $.Deferred();

    const providerCollection = new ProviderCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'provider_type/alias__=',
        val: this.get('type'),
      }],
    };

    providerCollection
      .fetch({ params })
      .then(
        () => {
          const providerModel = providerCollection.first();

          if (providerModel && providerModel.get('provider_type.alias') === ParcelProvider.ALIAS_VELOYD) {
            const veloydModel = new VeloydProviderModel({
              provider: providerModel.toJSON(),
            });

            veloydModel.set('id', providerModel.get('id'));
            veloydModel.set('provider_type.alias', providerModel.get('provider_type.alias'));
            veloydModel.fetch().then(() => {
              def.resolve(veloydModel);
            }, def.reject);
          } else {
            def.resolve(providerModel);
          }
        },
        def.reject,
      );

    return def.promise();
  },

}));
