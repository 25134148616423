define([
        'modules/upx/models/model',
        './structs/Configuration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'FiskalyModule',

            object: 'Configuration',
            configure: function (parameters, ajaxOptions) {
                return this.callObjectMethod('configureConfiguration',parameters, ajaxOptions);
            },
            reconfigure: function (parameters, ajaxOptions) {
                return this.callObjectMethod('reconfigureConfiguration',parameters, ajaxOptions);
            },
            checkConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkConfigurationConnection',parameters, ajaxOptions);
            },
            unconfigure: function (parameters, ajaxOptions) {
                return this.callObjectMethod('unconfigureConfiguration',parameters, ajaxOptions);
            }
        });
    }
);