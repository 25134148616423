define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/orderAuth',
], (AclRouter, LoggedIn, OrderAuthAcl) => AclRouter.extend({

  appRoutes: {
    'used-goods': 'list',
  },

  acls: [
    new LoggedIn(),
    new OrderAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/usedGoods'], callback);
  },
}));
