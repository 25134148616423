define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'hospitality-settings(/:content)': 'settings',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/hospitality'], callback);
  },
}));
