define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/FiskalyModule/collections/Configuration',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  ConfigurationCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.FISKALY_APP_ID,
      logo: '/images/apps/fiskaly-square.png',
      icon: 'fa-light fa-money-bill',
      title: Locale.translate('fiskaly_integration'),
      summary: Locale.translate('the_fiskaly_tss_for_the_kassensichv_and_dsfinvk_for_german_clients'),
      link_open: 'apps/fiskaly',
      app_name: Feature.APP_NAME_CORE_FISKALY_MODULE_FISKALY,
    },
  ),

  getUninstallContent() {
    return Locale.translate('are_you_sure_you_want_to_disconnect_fiskaly_it_will_disable_the_current_tss_once_tss_is_disabled_it_cannot_be_enabled_again_reconconnecting_after_will_create_a_one_tss');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getConfiguration(),
    ).then((model) => {
      const onSuccess = () => LinkModel.prototype.uninstall.call(this).then(
        def.resolve,
        def.reject,
      );
      model.unconfigure().then(
        onSuccess,
        (error) => {
          if (
            error.class === 'FiskalyModule::Client'
                    && error.details.api_code === 'E_TSS_DELETED'
          ) {
            // demo fiskaly accounts gets deleted each week
            model.unconfigure({ fields: { id: this.model.get('id'), delete_local: true } }).then(
              onSuccess,
              def.reject,
            );
          } else {
            def.reject(error);
          }
        },
      );
    });
    return def.promise();
  },

  getConfiguration() {
    const def = new $.Deferred();

    const providerCollection = new ConfigurationCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
    };

    providerCollection
      .fetch({ params })
      .then(
        () => {
          def.resolve(providerCollection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
