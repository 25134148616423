define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'repairs/create(/:relation_data_id)': 'newRepair',
    repairs: 'list',
    'repairs/:id': 'view',
    'repairs/:id/:swapTo': 'view',
    'repair-questions/create': 'newRepairQuestion',
    'repair-questions': 'repairQuestions',
    'repair-questions/:id': 'viewRepairQuestion',
  },

  acls: [
    new LoggedIn(),

    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/repairs'], callback);
  },
}));
