define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/apps',
  'modules/shop/components/feature',
], (
  $, _, LinkModel,
  Locale, AppsComponent, Feature,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.NEW_WEBSHOP_WITH_SYNC_APP_ID,
      icon: 'fal fa-plus',
      title: Locale.translate('new_webshop_with_sync'),
      summary: Locale.translate('connect_new_woocommerce_webshop'),
      link_open: 'apps/create-webshop-with-sync',
      app_name: Feature.APP_NAME_CORE_SHOP_MODULE_WEB_SHOP_COUNT,
    },
  ),

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
