define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    stocks: 'list',
    'stock-mutations': 'listMutations',
    'stock-mutations/supplier-order/:id': 'listMutationsForSupplierOrder',
    'stock-mutations/product/:id': 'listMutationsForProduct',
    'stock/assembly-recipes': 'assembly',
    'stock/assembly-recipes/:swapTo': 'assembly',
    'stock/assembly-recipes/:swapTo/:id': 'assembly',
  },

  acls: [
    new LoggedIn(),

    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/stocks'], callback);
  },
}));
