define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'product-labels-print/choose-product/:product_id': 'details',
    'product-labels-print': 'details',
    'product-labels-print/test/:format(/:quantity)': 'testPrint',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/productLabelPrint'], callback);
  },
}));
