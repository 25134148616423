define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',
], (
  $, _, LinkModel,
  Locale, Feature, AppsComponent,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.ORDER_REQUEST_APP_ID,
      icon: 'fal fa-bags-shopping',
      title: Locale.translate('new_order_request_sales_channel'),
      summary: Locale.translate('send_order_request_with_predefined_rows_using_email_and_let_you_customers_pay_online_for_it'),
      link_open: 'apps/create-order-request-channel',
      app_name: Feature.APP_CORE_SHOP_MODULE_ORDER_REQUEST_SALES_CHANNEL,
    },
  ),

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },
}));
