define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',
], (
  $, _, LinkModel,
  Locale, Feature, AppsComponent,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.WINKELSTRAAT_INTEGRATION_APP_ID,
      logo: '/images/apps/winkelstraat.png',
      title: Locale.translate('new_winkelstraat_nl_integration'),
      summary: Locale.translate('sell_your_products_to_winkelstraat_nl_live_synchronization_of_stock'),
      link_open: 'apps/create-winkelstraat-integration',
      app_name: Feature.APP_CORE_COMMERCE_MODULE_WINKEL_STRAAT_SHOP,
    },
  ),

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
