define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/anyAppAuth',
], (
  AclRouter, LoggedIn, AnyAppAuthAcl,
) => AclRouter.extend({

  appRoutes: {
    apps: 'overview',
    'apps/:app': 'details',
    'apps/:app/:page': 'details',
    'apps/:app/:page/:cacheId': 'details',
  },

  acls: [
    new LoggedIn(),
    new AnyAppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/apps'], callback);
  },
}));
