define([
        'modules/upx/collections/collection',
        '../models/JobSchedule',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'LinkingPinsModule',
            object: 'JobSchedule',
            collection_method: 'listJobSchedules',
            model: Model
        });
    }
);