define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/ShopType',
  'modules/common/components/locale',

  'modules/shop/components/apps.js',
], (
  $, _, Backbone, ShopTypeCollection, Locale,

  Apps,
) => {
  const ALIAS_WEB = 'Web';
  const ALIAS_WEB_WITH_SYNC = 'WebWithSync';
  const ALIAS_POINT_OF_SALE = 'PointOfSale';
  const ALIAS_FALLBACK = 'Fallback';
  const ALIAS_BACKOFFICE = 'Backoffice';
  const ALIAS_SITEDISH = 'Sitedish';
  const ALIAS_ORDER_REQUEST = 'OrderRequest';
  const ALIAS_WINKELSTRAAT = 'WinkelStraat';
  const ALIAS_SK_AS_POS = 'SkAsPOS';
  const ALIAS_LOCALTOMORROW = 'LocalTomorrow';

  const ALIAS_BOL_FLUSE = 'BolViaFluse';

  // Shop types
  const WEBSHOP_SHOPIFY = 'shopify';
  const WEBSHOP_CCV = 'ccv-shop';
  const WEBSHOP_PRESTASHOP = 'prestashop';
  const WEBSHOP_MAGENTO2 = 'magento2';
  const WEBSHOP_SHOPTRADER = 'shoptrader';
  const WEBSHOP_WOOCOMMERCE = 'woocommerce';
  const WEBSHOP_SHOPWARE6 = 'shopware6';
  const WEBSHOP_WITH_SYNC_WC = 'wws_woocommerce';
  const WEBSHOP_WITH_SYNC_M2 = 'wws_magento2';
  const WEBSHOP_WITH_SYNC_SHOPTRADER = 'wws_shoptrader';

  const WEBSHOP_SHOPIFY_FLAVOUR = 'StoreKeeper/Mercurius/Shopify';
  const WEBSHOP_CCV_FLAVOUR = 'StoreKeeper/Mercurius/CCV';
  const WEBSHOP_PRESTASHOP_FLAVOUR = 'StoreKeeper/Mercurius/PrestaShop';
  const WEBSHOP_MAGENTO2_FLAVOUR = 'StoreKeeper/Mercurius/Magento2';
  const WEBSHOP_SHOPTRADER_FLAVOUR = 'StoreKeeper/Mercurius/ShopTrader'; // it's Shoptrader, but we leave if because of compatibility
  const WEBSHOP_WOOCOMMERCE_FLAVOUR = 'StoreKeeper/Mercurius/WooCommerce';
  const WEBSHOP_SHOPWARE6_FLAVOUR = 'StoreKeeper/Mercurius/Shopware6';
  const WEBSHOP_OTHER_FLAVOUR = 'StoreKeeper/Other/Other';

  const WEBSHOP_WP_WOOCOMMERCE_FLAVOUR = 'StoreKeeper/WordPress/storekeeper-woocommerce-b2c';
  const WEBSHOP_M2_MAGENTO2_FLAVOUR = 'StoreKeeper/Magento2/magento2-plugin';
  const WEBSHOP_WWS_SHOPTRADER_FLAVOUR = 'Shoptrader/Shoptrader/widget';

  const POS_RETAIL = 'retail';
  const POS_FOOD = 'restaurant';

  const Collection = ShopTypeCollection.extend({

    loaded: false,

    fetchParameters: {
      start: 0,
      limit: 0,
    },

    load(force) {
      const def = new $.Deferred();

      // force fallback to false.
      force = _.isUndefined(force) ? false : force;

      // Unload if force is enabled
      force ? this.unload() : null;

      // If done, mark as loaded
      def.done(() => { this.loaded = true; });

      // fetch is not yet loaded
      if (!this.loaded) {
        this.fetch({ params: this.fetchParameters })
          .then(def.resolve, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    unload() {
      this.reset();
      this.loaded = false;
    },

    ALIAS_WEB,
    ALIAS_WEB_WITH_SYNC,
    ALIAS_POINT_OF_SALE,
    ALIAS_FALLBACK,
    ALIAS_BACKOFFICE,
    ALIAS_SITEDISH,
    ALIAS_ORDER_REQUEST,
    ALIAS_WINKELSTRAAT,
    ALIAS_SK_AS_POS,
    ALIAS_LOCALTOMORROW,
    ALIAS_BOL_FLUSE,

    POS_FOOD,
    POS_RETAIL,

    getIdByAlias(alias) {
      const model = this.findWhere({
        alias,
      });

      if (model) {
        return model.get('id');
      }
      return null;
    },

    MODULE_SHOPMODULE: 'ShopModule',

    getIdByModuleAndAlias(module, alias) {
      const model = this.findWhere({
        module_name: module,
        alias,
      });

      if (model) {
        return model.get('id');
      }
      return null;
    },

    getIconByAlias(alias, prefix) {
      prefix = prefix || 'isax';
      switch (alias) {
        case this.ALIAS_WEB:
        case this.ALIAS_SK_AS_POS:
        case this.ALIAS_WEB_WITH_SYNC:
        case this.ALIAS_WINKELSTRAAT:
          return `${prefix} isax-monitor`;
        case this.ALIAS_BOL_FLUSE:
          return `${prefix} isax-bag`;
        case this.ALIAS_POINT_OF_SALE:
          return `${prefix} isax-shop`;
        case this.ALIAS_SITEDISH:
        case this.ALIAS_LOCALTOMORROW:
          return `${prefix} isax-menu-1`;
        case this.ALIAS_ORDER_REQUEST:
          return `${prefix} isax-bag`;
        case this.ALIAS_BACKOFFICE:
        case this.ALIAS_FALLBACK:
        default:
          return `${prefix} isax-buildings`;
      }
    },

    WEBSHOP_SHOPIFY,
    WEBSHOP_CCV,
    WEBSHOP_PRESTASHOP,
    WEBSHOP_MAGENTO2,
    WEBSHOP_SHOPTRADER,
    WEBSHOP_WOOCOMMERCE,
    WEBSHOP_SHOPWARE6,
    WEBSHOP_WITH_SYNC_WC,
    WEBSHOP_WITH_SYNC_M2,
    WEBSHOP_WITH_SYNC_SHOPTRADER,

    WEBSHOP_SHOPIFY_FLAVOUR,
    WEBSHOP_CCV_FLAVOUR,
    WEBSHOP_PRESTASHOP_FLAVOUR,
    WEBSHOP_MAGENTO2_FLAVOUR,
    WEBSHOP_SHOPTRADER_FLAVOUR,
    WEBSHOP_WOOCOMMERCE_FLAVOUR,
    WEBSHOP_SHOPWARE6_FLAVOUR,
    WEBSHOP_OTHER_FLAVOUR,
    WEBSHOP_WP_WOOCOMMERCE_FLAVOUR,
    WEBSHOP_M2_MAGENTO2_FLAVOUR,
    WEBSHOP_WWS_SHOPTRADER_FLAVOUR,

    getShopNameByType(type) {
      switch (type) {
        case this.WEBSHOP_SHOPIFY:
          return 'Shopify';
        case this.WEBSHOP_CCV:
          return 'CCV Shop';
        case this.WEBSHOP_PRESTASHOP:
          return 'PrestaShop';
        case this.WEBSHOP_MAGENTO2:
          return 'Magento 2';
        case this.WEBSHOP_SHOPTRADER:
        case this.WEBSHOP_WWS_SHOPTRADER_FLAVOUR:
          return 'Shoptrader';
        case this.WEBSHOP_WOOCOMMERCE:
          return 'WooCommerce';
        case this.WEBSHOP_SHOPWARE6:
          return 'Shopware 6';
        default:
          console.warn('Not defined shop type', type);
          return type;
      }
    },

    getShopTypeFromFlavour(flavour) {
      switch (flavour) {
        case this.WEBSHOP_SHOPIFY_FLAVOUR:
          return this.WEBSHOP_SHOPIFY;
        case this.WEBSHOP_CCV_FLAVOUR:
          return this.WEBSHOP_CCV;
        case this.WEBSHOP_PRESTASHOP_FLAVOUR:
          return this.WEBSHOP_PRESTASHOP;
        case this.WEBSHOP_MAGENTO2_FLAVOUR:
          return this.WEBSHOP_MAGENTO2;
        case this.WEBSHOP_SHOPTRADER_FLAVOUR:
          return this.WEBSHOP_SHOPTRADER;
        case this.WEBSHOP_WOOCOMMERCE_FLAVOUR:
          return this.WEBSHOP_WOOCOMMERCE;
        case this.WEBSHOP_SHOPWARE6_FLAVOUR:
          return this.WEBSHOP_SHOPWARE6;
        case this.WEBSHOP_WP_WOOCOMMERCE_FLAVOUR:
          return this.WEBSHOP_WITH_SYNC_WC;
        case this.WEBSHOP_M2_MAGENTO2_FLAVOUR:
          return this.WEBSHOP_WITH_SYNC_M2;
        case this.WEBSHOP_WWS_SHOPTRADER_FLAVOUR:
          return this.WEBSHOP_WITH_SYNC_SHOPTRADER;
        default:
          console.warn('Not defined shop type', flavour);
          return flavour;
      }
    },

    getFlavourFromShopType(type) {
      switch (type) {
        case this.WEBSHOP_SHOPIFY:
          return this.WEBSHOP_SHOPIFY_FLAVOUR;
        case this.WEBSHOP_CCV:
          return this.WEBSHOP_CCV_FLAVOUR;
        case this.WEBSHOP_PRESTASHOP:
          return this.WEBSHOP_PRESTASHOP_FLAVOUR;
        case this.WEBSHOP_MAGENTO2:
          return this.WEBSHOP_MAGENTO2_FLAVOUR;
        case this.WEBSHOP_SHOPTRADER:
          return this.WEBSHOP_SHOPTRADER_FLAVOUR;
        case this.WEBSHOP_WOOCOMMERCE:
          return this.WEBSHOP_WOOCOMMERCE_FLAVOUR;
        case this.WEBSHOP_SHOPWARE6:
          return this.WEBSHOP_SHOPWARE6_FLAVOUR;
        case this.WEBSHOP_WITH_SYNC_WC:
          return this.WEBSHOP_WP_WOOCOMMERCE_FLAVOUR;
        case this.WEBSHOP_WITH_SYNC_M2:
          return this.WEBSHOP_M2_MAGENTO2_FLAVOUR;
        case this.WEBSHOP_WITH_SYNC_SHOPTRADER:
          return this.WEBSHOP_WWS_SHOPTRADER_FLAVOUR;
        default:
          console.warn('Not defined shop type', type);
          return this.WEBSHOP_OTHER_FLAVOUR;
      }
    },

    getSkAsPosShopTypes() {
      return [
        this.WEBSHOP_SHOPIFY,
        this.WEBSHOP_CCV,
        this.WEBSHOP_PRESTASHOP,
        this.WEBSHOP_MAGENTO2,
        this.WEBSHOP_SHOPTRADER,
        this.WEBSHOP_WOOCOMMERCE,
        this.WEBSHOP_SHOPWARE6,
      ];
    },

    getWebshopWithSyncShopTypes() {
      return [
        this.WEBSHOP_WITH_SYNC_WC,
        this.WEBSHOP_WITH_SYNC_M2,
        this.WEBSHOP_WITH_SYNC_SHOPTRADER,
      ];
    },

    isWebshopWithSyncType(type) {
      return this.getWebshopWithSyncShopTypes().includes(type);
    },

    isSkAsPosType(type) {
      return this.getSkAsPosShopTypes().includes(type);
    },

    isPointOfSaleTypeId(id) {
      const m = this.get(id);
      return m && m.get('alias') === ALIAS_POINT_OF_SALE;
    },
    getAppIdFromShopType(type) {
      if (this.getSkAsPosShopTypes().includes(type)) {
        return `new-sk-as-pos-${type}`;
      }
      if (type === this.WEBSHOP_WITH_SYNC_M2) {
        return Apps.NEW_WEBSHOP_WITH_SYNC_MAGENTO2_APP_ID;
      }
      if (type === this.WEBSHOP_WITH_SYNC_WC) {
        return Apps.NEW_WEBSHOP_WITH_SYNC_WOOCOMMERCE_APP_ID;
      }
      if (type === this.WEBSHOP_WITH_SYNC_SHOPTRADER) {
        return Apps.NEW_WEBSHOP_WITH_SYNC_SHOPTRADER_APP_ID;
      }
      return Apps.NEW_WEBSHOP_WITH_SYNC_APP_ID;
    },

    getNameByAlias(alias) {
      switch (alias) {
        case this.ALIAS_SK_AS_POS:
          return Locale.translate('pos_for_webshop_sync');
        case this.ALIAS_WEB:
          return Locale.translate('web');
        case this.ALIAS_WEB_WITH_SYNC:
          return Locale.translate('webshop_with_sync');
        case this.ALIAS_BACKOFFICE:
          return Locale.translate('backoffice');
        case this.ALIAS_POINT_OF_SALE:
          return Locale.translate('point_of_sale');
        case this.ALIAS_FALLBACK:
          return Locale.translate('other');
        case this.ALIAS_SITEDISH:
          return 'SiteDish';
        case this.ALIAS_ORDER_REQUEST:
          return Locale.translate('order_request');
        case this.ALIAS_WINKELSTRAAT:
          return Locale.translate('winkelstraat');
        case this.ALIAS_LOCALTOMORROW:
          return Locale.translate('localtomorrow');
        default:
          console.warn('Not defined shop type alias', alias);
          return alias;
      }
    },

    getFlavourFromSalesChannelShopInfo(shop) {
      const { vendor, platform_name, software_name } = shop;

      if (vendor && platform_name && software_name) {
        return `${vendor}/${platform_name}/${software_name}`;
      }

      return this.WEBSHOP_OTHER_FLAVOUR;
    },

  });

  Collection.ALIAS_WEB = ALIAS_WEB;
  Collection.ALIAS_SK_AS_POS = ALIAS_SK_AS_POS;
  Collection.ALIAS_WEB_WITH_SYNC = ALIAS_WEB_WITH_SYNC;
  Collection.ALIAS_POINT_OF_SALE = ALIAS_POINT_OF_SALE;
  Collection.ALIAS_FALLBACK = ALIAS_FALLBACK;
  Collection.ALIAS_BACKOFFICE = ALIAS_BACKOFFICE;
  Collection.ALIAS_ORDER_REQUEST = ALIAS_ORDER_REQUEST;
  Collection.ALIAS_SITEDISH = ALIAS_SITEDISH;
  Collection.ALIAS_LOCALTOMORROW = ALIAS_LOCALTOMORROW;
  Collection.ALIAS_WINKELSTRAAT = ALIAS_WINKELSTRAAT;

  Collection.WEBSHOP_SHOPIFY = WEBSHOP_SHOPIFY;
  Collection.WEBSHOP_CCV = WEBSHOP_CCV;
  Collection.WEBSHOP_PRESTASHOP = WEBSHOP_PRESTASHOP;
  Collection.WEBSHOP_MAGENTO2 = WEBSHOP_MAGENTO2;
  Collection.WEBSHOP_SHOPTRADER = WEBSHOP_SHOPTRADER;
  Collection.WEBSHOP_WOOCOMMERCE = WEBSHOP_WOOCOMMERCE;
  Collection.WEBSHOP_SHOPWARE6 = WEBSHOP_SHOPWARE6;
  Collection.WEBSHOP_WITH_SYNC_SHOPTRADER = WEBSHOP_WITH_SYNC_SHOPTRADER;
  return new Collection();
});
