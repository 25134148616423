define([
        'modules/upx/models/model',
        './structs/TwinfieldIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BillingIntegrationModule',
            idAttribute: 'id',
            object: 'TwinfieldIntegration',
            getStats: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getTwinfieldIntegrationStats',parameters, ajaxOptions);
            },
            deactivate: function (parameters, ajaxOptions) {
                return this.callObjectMethod('deactivateTwinfieldIntegration',parameters, ajaxOptions);
            },
            setOfficeCode: function (parameters, ajaxOptions) {
                return this.callObjectMethod('setTwinfieldIntegrationOfficeCode',parameters, ajaxOptions);
            }
        });
    }
);