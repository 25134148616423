define([
  'jquery',
  'backbone',
  'modules/shop/components/promisify',
  'toastr',
],
($, Backbone, Promisify, Toastr) => Backbone.Marionette.Behavior.extend({

  defaults: {
    setupLoaderPosition: true,
    name: null,
    viewParameters: 'loader',
  },

  initialize() {
    this.inputs = false;
    this.view[this.options.viewParameters] = this;
  },

  onRender() {
    this.initLoader();
  },

  initLoader() {
    this.addLoader();
    if (this.options.setupLoaderPosition) {
      this.setupLoaderPosition();
    }
  },

  addLoader() {
    if (!this.view.$el.find('.loader-wrapper').length) {
      const name = this.options.name ? ` data-name="${this.options.name}"` : '';
      this.view.$el.prepend(`<div class="loader-wrapper"${name}><div class="loader"></div></div>`);
    }
  },

  showLoader(name) {
    this.inputs = this.view.$el.find(':enabled');
    this.inputs.prop('readonly', true);

    if (this.options.name) {
      this.view.$el.find(`.loader-wrapper[data-name="${this.options.name}"]`).show();
    } else if (name) {
      this.view.$el.find(`.loader-wrapper[data-name="${name}"]`).show();
    } else {
      this.view.$el.find('.loader-wrapper:not([data-name])').show();
    }
  },

  hideLoader(name) {
    if (this.inputs) {
      this.inputs.prop('readonly', false);
    }

    if (name) {
      this.view.$el.find(`.loader-wrapper[data-name="${name}"]`).hide();
    } else {
      this.view.$el.find('.loader-wrapper').hide();
    }
  },
  setupLoaderPosition() {
    this.view.$el.find('.loader-wrapper').parent().css('position', 'relative');
  },

  startAsyncLoader(asyncFunction, name, toastrErrors = false) {
    const deferred = this.startLoader(name, toastrErrors);
    const promise = Promisify.functionToPromise(asyncFunction);
    Promisify.bindPromiseToDeferred(promise, deferred);
  },

  startLoader(name, toastrErrors = false) {
    this.showLoader(name);
    const def = $.Deferred();
    const self = this;

    def.always(() => {
      self.hideLoader(name);
    });
    if (toastrErrors) {
      def.fail(
        (error) => {
          if (error) {
            Toastr.error(error.error || error.msg || error.mes || error.message || error);
          }
        },
      );
    }
    return def;
  },
}));
