define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
], (AclRouter, LoggedIn, CapabilityAuthAcl) => AclRouter.extend({

  appRoutes: {
    dashboard: 'dashboard',
  },

  acls: [
    new LoggedIn(),

    new CapabilityAuthAcl({
      name: CapabilityAuthAcl.ADMIN_DASHBOARD_CAPABILITY,
    }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/dashboard'], callback);
  },
}));
