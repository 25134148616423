define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ProductsModule/models/TaxRate',
  'modules/common/collections/countries',
], (
  $, _, Backbone, TaxRateModel, CountriesCollection,
) => TaxRateModel.extend({

  getLabel() {
    let percentage = (this.get('value') * 100).toFixed(1);
    // Keep decimal places to 1 if not a whole number, remove 0s if a whole number
    percentage = percentage % 1 ? percentage : Math.round(percentage);
    return `[ ${percentage}% ] ${this.get('name')}`;
  },

}));
