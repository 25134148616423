define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/productAuth',
], (AclRouter, LoggedIn, ProductAuthAcl) => AclRouter.extend({

  appRoutes: {
    'configurable-product-kind': 'list',
    'configurable-product-kind/create': 'create',
    'configurable-product-kind/edit/:id': 'edit',
    'configurable-product-kind/details/:id': 'details',
  },

  acls: [
    new LoggedIn(),
    new ProductAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/configurableProductKind'], callback);
  },
}));
