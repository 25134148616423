define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/ProductsModule/collections/FeaturedAttribute',
  'modules/shop/collections/TranslatedAttributeOption.js',
  'modules/common/components/locale',
], (
  $, _, Backbone,
  FeaturedAttributeCollection, TranslatedAttributeOptionCollection, Locale,
) => {
  const ALIAS_BRAND = 'brand';
  const ALIAS_BARCODE = 'barcode';
  const ALIAS_SEASON = 'season';
  const ALIAS_STYLE_ID = 'style_id';
  const ALIAS_FASHION_COLLECTION = 'fashion_collection';
  const Collection = FeaturedAttributeCollection.extend({

    offline: true,

    loaded: false,

    /**
     * Aliases
     */
    ALIAS_BRAND,
    ALIAS_BARCODE,
    ALIAS_NEEDS_WEIGHT_ON_KASSA: 'needs_weight_on_kassa',
    ALIAS_NEEDS_DESCRIPTION_ON_KASSA: 'needs_description_on_kassa',
    ALIAS_PRINTABLE_SHORTNAME: 'printable_shortname',
    ALIAS_SEASON,
    ALIAS_STYLE_ID,
    ALIAS_DURATION_SECONDS: 'duration_in_seconds',
    ALIAS_CONDITION: 'condition',
    ALIAS_SALES_UNIT: 'sales_unit',
    ALIAS_FASHION_COLLECTION,
    ALIAS_MANUFACTURER_SKU: 'manufacturer_sku',
    ALIAS_UNIT_WEIGHT_IN_G: 'unit_weight_in_g',

    FILTER_OPTION_ATTRIBUTES: [
      ALIAS_BRAND,
      ALIAS_SEASON,
      ALIAS_STYLE_ID,
      ALIAS_FASHION_COLLECTION,
    ],
    /**
     * Load fn
     */
    load(force) {
      const def = new $.Deferred();

      if (!this.loaded || force) {
        const parameters = {
          start: 0,
          limit: 0,
        };
        this.fetch({ params: parameters })
          .then(() => {
            // load attribute options for featured products
            const attributeIds = this.pluck('attribute_id');

            TranslatedAttributeOptionCollection.fetchSelectedAttributes(attributeIds).then(
              () => {
                this.loaded = true;
                def.resolve();
              },
              def.reject,
            );
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    isLoaded() {
      return this.loaded;
    },
    getAttributeLabel(alias) {
      if (alias === this.ALIAS_SEASON) {
        return Locale.translate(ALIAS_SEASON);
      }
      if (alias === this.ALIAS_BRAND) {
        return Locale.translate(ALIAS_BRAND);
      }
      if (alias === this.ALIAS_FASHION_COLLECTION) {
        return Locale.translate('fashion_collections');
      }
      if (alias === this.ALIAS_STYLE_ID) {
        return Locale.translate(ALIAS_STYLE_ID);
      }
      throw new Error(`Label for ${alias} is not defined`);
    },

    getFilterOptionAttributeIds() {
      let ids = this.FILTER_OPTION_ATTRIBUTES.map(
        (name) => this.getAttributeIdByAlias(name),
      );

      ids = ids.filter((attribute) => !!attribute);
      return ids;
    },

    getHiddenFeaturedAttributeIds() {
      let hiddenFeaturedAttributes = [
        this.getAttributeIdByAlias(this.ALIAS_BARCODE),
        this.getAttributeIdByAlias(this.ALIAS_BRAND),
        this.getAttributeIdByAlias(this.ALIAS_NEEDS_DESCRIPTION_ON_KASSA),
        this.getAttributeIdByAlias(this.ALIAS_NEEDS_WEIGHT_ON_KASSA),
        this.getAttributeIdByAlias(this.ALIAS_PRINTABLE_SHORTNAME),
        this.getAttributeIdByAlias(this.ALIAS_DURATION_SECONDS),
        this.getAttributeIdByAlias(this.ALIAS_UNIT_WEIGHT_IN_G),
        this.getAttributeIdByAlias(this.ALIAS_SALES_UNIT),
      ];

      hiddenFeaturedAttributes = hiddenFeaturedAttributes.filter((attribute) => !!attribute);
      return hiddenFeaturedAttributes;
    },

    getBaseProductFeaturedAttributeIds() {
      let hiddenFeaturedAttributes = [
        this.getAttributeIdByAlias(this.ALIAS_BARCODE),
        this.getAttributeIdByAlias(this.ALIAS_BRAND),
        this.getAttributeIdByAlias(this.ALIAS_NEEDS_DESCRIPTION_ON_KASSA),
        this.getAttributeIdByAlias(this.ALIAS_NEEDS_WEIGHT_ON_KASSA),
        this.getAttributeIdByAlias(this.ALIAS_PRINTABLE_SHORTNAME),
      ];

      hiddenFeaturedAttributes = hiddenFeaturedAttributes.filter((attribute) => !!attribute);
      return hiddenFeaturedAttributes;
    },
    /**
         * Other fn's
         */
    getAttributeByAlias(alias) {
      const attribute = this.findWhere({ alias });
      if (!attribute) {
        return null;
      }
      return attribute;
    },

    getAttributeIdByAlias(alias) {
      const attribute = this.getAttributeByAlias(alias);
      if (!attribute) {
        return null;
      }
      return attribute.get('attribute_id');
    },

    isFeaturedAttribute(attribute_id) {
      return this.where({ attribute_id: parseInt(attribute_id, 10) }).length > 0;
    },

    unload() {
      this.loaded = false;
      this.reset();
      this.getCacheStorage().cleanup(); // clean the cache to not load old profile
    },

    getValueFromContentVarsByAlias(alias, contentVars, value = null) {
      contentVars = contentVars || [];
      const wantedAttributeId = this.getAttributeIdByAlias(alias);
      contentVars.forEach((contentVar) => {
        if (contentVar.attribute_id === wantedAttributeId) {
          value = contentVar.value;
        }
      });
      return value;
    },

    updateContentVarsByAlias(alias, contentVars, updateValue = '') {
      // Ensure contentVars is an array
      if (!_.isArray(contentVars)) contentVars = [];

      // gather variables
      const newContentVars = [];
      const value = updateValue.trim();
      const isDeleted = !value;
      let updateExisting = false;

      // Get and check attribute
      const featuredAttribute = this.getAttributeByAlias(alias);
      if (!featuredAttribute) throw new Error(`Could not find featured attribute with alias ${alias}`);
      const attribute = featuredAttribute.get('attribute');

      // build the new content vars
      contentVars.forEach((contentVar) => {
        if (contentVar.attribute_id === attribute.id) {
          newContentVars.push(_.extend({}, contentVar, {
            value,
            is_deleted: isDeleted,
          }));
          updateExisting = true;
        }
      });

      // Add if there was no update to an existing contentVar AND we are not deleting a value
      if (!updateExisting && !isDeleted) {
        newContentVars.push({
          attribute_id: attribute.id,
          name: attribute.name,
          value,
          is_deleted: isDeleted,
        });
      }

      return newContentVars;
    },
  });

  const collection = new Collection();
  collection.CLASS = Collection; // needed otherwise not extendable, soooo brutal BUEHHEHEHE
  return collection;
});
