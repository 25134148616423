define([
  'underscore',
  'upx.modules/RelationsModule/collections/Location',
], (
  _, UpxCollection,
) => {
  const Collection = UpxCollection.extend({

    loadParameters: {
      start: 0,
      limit: 200,
    },

    getDefaultLocation() {
      return this.findWhere({
        is_default: true,
        is_active: true,
      });
    },

    getLocationName(ids) {
      const names = [];
      if (_.isArray(ids) && ids.length) {
        ids.forEach((id) => {
          const loc = this.get(id);
          if (loc) {
            names.push(loc.get('name'));
          }
        });
      }
      return names;
    },

    getDefaultLocationGln() {
      const def = this.getDefaultLocation();
      if (def) {
        return def.get('address.gln');
      }
      return null;
    },

    getById(id) {
      id = parseInt(id, 10);
      return this.findWhere({
        id,
      });
    },

    getSelect2Options({ allowNotActive = false } = {}) {
      const data = [];

      const processModel = (model) => {
        const id = model.get('id');
        data.push({
          id,
          text: model.get('name') || '',
        });
      };

      const collection = allowNotActive ? this : this.getActiveLocationsCollection();
      collection.each(processModel);

      return data;
    },

    getActiveLocationsCollection() {
      return new UpxCollection(this.getActiveLocations());
    },

    getActiveLocations() {
      return this.filter((model) => model.get('is_active'));
    },

  });

  return new Collection();
});
