define([
  'underscore',
  './../components/component',
],
(_, Component) => {
  const Nested = Component.extend({
    /**
     * Takes a nested object and returns a shallow object keyed with the path names
     * e.g. { "level1.level2": "value" }
     *
     * @param  {Object}      Nested object e.g. { level1: { level2: 'value' } }
     * @return {Object}      Shallow object with path names e.g. { 'level1.level2': 'value' }
     */
    objToPaths(obj) {
      const ret = {};
      const separator = '.';

      for (const key in obj) {
        const val = obj[key];

        if (val && val.constructor === Object) {
          if (!_.isEmpty(val)) {
            // Recursion for embedded objects
            const obj2 = this.objToPaths(val);

            for (const key2 in obj2) {
              ret[key + separator + key2] = obj2[key2];
            }
          }
        } else if (val !== undefined) {
          ret[key] = val;
        }
      }

      return ret;
    },

    /**
             * @param {Object} obj                Object to fetch attribute from
             * @param {String} path               Object path e.g. 'user.name'
             * @param {Object} [options]          Options
             * @param {Boolean} [options.unset]   Whether to delete the value
             * @param {Mixed}                     Value to set
             */
    setNested(obj, path, val, options) {
      options = options || {};

      const separator = '.';

      const fields = path.split(separator);
      let result = obj;
      for (let i = 0, n = fields.length; i < n && result !== undefined; i++) {
        const field = fields[i];

        // If the last in the path, set the value
        if (i === n - 1) {
          options.unset ? delete result[field] : result[field] = val;
        } else {
          // Create the child object if it doesn't exist, or isn't an object
          if (typeof result[field] === 'undefined' || !_.isObject(result[field])) {
            result[field] = {};
          }

          // Move onto the next part of the path
          result = result[field];
        }
      }
    },

    deleteNested(obj, path) {
      this.setNested(obj, path, null, { unset: true });
    },
  });

  return new Nested();
});
