define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/component',

  'modules/profile/models/profile',
  'modules/shop/components/feature',
], (
  App, $, _, Backbone, Component,
  ProfileModel, Feature,
) => {
  // backoffice oriented roles
  const ADMIN_ROLE = 'admin';
  const GENERAL_MANAGER_ROLE = 'manager';
  const FULL_USER_ROLE = 'user';
  const STORE_ADMIN_ROLE = 'store_admin';
  const WEBSHOP_MANAGER_ROLE = 'webshop_manager';
  const ORDER_ONLY_ROLE = 'order_only';

  // pos oriented roles
  const JUNIOR_CLERK_ROLE = 'junior_cashier';
  const SENIOR_CLERK_ROLE = 'senior_clerk';
  const STORE_MANAGER_ROLE = 'cashier';
  // deprecated
  const ORDER_REQUEST_MANAGER_ROLE = 'order_request_manager';

  const USER_LEVEL_ROLES = {
    ADMIN_ROLE,
    GENERAL_MANAGER_ROLE,
    FULL_USER_ROLE,
    STORE_ADMIN_ROLE,
    WEBSHOP_MANAGER_ROLE,
    ORDER_ONLY_ROLE,
    SENIOR_CLERK_ROLE,
    STORE_MANAGER_ROLE,
    JUNIOR_CLERK_ROLE,
    ORDER_REQUEST_MANAGER_ROLE,
  };

  // capabilities
  const FULL_LOGIN_CAPABILITY = 'cap:bo_login';
  const ORDER_CAPABILITY = 'cap:orders';
  const PRODUCTS_CAPABILITY = 'cap:products';
  const CUSTOMERS_CAPABILITY = 'cap:customers';
  const ADMIN_DASHBOARD_CAPABILITY = 'cap:admin_dashboard';
  const STORES_CAPABILITY = 'cap:stores';
  const WEBSHOPS_CAPABILITY = 'cap:webshops';
  const ALL_CHANNELS_CAPABILITY = 'cap:all_channels';
  const ALL_APPS_CAPABILITY = 'cap:all_apps';
  const APPS_CAPABILITY = 'cap:apps';
  const SETTINGS_CAPABILITY = 'cap:settings';
  const TECH_SETTINGS_CAPABILITY = 'cap:tech-settings';
  const COMPANY_INFO_CAPABILITY = 'cap:company-info-settings';
  const PAYMENT_SETTINGS_CAPABILITY = 'cap:payment-settings';
  const USERS_CAPABILITY = 'cap:users';

  const LOGIN_CAPABILITIES = [
    FULL_LOGIN_CAPABILITY,
  ];
  const ORDER_ONLY_CAPABILITIES = [
    ...LOGIN_CAPABILITIES,
    ORDER_CAPABILITY,
  ];
  const ORDER_REQUEST_MANAGER_CAPABILITIES = [
    ...ORDER_ONLY_CAPABILITIES,
    COMPANY_INFO_CAPABILITY,
    SETTINGS_CAPABILITY,
  ];

  const WEBSHOP_MANGER_CAPABILITIES = [
    ...ORDER_ONLY_CAPABILITIES,
    PRODUCTS_CAPABILITY,
    CUSTOMERS_CAPABILITY,
    WEBSHOPS_CAPABILITY,
    APPS_CAPABILITY,
    ADMIN_DASHBOARD_CAPABILITY,
  ];
  const STORE_ADMIN_CAPABILITIES = [
    ...ORDER_ONLY_CAPABILITIES,
    PRODUCTS_CAPABILITY,
    CUSTOMERS_CAPABILITY,
    STORES_CAPABILITY,
    APPS_CAPABILITY,
    ADMIN_DASHBOARD_CAPABILITY,
  ];

  const FULL_USER_CAPABILITIES = [
    ...WEBSHOP_MANGER_CAPABILITIES,
    ...STORE_ADMIN_CAPABILITIES,
    SETTINGS_CAPABILITY,
    ALL_APPS_CAPABILITY,
    ALL_CHANNELS_CAPABILITY,
  ];
  const GENERAL_MANAGER_CAPABILITIES = [
    ...FULL_USER_CAPABILITIES,
    COMPANY_INFO_CAPABILITY,
  ];
  const ADMIN_CAPABILITIES = [
    ...GENERAL_MANAGER_CAPABILITIES,
    TECH_SETTINGS_CAPABILITY,
    USERS_CAPABILITY,
    PAYMENT_SETTINGS_CAPABILITY,
  ];

  const ROLE_CAPABILITIES = {
    [ADMIN_ROLE]: ADMIN_CAPABILITIES,
    [GENERAL_MANAGER_ROLE]: GENERAL_MANAGER_CAPABILITIES,
    [FULL_USER_ROLE]: FULL_USER_CAPABILITIES,
    [STORE_ADMIN_ROLE]: STORE_ADMIN_CAPABILITIES,
    [WEBSHOP_MANAGER_ROLE]: WEBSHOP_MANGER_CAPABILITIES,
    [ORDER_ONLY_ROLE]: ORDER_ONLY_CAPABILITIES,
    [ORDER_REQUEST_MANAGER_ROLE]: ORDER_REQUEST_MANAGER_CAPABILITIES,
  };

  const ALL_CAPABILITIES = {
    FULL_LOGIN_CAPABILITY,
    ORDER_CAPABILITY,
    PRODUCTS_CAPABILITY,
    CUSTOMERS_CAPABILITY,
    ADMIN_DASHBOARD_CAPABILITY,
    STORES_CAPABILITY,
    WEBSHOPS_CAPABILITY,
    ALL_CHANNELS_CAPABILITY,
    ALL_APPS_CAPABILITY,
    APPS_CAPABILITY,
    SETTINGS_CAPABILITY,
    TECH_SETTINGS_CAPABILITY,
    COMPANY_INFO_CAPABILITY,
    PAYMENT_SETTINGS_CAPABILITY,
    USERS_CAPABILITY,
  };

  // Apps
  const STORE_ADMIN_FEATURES = [
    Feature.APP_NAME_CORE_SUPPORT_MODULE_SUPPORT_REQUEST, // conversations
    Feature.APP_CORE_PRODUCTS_MODULE_SALES_CHANNEL_TURNOVER_OVERVIEW, // turnover reports
  ];
  const WEBSHOP_MANGER_FEATURES = [
    Feature.APP_CORE_SHOP_MODULE_BRAND, // brands
    Feature.APP_NAME_CORE_SUPPORT_MODULE_SUPPORT_REQUEST, // conversations
    Feature.APP_CORE_SHOP_MODULE_SHIPMENT_PARCEL_OVERVIEW, // parcel overview
    Feature.APP_CORE_SHOP_MODULE_FEATURED_ATTRIBUTE_CATEGORIES, // colors and sizes
    Feature.APP_CORE_PRODUCTS_MODULE_SALES_CHANNEL_TURNOVER_OVERVIEW, // turnover reports
  ];
  const ROLE_FEATURES = {
    [STORE_ADMIN_ROLE]: STORE_ADMIN_FEATURES,
    [WEBSHOP_MANAGER_ROLE]: WEBSHOP_MANGER_FEATURES,
  };

  const CommerceAuth = Component.extend({

    USER_LEVEL_ROLES,
    ALL_CAPABILITIES,

    ...USER_LEVEL_ROLES,
    ...ALL_CAPABILITIES,

    hasRole(alias) {
      return this.getAllUserRoles().includes(alias);
    },

    hasFeature(feature) {
      return this.getAllUserRoles().includes(`feature:${feature}`);
    },

    getUserLevelRole() {
      const products = ProfileModel.getProducts();

      for (let i = 0; i < products.length; i++) {
        const product = products[i];
        if (product.product_type
            && product.product_type.module_name === 'CommerceModule'
            && product.product_type.alias === 'Commerce'
            && product.membership
            && product.membership.group_member_type
            && product.membership.group_member_type.alias
        ) {
          return product.membership.group_member_type.alias;
        }
      }
      return null;
    },

    getAllUserRoles() {
      const roles = [];
      const baseRole = this.getUserLevelRole();
      if (baseRole) {
        roles.push(baseRole);
        if (baseRole in ROLE_CAPABILITIES) {
          roles.push(...ROLE_CAPABILITIES[baseRole]);
        }
        if (baseRole in ROLE_FEATURES) {
          roles.push(
            ...ROLE_FEATURES[baseRole].map(
              (feature) => `feature:${feature}`,
            ),
          );
        }
      }

      return roles;
    },

    hasOrderAccess() {
      return this.hasRole(ORDER_CAPABILITY);
    },

    hasProductAccess() {
      return this.hasRole(PRODUCTS_CAPABILITY);
    },

    hasCustomerAccess() {
      return this.hasRole(CUSTOMERS_CAPABILITY);
    },

    getIndexRoute() {
      if (!this.hasRole(FULL_LOGIN_CAPABILITY)) {
        return 'pos-only';
      }
      if (this.hasRole(ADMIN_DASHBOARD_CAPABILITY)) {
        return 'dashboard';
      }
      if (this.hasOrderAccess()) {
        return 'orders';
      }
      if (this.hasCustomerAccess()) {
        return 'customers';
      }
      if (this.hasProductAccess()) {
        return 'products';
      }

      return 'profile';
    },

  });

  return new CommerceAuth();
});
