define(['hbs/handlebars', 'modules/shop/components/feature.js'], (Handlebars, Feature) => {
  function isMultiLocationEnabled(options) {
    const result = Feature.isMultiLocationFeatureEnabled();

    if (result) {
      return options.fn(this);
    }
    return options.inverse(this);
  }

  Handlebars.registerHelper('shop$isMultiLocationEnabled', isMultiLocationEnabled);

  return isMultiLocationEnabled;
});
