define([
        'modules/upx/models/model',
        './structs/SupplierEdiIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'SupplierEdiIntegration',
            checkConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkSupplierEdiIntegrationConnection',parameters, ajaxOptions);
            },
            getStats: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getSupplierEdiIntegrationStats',parameters, ajaxOptions);
            },
            checkDropshipmentProductStocks: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkSupplierEdiIntegrationDropshipmentProductStocks',parameters, ajaxOptions);
            },
            newDropshipmentOrder: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newSupplierEdiIntegrationDropshipmentOrder',parameters, ajaxOptions);
            },
            dailyAutoMarkingOfShipmentAsDelivered: function (parameters, ajaxOptions) {
                return this.callObjectMethod('dailyAutoMarkingOfShipmentAsDelivered',parameters, ajaxOptions);
            },
            sendSupplierReport: function (parameters, ajaxOptions) {
                return this.callObjectMethod('sendSupplierEdiIntegrationSupplierReport',parameters, ajaxOptions);
            }
        });
    }
);