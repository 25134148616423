define([
        'modules/upx/models/model',
        './structs/PayNlProvider'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',

            object: 'PayNlProvider',
            checkConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkPayNlProviderConnection',parameters, ajaxOptions);
            }
        });
    }
);