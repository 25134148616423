define([
  'jquery',
], (
  $,
) => ({

  isEmbeddedMobileApp() {
    return this.getMode() === 'APP';
  },

  getMode() {
    const variable = 'MODE';
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]).toUpperCase() == variable) {
        return decodeURIComponent(pair[1]).toUpperCase();
      }
    }
    return false;
  },
}));
