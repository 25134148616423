define((require) => {
  const Backbone = require('backbone');

  const pageHeader = Backbone.Model.extend({
    defaults: {
      id: 'active',
      title: '',
      subTitle: '',
      icon: '',
      showHeader: true,
    },
  });

  return new pageHeader();
});
