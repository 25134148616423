define([
  'jquery',
  'underscore',
  'modules/shop/models/App/WebShopWithSyncApp.js',

  'modules/common/components/locale',
  'modules/shop/components/apps',
  'modules/shop/components/feature',

  'modules/shop/collections/ShopType',
], (
  $, _, WebShopWithSyncApp,
  Locale, AppsComponent, Feature,
  ShopTypeCollection,
) => WebShopWithSyncApp.extend({
  defaults: _.extend(
    {},
    WebShopWithSyncApp.prototype.defaults,
    {
      id: AppsComponent.NEW_WEBSHOP_WITH_SYNC_MAGENTO2_APP_ID,
      icon: 'fa-brands fa-magento',
      title: Locale.translate('magento2_advanced'),
      summary: Locale.translate('allows_you_to_easily_integrate_your_magento2_online_shop_dot_you_can_take_advantage_of_magento_community_and_great_templating_support_while_having_storekeeper_for_processing_orders_from_all_your_channels_dot'),
      flavour: ShopTypeCollection.WEBSHOP_M2_MAGENTO2_FLAVOUR,
      link_open: 'apps/create-webshop-with-sync/magento2',
      app_name: Feature.APP_CORE_SHOP_MODULE_WEBSHOP_WITH_SYNC_MAGENTO2,
    },
  ),
}));
