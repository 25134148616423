define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',
  'modules/upx/components/upx',

  'upx.modules/PaymentModule/collections/GroupCardProvider',
  'upx.modules/PaymentModule/models/GroupCardProvider',
], (
  $, _, LinkModel,
  Locale, Feature, AppsComponent, Upx,
  GroupCardProviderCollection, GroupCardProviderModel,
) => LinkModel.extend({

  ALIAS_GROUPCARD: 'GroupCard',

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.GROUP_CARD_APP_ID,
      logo: '/images/apps/groupcard.png',
      icon: 'fal fa-gift-card',
      app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_GROUPCARD,
      title: Locale.translate('groupcard_open_parenthesis_cityid_close_parenthesis_gift_cards'),
      summary: Locale.translate('accept_payments_via_a_card_label_of_groupcard'),
      link_open: 'apps/group-card',
    },
  ),

  testConnection(provider_id) {
    const def = new $.Deferred();

    if (provider_id) {
      Upx.call('PaymentModule', 'testGroupCardProviderConnection',
        {
          provider_id,
        }).then(
        def.resolve,
        def.reject,
      );
    } else {
      def.resolve();
    }

    return def.promise();
  },

  ensureProvider() {
    const def = new $.Deferred();

    $.when(
      this.getProvider(),
    ).then((groupCardProvider) => {
      if (groupCardProvider) {
        def.resolve(groupCardProvider);
      } else {
        const model = new GroupCardProviderModel();
        model.save().then(
          (provider_id) => {
            model.set({ provider_id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  getProvider() {
    const def = new $.Deferred();
    const collection = new GroupCardProviderCollection();

    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'provider_id',
        dir: 'asc',
      }],
      filters: [{
        name: 'provider/deleted__=',
        val: 0,
      }],
    };

    collection.fetch({
      params,
    }).then(() => {
      def.resolve(collection.first());
    }, def.reject);

    return def.promise();
  },

  getUninstallContent() {
    return Locale.translate('are_you_sure_you_want_to_disconnect_your_groupcard_integration_question_mark');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getProvider(),
    ).then((model) => {
      $.when(
        !model || model.destroy(),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },
}));
