define([
  'application',
  'module',
  'modules/common/views/layout',
  'modules/admin/templates/admin/layouts/page',
  'modules/admin/views/admin/pageHeader',
  'modules/admin/regions/page',
],
(App, Module, Layout, Template, PageHeader, PageRegion) => Layout.extend({
  template: Template,

  name: 'modules/admin/views/admin/layouts/page',

  regionClass: PageRegion,

  regions: {
    header: {
      selector: '#page-header',
      viewClass: PageHeader,
    },
    content: '#page',
  },

  serializeData() {
    const data = {};
    if (
      App.settings.admin !== undefined
                    && App.settings.admin.breadcrumbs !== undefined
                    && App.settings.admin.breadcrumbs.show_back_button !== undefined
    ) {
      data.breadcrumbsBackButton = App.settings.admin.breadcrumbs.show_back_button;
    } else {
      data.breadcrumbsBackButton = false;
    }
    if (
      App.settings.admin !== undefined
                    && App.settings.admin.breadcrumbs !== undefined
                    && App.settings.admin.breadcrumbs.show_forward_button !== undefined
    ) {
      data.breadcrumbsForwardButton = App.settings.admin.breadcrumbs.show_forward_button;
    } else {
      data.breadcrumbsForwardButton = false;
    }
    return data;
  },
}));
