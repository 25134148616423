define([
        'modules/upx/collections/collection',
        '../models/MarketingIntegration',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ShopMarketingModule',
            object: 'MarketingIntegration',
            collection_method: 'listMarketingIntegrations',
            model: Model
        });
    }
);