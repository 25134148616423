define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    payments: 'list',
    'payments/setting/:additional': 'list',
  },

  acls: [
    new LoggedIn(),

    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/payments'], callback);
  },
}));
