define([
  'underscore',
  'backbone',
  'modules/common/acls/acl',
  'modules/shop.common/components/commerceAuth',
],
(_, Backbone, Acl, CommerceAuth) => Acl.extend({

  initialize(options) {
    // Set prototypes
    Acl.prototype.initialize.call(this, options);
    this.FEATURE_NAME = options.FEATURE_NAME || false;
  },
  authorized() {
    if (CommerceAuth.hasRole(CommerceAuth.ALL_APPS_CAPABILITY)) {
      return true;
    }
    if (this.FEATURE_NAME) {
      return CommerceAuth.hasFeature(this.FEATURE_NAME);
    }
    return false;
  },

  error() {
    Backbone.history.navigate('error/forbidden', { trigger: true });
  },

}));
