define(['underscore', 'hbs/handlebars', '../../components/locale'], (_, Handlebars, Locale) => {
  function escape(v, key, input) {
    let val = null;
    if (_.isObject(v)) {
      console.debug('No multilevel structure is supported for translation. Key: ', key,
        ' Got:', input);
    } else {
      val = _.escape(v);
    }
    return val;
  }

  function escapeObject(input, key) {
    const output = {};
    _.each(input, (v, k) => {
      output[k] = escape(v, key, input);
    });
    return output;
  }

  function translate(key) {
    if (key) {
      let params;
      if (arguments.length > 2) {
        if (arguments.length === 3 && _.isObject(arguments[1])) {
          // named argument for translations
          params = escapeObject(arguments[1], key);
        } else {
          // get arguments without first which is the translations string
          // and last which is the handlebars.options
          params = [];
          for (let i = 1; i < arguments.length - 1; i++) {
            const escaped = escape(arguments[i], key, arguments[i]);
            params.push(escaped);
          }
        }
      }
      return new Handlebars.SafeString(
        Locale.translate(key, params),
      );
    }
    return '';
  }

  Handlebars.registerHelper('common$translate', translate);
  return translate;
});
