define([
  'jquery',
  'upx.modules/FileModule/collections/Adapter',
],
($, AdapterCollection) => {
  const StaticModel = (AdapterCollection.prototype.model).extend({

    loadDeferred: false,

    isLoaded() {
      return this.loadDeferred && this.loadDeferred.state() === 'resolved';
    },
    isLoading() {
      return this.loadDeferred && this.loadDeferred.state() === 'pending';
    },
    load({ reload = false } = {}) {
      const def = new $.Deferred();

      if (reload) {
        this.loadDeferred = null;
      }

      if (
        !this.loadDeferred
              || this.loadDeferred.state() === 'rejected'
              || this.loadDeferred.state() === 'resolved'
      ) {
        const collection = new AdapterCollection();
        const params = {
          start: 0,
          limit: 1,
          sort: [],
          filters: [
            {
              name: 'is_default__=',
              val: true,
            },
          ],
        };

        collection.fetch({ params })
          .then(() => {
            this.clear({ silent: true });
            const model = collection.first();
            if (model) {
              this.set(model.toJSON());
            }
            def.resolve();
          }, def.reject);
        this.loadDeferred = def.promise();
      }
      return this.loadDeferred;
    },

    unload() {
      this.clear();
      this.loadDeferred = null;
    },
  });

  return new StaticModel();
});
