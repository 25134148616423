define([
  'underscore',
  'backbone',
  'money-math',

  'modules/common/components/configuration',
], (
  _, Backbone, MoneyMath,
  Configuration,
) => {
  const currency = Backbone.Marionette.Controller.extend({

    Math: MoneyMath,

    /**
     * Retrieve the decimal separator of given iso3 or Default Shop Iso3
     * @param iso3 three-letter country codes
     * @param part [integer, group, decimal, fraction, literal, currency] NumberFormat part
     */
    isoToParts(iso3, part) {
      try {
        if (!iso3) iso3 = this.getCurrencyIso3();

        const locale = this.getCurrencyLocale();
        const amount = 1234.01;
        const options = { style: 'currency', currency: iso3 };
        const numberFormat = new Intl.NumberFormat(locale, options);
        const parts = numberFormat.formatToParts(amount);

        // If part is null return array of parts
        if (!part) {
          throw Error('Part needs to be defined');
        }

        for (let i = 0; i < parts.length; i++) {
          const { type, value } = parts[i];
          if (type === part) {
            return value;
          }
        }
        console.warn(`Currency part ${part} for ${iso3} not found`);
        return null;
      } catch (error) {
        if (error instanceof RangeError) {
          // Handle the RangeError from Intl.NumberFormat
          console.warn('RangeError occurred:', error.message);
          return null;
        }
        // Handle other types of errors
        console.warn('An error occurred:', error);
        return null;
      }
    },

    getCurrencyIso3() {
      return Configuration.getCurrencyIso3();
    },

    stripCurrencySymbol(text, symbol) {
      symbol = symbol || this.isoToSymbol();
      text = `${text}`;
      text = text.trim();
      if (text.startsWith('-')) {
        const minSymbol = `-${symbol}`;
        if (text.startsWith(minSymbol)) {
          text = `-${text.slice(minSymbol.length).trim()}`;
        }
      }
      if (text.startsWith(symbol)) {
        text = text.slice(symbol.length).trim();
      } else if (text.endsWith(symbol)) {
        text = text.slice(0, -symbol.length).trim();
      }

      return text;
    },

    isoToSymbol(iso3) {
      if (!iso3) iso3 = this.getCurrencyIso3();
      const part = 'currency';
      return this.isoToParts(iso3, part) || iso3;
    },

    isoToDecimalPart(iso3) {
      const part = 'decimal';
      return this.isoToParts(iso3, part) || ',';
    },

    /**
     *
     * @param value
     * @return number
     */
    currencyToFloat(value) {
      if (
        typeof value === 'string'
      ) {
        if (value.match(/^\-?\d+\.\d{4}$/)) {
          // proper money value
          return parseFloat(value);
        }

        const separator = this.isoToDecimalPart(Configuration.getCurrencyIso3());
        value = value.replace(/,+/g, ',');
        value = value.replace(/\.+/g, '.');

        if (separator === ',') {
          value = value.replace(/\,/g, '###');
          value = value.replace(/\.([\d]{3})/g, '$1');
          value = value.replace(/\#\#\#/g, '.');
        } else {
          value = value.replace(/\./g, '###');
          value = value.replace(/\.([\d]{3})/g, '$1');
          value = value.replace(/\#\#\#/g, '.');

          // special case if there is a comma on last 2 places
          value = value.replace(/,(\d\d)$/, '.$1');
        }

        // Removing non-currency characters;
        // only number, dots and negative sign are allowed;
        value = value.replace(/[^\d.-]/g, '');
        value = value.replace(/\.+/g, '.');

        // If someone tries to format words. it will reset to 0
        if (value === '') {
          value = 0;
        }
        return parseFloat(value) || 0;
      } if (typeof value === 'number') {
        return value;
      }

      return parseFloat(value);
    },

    toCurrency(value) {
      if (value === null || value === undefined || Number.isNaN(value)) {
        console.warn(`toCurrency received ${value} as value`);
        return '0.00';// zero value if the input is invalid
      }
      return this.Math.floatToAmount(this.currencyToFloat(value));
    },

    getCurrencyLocale() {
      return Configuration.getCurrencyLocale();
    },

    formatWithoutSymbol(iso3, amount) {
      let formatted = this.format(iso3, amount);

      // Removing non-currency characters;
      // only number, dots,comma and negative sign are allowed;
      formatted = formatted.replace(/[^\d.,-]/g, '');
      return formatted;
    },

    format(iso3, amount) {
      if (!iso3) iso3 = this.getCurrencyIso3();

      if (typeof amount !== 'number') {
        amount = this.currencyToFloat(amount);
      }

      const locale = this.getCurrencyLocale();
      let formatted = amount.toLocaleString(
        locale,
        { style: 'currency', currency: iso3 },
      );

      formatted = formatted.replace(' ', '\u00A0');

      return formatted;
    },
  });

  return new currency();
});
