define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/PaymentModule/collections/SumupProvider',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  SumupProviderCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.SUM_UP_APP_ID,
      logo: '/images/apps/sumup.png',
      icon: 'fab fa-money-bill-wave',
      summary: Locale.translate('use_sumup_as_your_terminal_payments'),
      link_open: 'apps/sumup',
      title: Locale.translate('sumup'),
      app_name: Feature.APP_NAME_CORE_PAYMENTS_MODULE_SUM_UP,
    },
  ),

  getUninstallContent() {
    return Locale.translate('are_you_sure_you_want_to_disconnect_this_sumup_account_you_won_t_be_able_to_use_those_terminals_anymore');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getProvider(),
    ).then((provider) => {
      $.when(
        !provider || Upx.call('PaymentModule', 'deleteSumupProvider',
          {
            id: provider.get('provider_id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  getProvider() {
    const def = new $.Deferred();

    const providerCollection = new SumupProviderCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'provider_id',
        dir: 'asc',
      }],
      filters: [{
        name: 'provider/deleted__=',
        val: false,
      }],
    };

    providerCollection
      .fetch({ params })
      .then(
        () => {
          def.resolve(providerCollection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
