define([
  'jquery',
  'underscore',
  'hbs/handlebars',
  'markdown-it',

  'magnific-popup',
], (
  $, _, Handlebars, MarkdownIt,
) => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  function getUuid() {
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  const backrefRegex = /backref:\/\/(\S+)::(\S+)\((\S+)\)/;

  function getBackrefTypeAndOptions(backref) {
    // Get the items from the regex execution
    const [
      __,
      module,
      object,
      data,
    ] = backrefRegex.exec(backref);

    const type = `${module}::${object}`;

    // Get the options
    const options = {};

    // Loop over all options in the data
    const optionArray = data.split(',');
    optionArray.forEach((option) => {
      // Split each option and check if the length is 2
      const optionSplit = option.split('=');
      if (optionSplit.length === 2) {
        // Get the key and ID
        const [
          key,
          id,
        ] = optionSplit;

        // Add the option to the options object
        options[key] = id;
      } else {
        throw new Error(`Wrong options "${option}"`);
      }
    });

    // Return type and options
    return { type, options };
  }

  function processATag(aEL) {
    const $aEl = $(aEL);
    const backref = $aEl.attr('href');

    // Check if the href is a backref;
    if (backrefRegex.test(backref)) {
      // Get type with options
      const { type, options } = getBackrefTypeAndOptions(backref);

      // Check what to do with the different types
      switch (type) {
        case 'ShopModule::ShopProduct':
          if ('id' in options) {
            $aEl.attr('href', `#shop-products/details/${options.id}`);
          } else {
            throw new Error('Missing option "id" of ShopModule::ShopProduct');
          }
      }

      // Make links open in the same page
      $aEl.attr('target', '_parent');
    } else {
      // make links open in external tab
      $aEl.attr('target', '_blank');
    }
  }

  function render(markdown, maxHeight, imagesMaxWidth, imageOpenOnClick) {
    markdown = markdown || '';
    // When the 2nd parameter is not set, this is some object from HandleBars
    if (_.isObject(maxHeight)) {
      maxHeight = false;
    }

    if (_.isObject(imagesMaxWidth)) {
      imagesMaxWidth = false;
    }

    if (_.isObject(imageOpenOnClick)) {
      imageOpenOnClick = false;
    }

    const markdownIt = new MarkdownIt();
    const uuid = getUuid();
    const html = markdownIt.render(markdown);

    // as we can not set the (iframe) body with html we set it afterwards (after the return) with javascript.
    setTimeout(() => {
      // getting the iframe on the page;
      const $iframe = $(`iframe#${uuid}`);
      const $content = $iframe.contents();
      const $body = $content.find('body');
      const $head = $content.find('head');
      // Creating container div, so we can get the height later.
      const $div = $('<div>');
      $div.html(html);
      $div.find('p').css('white-space', 'pre-line');

      // Process all the a tags
      $div.find('a').each((i, $aEl) => { processATag($aEl); });

      // To make sure wide images are not expanding outside the iframe
      const $images = $div.find('img');

      if (imagesMaxWidth) {
        $images.css('height', 'auto');
        $images.css('max-width', imagesMaxWidth);
      } else {
        $images.css('width', '100%');
      }

      if (imageOpenOnClick) {
        $images.css('cursor', 'pointer');
        $images.on('click', (e) => {
          const element = $(e.target);
          $.magnificPopup.open({
            items: [{
              type: 'image',
              src: $(element).attr('src'),
              title: $(element).attr('title') || '',
              closeOnContentClick: true,
            }],
            type: 'image',
          }, 0);
        });
      }

      const $divChildren = $div.children();
      $divChildren.first().css('margin-top', '0');
      $divChildren.last().css('margin-bottom', '0');

      $body.html($div);
      $body.css('margin', 0); // Default it adds some margin to the body.

      const $style = $('<style>')
        .text(
          `body {
                font-family: Inter, sans-serif;
                font-size: 13px;
                color: #323d40;
            }`,
        );

      $head.html($style);

      if (maxHeight) {
        $iframe.css('max-height', maxHeight); // So it doesn't become huge
      }

      // Resizing the height
      const resizeFn = function () {
        let divHeight = $div.outerHeight();
        $iframe.css('height', `${divHeight}px`);

        /**
                 * For some reason, applying the height twice results in the correct divHeight.
                 * The first time it does not has the correct height.
                 * But the second time it does has the correct height.
                 */
        divHeight = $div.outerHeight();
        $iframe.css('height', `${divHeight}px`);
      };

      // Prevent jumping.
      resizeFn();

      // Resizes as images load in.
      $images.on('load', () => resizeFn());

      // Giving the view some time to render and then re-size it.
      setTimeout(resizeFn, 10);
      setTimeout(resizeFn, 500); // 500 is needed for modals. An iframe does not render in a modal that has a transition effect.
    }, 0);

    return new Handlebars.SafeString(
      // return iframe with unique uuid, full width so it looks nicer by default.
      `<iframe style="width: 100%" id="${uuid}"></iframe>`,
    );
  }

  Handlebars.registerHelper('markdown$render', render);
  return render;
});
