define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/Shop',
  'modules/shop/collections/ShopType',

  'modules/common/components/locale',
], (
  $, _, Backbone, ShopModel, ShopTypeCollection,
  Locale,
) => ShopModel.extend({

  isType(wantedModuleName, wantedAlias) {
    return (
      this.isTypeModuleName(wantedModuleName)
                && this.isTypeAlias(wantedAlias)
    );
  },

  isTypeModuleName(wantedModuleName) {
    return this.get('shop_type.module_name') === wantedModuleName;
  },

  isTypeAlias(wantedAlias) {
    return this.get('shop_type.alias') === wantedAlias;
  },

  isMarketplace() {
    return this.isTypeAlias(ShopTypeCollection.ALIAS_BOL_FLUSE);
  },
  canEdit() {
    const editableAliases = [
      ShopTypeCollection.ALIAS_POINT_OF_SALE,
      ShopTypeCollection.ALIAS_WEB,
      ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
    ];
    return editableAliases.indexOf(this.get('shop_type.alias')) !== -1;
  },

  getPluginUrl() {
    const flavour = this.get('flavour');

    if (flavour === ShopTypeCollection.WEBSHOP_WP_WOOCOMMERCE_FLAVOUR) {
      return 'https://wordpress.org/plugins/storekeeper-for-woocommerce/';
    } if (flavour === ShopTypeCollection.WEBSHOP_M2_MAGENTO2_FLAVOUR) {
      return 'https://github.com/storekeeper-company/magento2-plugin';
    }
    if (flavour === ShopTypeCollection.WEBSHOP_WWS_SHOPTRADER_FLAVOUR) {
      return 'https://www.shoptrader.nl/';
    }

    console.warn('Unknown flavour: ', flavour);
    return null;
  },

  getFlavourText() {
    const flavour = this.get('flavour');

    if (!flavour) {
      return Locale.translate('shop_type_not_set_yet');
    }

    if (flavour === ShopTypeCollection.WEBSHOP_WP_WOOCOMMERCE_FLAVOUR) {
      return Locale.translate('for_connecting_to_your_webshop_comma_install_our_plugin_on_your_woocommerce_shop');
    } if (flavour === ShopTypeCollection.WEBSHOP_M2_MAGENTO2_FLAVOUR) {
      return Locale.translate('for_connecting_to_your_webshop_comma_install_our_plugin_on_your_magento_2_shop');
    } if (flavour === ShopTypeCollection.WEBSHOP_OTHER_FLAVOUR) {
      return Locale.translate('shop_type_unknown');
    }

    console.warn('Unknown flavour: ', flavour);
    return '';
  },

  canDelete() {
    const deletableAliases = [
      ShopTypeCollection.ALIAS_POINT_OF_SALE,
      ShopTypeCollection.ALIAS_WEB,
      ShopTypeCollection.ALIAS_WEB_WITH_SYNC,
    ];
    return deletableAliases.indexOf(this.get('shop_type.alias')) !== -1;
  },

}));
