define([
        'modules/upx/models/model',
        './structs/SnelStartIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'BillingIntegrationModule',
            idAttribute: 'id',
            object: 'SnelStartIntegration',
            checkConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('checkSnelStartIntegrationConnection',parameters, ajaxOptions);
            },
            getOauthRedirectUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getSnelStartIntegrationOauthRedirectUrl',parameters, ajaxOptions);
            }
        });
    }
);