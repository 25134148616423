define([
  'underscore',
  'backbone',
  'modules/common/acls/acl',
  'modules/shop.common/components/commerceAuth',
],
(_, Backbone, Acl, CommerceAuth) => Acl.extend({

  authorized() {
    return CommerceAuth.hasOrderAccess();
  },

  error() {
    Backbone.history.navigate('error/forbidden', { trigger: true });
  },

}));
