define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
  'modules/shop/acls/appAuth',
  'modules/shop/components/feature',
], (AclRouter, LoggedIn, CapabilityAuthAcl, AppAuthAcl, Feature) => AclRouter.extend({

  appRoutes: {
    'fa/brands': 'overviewBrands',
    'fa/seasons': 'overviewSeasons',
    'fa/fashion-collections': 'overviewFashionCollections',
    'fa/style-id': 'overviewStyleId',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  overwriteAcls: [
    {
      routes: [
        'fa/brands',
      ],
      acls: [
        new LoggedIn(),
        new AppAuthAcl({
          FEATURE_NAME: Feature.APP_CORE_SHOP_MODULE_BRAND,
        }),
      ],
    },
  ],

  getController(callback) {
    return require(['modules/shop/controllers/featuredAttributes'], callback);
  },

}));
