define([
  'jquery',
  'underscore',
  'modules/shop/models/App/SupplierEdiIntegrationApp.js',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
], (
  $, _, SupplierEdiIntegrationAppModel, LinkModel,
  Locale, Feature, Upx, AppsComponent,
) => SupplierEdiIntegrationAppModel.extend({

  defaults: _.extend(
    {},
    SupplierEdiIntegrationAppModel.prototype.defaults,
    {
      id: AppsComponent.FASHION_UNITED_INTEGRATION_APP_ID,
      icon: 'fal fa-clothes-hanger',
      logo: '/images/apps/fashion-united.png',
      title: Locale.translate('fashionunited_ebusiness'),
      summary: Locale.translate('set_up_the_link_with_fashion_united_ebusiness_and_discover_edi_for_fashion'),
      link_open: 'fashion-united',
      app_name: Feature.APP_NAME_CORE_SHOP_MODULE_FASHION_UNITED_INTEGRATION,
      type_module_alias: 'FashionUnited',
    },
  ),

  install() {
    const def = new $.Deferred();
    this.getIntegration()
      .then(
        (integration) => {
          $.when(integration)
            .then(
              () => {
                $.when(
                  LinkModel.prototype.install.call(this),
                ).then(
                  def.resolve,
                  def.reject,
                );
              },
              def.reject,
            );
        },
        def.reject,
      );
    return def.promise();
  },

  newIntegration() {
    return Upx.call('ShopModule', 'newFashionUnitedIntegration');
  },
}));
