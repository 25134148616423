define([
        'modules/upx/models/model',
        './structs/MailchimpIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopMarketingModule',
            idAttribute: 'id',
            object: 'MailchimpIntegration',
            disconnect: function (parameters, ajaxOptions) {
                return this.callObjectMethod('disconnectMailchimpIntegration',parameters, ajaxOptions);
            },
            getAuthorizationUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getMailchimpIntegrationAuthorizationUrl',parameters, ajaxOptions);
            },
            testConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('testMailchimpIntegrationConnection',parameters, ajaxOptions);
            },
            listListFromApi: function (parameters, ajaxOptions) {
                return this.callObjectMethod('listMailchimpIntegrationListFromApi',parameters, ajaxOptions);
            },
            syncMailchimpIntegration: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncMailchimpIntegration',parameters, ajaxOptions);
            },
            syncCustomer: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncMailchimpIntegrationCustomer',parameters, ajaxOptions);
            },
            syncMultipleCustomers: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncMultipleMailchimpIntegrationCustomers',parameters, ajaxOptions);
            },
            getStats: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getMailchimpIntegrationStats',parameters, ajaxOptions);
            },
            getCustomerUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getMailchimpIntegrationCustomerUrl',parameters, ajaxOptions);
            }
        });
    }
);