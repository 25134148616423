define([
  'jquery',
  'underscore',
  'modules/shop/models/App/SupplierEdiIntegrationApp.js',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
], (
  $, _, SupplierEdiIntegrationAppModel, LinkModel,
  Locale, Feature, Upx, AppsComponent,
) => SupplierEdiIntegrationAppModel.extend({

  defaults: _.extend(
    {},
    SupplierEdiIntegrationAppModel.prototype.defaults,
    {
      id: AppsComponent.ORDER_WRITER_INTEGRATION_APP_ID,
      icon: 'fal fa-clothes-hanger',
      logo: '/images/apps/order-writer.png',
      title: Locale.translate('fashioncloud_slash_orderwriter'),
      summary: Locale.translate('edi_connection_which_allows_to_process_supplier_orders_dot'),
      link_open: 'order-writer',
      app_name: Feature.APP_NAME_CORE_SHOP_MODULE_ORDER_WRITER_INTEGRATION,
      type_module_alias: 'OrderWriter',
    },
  ),

  install() {
    const def = new $.Deferred();
    this.getIntegration()
      .then(
        (integration) => {
          $.when(integration)
            .then(
              () => {
                $.when(
                  LinkModel.prototype.install.call(this),
                ).then(
                  def.resolve,
                  def.reject,
                );
              },
              def.reject,
            );
        },
        def.reject,
      );
    return def.promise();
  },

  newIntegration() {
    return Upx.call('ShopModule', 'newOrderWriterIntegration');
  },
}));
