define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    invoices: 'list',
    'invoices/settings/:setting': 'list',
    'invoices/create': 'create',
    'invoices/details/:id': 'details',
    'invoices-intracommunity/:from/:till(/:relation_data_id)': 'listForIntraCommunity',
    'invoices-outside-europe/:from/:till(/:relation_data_id)': 'listForOutsideEurope',
    'invoices/filtered/:cacheId': 'filteredOverview',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/invoices'], callback);
  },
}));
