define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/ShopModule/collections/SupplierEdiIntegration',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  IntegrationCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      type_module_name: 'ShopModule',
    },
  ),

  configureModel() {
    if (!this.get('type_module_name')) {
      throw new Error('type_module_name is not set');
    }
    if (!this.get('type_module_alias')) {
      throw new Error('type_module_alias is not set');
    }
    if (!this.has('link_open')) {
      this.set('link_open', `supplier-edi-integration/${this.get('id')}`);
    }
    LinkModel.prototype.configureModel.call(this);
  },

  getUninstallContent() {
    return Locale.translate('disconnecting_the_integration_will_stop_all_communication_with_the_supplier_edi_connection_no_future_updates_will_be_processed_it_will_remove_all_already_synchronized_supplier_products_in_background_it_will_not_removecancel_already_created_orders_those_need_to_be_manually');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getIntegration(),
    ).then((model) => {
      $.when(
        !model || Upx.call('ShopModule', 'deleteSupplierEdiIntegration',
          {
            id: model.get('id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  getIntegration() {
    const def = new $.Deferred();

    const collection = new IntegrationCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [
        {
          name: 'id',
          dir: 'asc',
        },
      ],
      filters: [
        {
          name: 'date_deleted__null',
          val: '1',
        },
        {
          name: 'supplier_edi_integration_type/module_name__=',
          val: this.get('type_module_name'),
        },
        {
          name: 'supplier_edi_integration_type/alias__=',
          val: this.get('type_module_alias'),
        },
      ],
    };

    collection
      .fetch({ params })
      .then(
        () => {
          def.resolve(collection.first());
        },
        def.reject,
      );

    return def.promise();
  },

  install() {
    const def = new $.Deferred();
    this.getIntegration()
      .then(
        (integration) => {
          $.when(integration || this.newIntegration())
            .then(
              () => {
                $.when(
                  LinkModel.prototype.install.call(this),
                ).then(
                  def.resolve,
                  def.reject,
                );
              },
              def.reject,
            );
        },
        def.reject,
      );
    return def.promise();
  },

  newIntegration() {
    throw new Error('Not implemented');
  },
}));
