define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop/templates/apps/overview/popup.hbs',
  'modules/common/components/historyBreadcrumb',

  'modules/common/components/locale',

  'toastr',
  'modules/admin/behaviors/loader',
  'modules/shop/components/popups',

  'modules/shop/collections/FavouriteApplication',

], (
  $, _, Backbone, Template, HistoryBreadcrumb,
  Locale,
  Toastr, Loader, Popups,
  FavouriteApplication,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'apps-popup',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click [data-action="favourite"]': 'favouriteClicked',
    'click [data-action="open"]': 'openClicked',
    'click [data-action="install"]': 'installClicked',
    'click [data-action="uninstall"]': 'uninstallClicked',
    'click [data-action="extra"]': 'extraClicked',
  },

  modelEvents: {
    'change:is_installed': 'render',
    'change:is_upgradable': 'render',
    'change:is_favourite': 'render',
  },

  initialize({ extraButtons, link }) {
    this.extraButtons = extraButtons;
    this.link = link || this.model.getQuickActionLink();
  },
  extraClicked() {
    this.trigger('modal:close');
  },
  favouriteClicked() {
    const def = this.loader.startLoader();
    this.model.toggleFavourite().always(def.resolve);
  },

  openClicked() {
    this.trigger('modal:close');

    this.trigger('action:open', { link: this.link });
    HistoryBreadcrumb.goto(this.link);
  },

  installClicked() {
    const def = this.loader.startLoader();
    this.model.install()
      .then(() => {
        this.openClicked();
      })
      .always(def.resolve);
  },

  uninstallClicked() {
    const def = this.loader.startLoader();
    this.model.uninstallPopup().then(
      () => {
        this.trigger('modal:close');
        def.resolve();
      },
      def.reject,
    );
  },

  serializeData() {
    const data = this.model.toJSON();
    const isFavourite = FavouriteApplication.isFavourite(this.model.get('id'));
    if (this.model.get('is_upgradable') || this.model.get('is_not_in_subscription')) {
      data.salesMailUrl = this.model.getSalesMailUrl();
      data.salesEmail = this.model.getSalesEmail();
    }

    return {
      extraButtons: this.extraButtons,
      ...data,
      isFavourite,
      hasFavouriteButton: this.model.hasFavouriteButton(),
    };
  },

}));
