var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":34}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":50}}}) : helper)))
    + "\" class=\"apps-popup__image\">\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"icon") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":10,"column":23}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":38}}}) : helper)))
    + " apps-popup__icon\"></i>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"limit_reached_text") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"contact_sales_using_the_this_email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":69}}}))
    + "\r\n            <a target=\"_blank\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"salesMailUrl") || (depth0 != null ? lookupProperty(depth0,"salesMailUrl") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"salesMailUrl","hash":{},"data":data,"loc":{"start":{"line":25,"column":37},"end":{"line":25,"column":53}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"salesEmail") || (depth0 != null ? lookupProperty(depth0,"salesEmail") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"salesEmail","hash":{},"data":data,"loc":{"start":{"line":25,"column":55},"end":{"line":25,"column":69}}}) : helper)))
    + "</a>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"alert alert-warning\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"limit_reached_text") || (depth0 != null ? lookupProperty(depth0,"limit_reached_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"limit_reached_text","hash":{},"data":data,"loc":{"start":{"line":20,"column":49},"end":{"line":20,"column":71}}}) : helper)))
    + "</div>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"alert alert-info\">"
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"this_app_is_not_available_in_your_package",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":46},"end":{"line":22,"column":110}}}))
    + "</div>\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-default btn-block\" data-action=\"open\">\r\n                "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"open",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":43}}}))
    + "\r\n            </button>\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_installable") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"btn btn-default btn-block\" data-action=\"install\">"
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"install_and_open",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":80},"end":{"line":34,"column":119}}}))
    + "</button>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"mt-2 btn btn-default btn-block\" data-action=\"favourite\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFavourite") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":44,"column":23}}})) != null ? stack1 : "")
    + "            </button>\r\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"unlink",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":20},"end":{"line":41,"column":49}}}))
    + "\r\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"link",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":47}}}))
    + "\r\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"mt-2 btn btn-block "
    + alias4(((helper = (helper = lookupProperty(helpers,"btnCls") || (depth0 != null ? lookupProperty(depth0,"btnCls") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnCls","hash":{},"data":data,"loc":{"start":{"line":49,"column":41},"end":{"line":49,"column":51}}}) : helper)))
    + "\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"link") || (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"link","hash":{},"data":data,"loc":{"start":{"line":49,"column":59},"end":{"line":49,"column":67}}}) : helper)))
    + "\" data-action=\"extra\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconCls") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "                "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":53,"column":25}}}) : helper)))
    + "\r\n            </a>\r\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"iconCls") || (depth0 != null ? lookupProperty(depth0,"iconCls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iconCls","hash":{},"data":data,"loc":{"start":{"line":51,"column":30},"end":{"line":51,"column":41}}}) : helper)))
    + "\"></i>\r\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "            <hr/>\r\n            <button class=\"btn btn-danger btn-block\" data-action=\"uninstall\">"
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"uninstall",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":77},"end":{"line":59,"column":109}}}))
    + "</button>\r\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\r\n    <div class=\"col-md-12 apps-popup__description\">\r\n        "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/markdown/templates/helpers/render.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"markdown$render","hash":{},"data":data,"loc":{"start":{"line":68,"column":8},"end":{"line":68,"column":39}}}))
    + "\r\n    </div>\r\n</div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\"row\">\r\n    <div class=\"col-md-8\">\r\n        <div class=\"apps-popup__logo\" >\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"logo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":11,"column":19}}})) != null ? stack1 : "")
    + "        </div>\r\n        <p class=\"apps-popup__summary\">\r\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"summary") || (depth0 != null ? lookupProperty(depth0,"summary") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"summary","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":23}}}) : helper)))
    + "\r\n        </p>\r\n    </div>\r\n    <div class=\"col-md-4\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"salesEmail") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_installed") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasFavouriteButton") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"extraButtons") : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":55,"column":17}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_uninstallable") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + "    </div>\r\n</div>\r\n\r\n\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":0},"end":{"line":71,"column":7}}})) != null ? stack1 : "");
},"useData":true});