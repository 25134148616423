define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'product-serials': 'list',
    'product-serials/create': 'create',
    'product-serials/create/:productId/:name': 'create',
    'product-serials/details/:id': 'view',
    'product-serials/details/:id/:swapTo': 'view',
    'product-serials/by-product/:id': 'listByProduct',
    'product-serials/details-by-number/:productId/:number': 'viewByNumber',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/productSerials'], callback);
  },
}));
