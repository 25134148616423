define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/collections/upx/Shops',
  'modules/shop/collections/ShopType',

  'modules/shop/components/popups',
  'toastr',
], (
  $, _, LinkModel,
  Locale, ShopsCollection, ShopTypeCollection,
  Popups, Toastr,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      icon: 'fal fa-plus',
      title: Locale.translate('pos_for_generic_webshop'),
      summary: Locale.translate('load_all_products_from_the_webshop_and_synchronize_their_stock_real_time_when_product_is_sold_on_pos'),
    },
  ),

  initialize() {
    const type = this.get('shop_type');
    if (!type) {
      throw new Error('shop_type needs to be set for SkAsShopApp model');
    }
    LinkModel.prototype.initialize.call(this);
  },

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  configureModel() {
    const type = this.get('shop_type');
    if (this.get('shop_type_name')) {
      this.set('title', Locale.translate('pos_for_{shop_name}', {
        shop_name: this.get('shop_type_name'),
      }));
    }

    if (type) {
      this.set('id', `new-sk-as-pos-${type}`);
    }
    this.set('link_open', `apps/create-sk-as-pos/${type}`);
    this.store();
    LinkModel.prototype.configureModel.call(this);
    if (this.get('shop_type') === 'generic') {
      this.set('is_visible', false); // we use that app only for counting
    }
  },

  getShop() {
    const def = new $.Deferred();

    $.when(
      ShopsCollection.reload(),
      ShopTypeCollection.load(),
    ).then(
      () => {
        const shop = ShopsCollection.findWhere({
          flavour: ShopTypeCollection.getFlavourFromShopType(this.get('shop_type')),
          deleted: false,
          visible: true,
          shop_type_id: ShopTypeCollection.getIdByAlias(ShopTypeCollection.ALIAS_SK_AS_POS),
        });
        def.resolve(shop);
      },
      def.reject,
    );
    return def.promise();
  },

  uninstall(notify = true) {
    const def = new $.Deferred();
    const self = this;
    this.getShop().then(
      (shop) => {
        if (shop) {
          shop.set({ notify });
        }

        $.when(
          !shop || shop.destroy(),
        )
          .then(
            () => LinkModel.prototype.uninstall.call(this).then(def.resolve, def.reject),
            (resp) => {
              if (resp.class && resp.class === 'HookModule::HookCallFailed' && notify) {
                return Popups.confirm(Locale.translate('failed_to_send_a_deletion_call_to_the_hook_url_comma_proceed_without_notifying_question_mark'))
                  .then(() => {
                    self.uninstall(false);
                  }, def.reject);
              }

              Toastr.error(resp.error);
              def.reject();
            },
          );
      }, def.reject,
    );
    return def.promise();
  },

}));
