define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/apps',
  'modules/shop/components/feature',
], (
  $, _, LinkModel,
  Locale, AppsComponent, Feature,
) => LinkModel.extend({
  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      icon: 'fal fa-plus',
      summary: Locale.translate('create_new_channel_and_use_it_with_hardware_or_web_pos'),
      app_name: Feature.APP_CORE_SHOP_MODULE_POS_STORE_COUNT,
    },
  ),

  initialize() {
    const id = this.get('id');
    if (!id) {
      throw new Error('No id set');
    }
    if (id === AppsComponent.NEW_RETAIL_POS_APP_ID) {
      this.set({
        title: Locale.translate('new_retail_store_channel'),
        link_open: 'sales-channels/create-retail-store',
      });
    } else if (id === AppsComponent.NEW_FOOD_POS_APP_ID) {
      this.set({
        title: Locale.translate('new_food_store_channel'),
        link_open: 'sales-channels/create-food-store',
      });
    } else {
      throw new Error(`Not handled app id=${id}`);
    }

    LinkModel.prototype.initialize.call(this);
  },

  configureModel() {
    if (this.hasFeature()) {
      LinkModel.prototype.configureModel.call(this);

      if (this.get('id') === AppsComponent.NEW_FOOD_POS_APP_ID && this.get('is_installable')) {
        const feature = Feature.getFeatureByAppName(Feature.APP_CORE_SHOP_MODULE_HOSPITALITY);
        const {
          is_not_in_subscription,
          is_installed,
          is_installable,
          is_uninstallable,
          is_upgradable,
          is_visible,
        } = this.calculateSingleFeature(feature);
        this.set({
          is_not_in_subscription,
          is_installed,
          is_installable,
          is_uninstallable,
          is_upgradable,
          is_visible,
        });
      }
    }
  },

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
