define([
  'jquery',
  'module',
  'upx.modules/BlogModule/collections/TranslatedAttributeSet',
  'modules/common/components/locale',

  'modules/shop/collections/AttributeSetAssigns.js',
],
(
  $, Module, UpxCollection, Locale,
  AttributeSetAssignCollection,
) => {
  const Collection = UpxCollection.extend({

    offline: false,

    loadParameters: {
      start: 0,
      limit: 500,
      lang: Locale.getLocale(),
      sort: [
        { name: 'id', dir: 'asc' },
      ],
    },

    /**
   *  Checks if the attribute exists with a similar alias, returns false when not loaded.
   * @return {boolean}
   */
    existsByAlias(alias) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        alias,
      });
    },

    fetchAttributesByAttribute(attribute_id) {
      const def = new $.Deferred();
      $.when(
        this.load(),
        AttributeSetAssignCollection.load(),
      ).then(() => {
        def.resolve(this.filterAttributesByAttribute(attribute_id));
      }, def.reject);

      return def;
    },

    filterAttributesByAttribute(attribute_id) {
      const filtered = new Collection();
      AttributeSetAssignCollection
        .getAttributeSetIdForAttribute(attribute_id)
        .forEach((attribute_set_id) => {
          const model = this.get(attribute_set_id);
          if (model) {
            filtered.push(model);
          }
        });
      return filtered;
    },

    getDefaultId() {
      // Get the default attribute set
      let attributeSet = this.findWhere({ alias: 'default' });

      // If not found. We just get to the first one as fallback.
      if (!attributeSet) {
        attributeSet = this.first();
      }

      return attributeSet.get('id');
    },
  });

  return new Collection();
});
