define([
  'jquery',
  'underscore',
  'modules/shop/models/App/SupplierEdiIntegrationApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',
], (
  $, _, SupplierEdiIntegrationAppModel,
  Locale, Feature, Upx, AppsComponent,
) => SupplierEdiIntegrationAppModel.extend({

  defaults: _.extend(
    {},
    SupplierEdiIntegrationAppModel.prototype.defaults,
    {
      id: AppsComponent.EDC_INTEGRATION_APP_ID,
      logo: '/images/apps/edc.png',
      title: Locale.translate('edc'),
      summary: Locale.translate('make_orders_using_edc_wholesale_and_deliver_them_straight_to_your_customers'),
      app_name: Feature.APP_NAME_SHOP_MODULE_EDC_INTEGRATION,
      type_module_alias: 'EDC',
    },
  ),

  newIntegration() {
    return Upx.call('ShopModule', 'newEDCIntegration');
  },
}));
