var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"yes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":46}}}))
    + "\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"no",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":45}}}))
    + "\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\r\n    <div class=\"col-md-12\">\r\n        <h4>"
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"we_found_settings_previously_set_for_your_loyalty_program_do_you_want_to_use_your_previous_settings_or_make_a_new_one",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":152}}}))
    + "</h4>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"enable_automatic_point_saving_on_paid_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":80}}}))
    + ":\r\n            <strong>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"automatic_on_invoice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":11,"column":23}}})) != null ? stack1 : "")
    + "            </strong>\r\n        </span>\r\n        <br/>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"autojoin_customer_on_first_points",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":68}}}))
    + ":\r\n            <strong>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"automatic_join") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "            </strong>\r\n        </span>\r\n        <br/>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"give_points_for_discounted_products_on_invoice",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":81}}}))
    + ":\r\n            <strong>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"points_for_discounted_rows") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":33,"column":23}}})) != null ? stack1 : "")
    + "            </strong>\r\n        </span>\r\n        <br/>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"when_earning_loyalty_customers_will_get_for_each_{one_euro}_spend",(depth0 != null ? lookupProperty(depth0,"translateVars") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":38,"column":114}}}))
    + ":\r\n            <strong>\r\n                "
    + alias2(((helper = (helper = lookupProperty(helpers,"earn_on_euro") || (depth0 != null ? lookupProperty(depth0,"earn_on_euro") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"earn_on_euro","hash":{},"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":40,"column":34}}}) : helper)))
    + "\r\n            </strong>\r\n        </span>\r\n        <br/>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"allow_exchanging_points_for_money_in_pos_checkout",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":45,"column":84}}}))
    + ":\r\n            <strong>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allow_points_as_payment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":51,"column":23}}})) != null ? stack1 : "")
    + "            </strong>\r\n        </span>\r\n        <br/>\r\n        <span>\r\n            "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"when_paying_with_loyalty_each_point_will_give",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":56,"column":80}}}))
    + ":\r\n            <strong>\r\n                "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"get_for_point") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":69}}}))
    + "\r\n            </strong>\r\n        </span>\r\n        <br/>\r\n        <div class=\"row\">\r\n            <div class=\"col-md-12\">\r\n                "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"program_text_for_store_clerk_on_pos",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":64,"column":74}}}))
    + ":\r\n            </div>\r\n            <div class=\"col-md-12\">\r\n                "
    + alias2(require("/mnt/d/node_projects/app-backoffice/app/scripts/modules/markdown/templates/helpers/render.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"usage_explanation") : depth0),{"name":"markdown$render","hash":{},"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":67,"column":53}}}))
    + "\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});