define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop/components/feature',
  'backbone.memento',

  'modules/common/components/locale',
  'modules/upx/components/upx',
  'toastr',
  'modules/shop/collections/FavouriteApplication',
  'modules/shop/components/popups',
  'modules/shop/views/apps/overview/popup.js',

  'modules/shop.common/components/commerceAuth.js',

  'backbone.deepmodel',
], (
  $, _, Backbone, Feature, Memento,
  Locale, Upx, Toastr, FavouriteApplication, Popups, AppPopupView,
  CommerceAuth,
) => Backbone.DeepModel.extend({

  defaults: {

    icon: 'fal fa-sync',
    title: '',
    summary: '',
    description: '',

    link_open: null,
    link_configure: null,
    limit_reached_text: null,

    app_name: null,
    is_visible: false,
    is_installed: false,
    is_uninstallable: false,
    is_installable: false,
    is_upgradable: false,
    is_not_in_subscription: false,
    is_aggregated: false,
  },

  initialize() {
    _.extend(this, new Memento(this));
    this.store();
    this.setFeature();
    this.configureModel();
  },

  setFeature() {
    this.feature = Feature.getFeatureByAppName(this.get('app_name'));
  },

  hasFeature() {
    return !!this.feature;
  },
  needsFeature() {
    return true;
  },

  getLimitReachedText() {
    return Locale.translate('you_have_reached_the_maximum_amount_of_installations_for_this_app');
  },

  configureModel() {
    if (this.hasFeature()) {
      this.calculatePropertiesForFeature();
    }
  },

  isAllowedByAuth(feature) {
    return CommerceAuth.hasRole(CommerceAuth.ALL_APPS_CAPABILITY)
      || CommerceAuth.hasFeature(feature.get('feature_type.name'));
  },

  calculateSingleFeature(feature) {
    let is_installed = feature.get('active');
    let is_installable = false;
    let is_uninstallable = false;
    let is_upgradable = false;
    const is_auth_passed = this.isAllowedByAuth(feature);
    let limit_reached_text = null;
    const is_aggregated = feature.get('feature_type.is_aggregated');

    if (feature.get('feature_type.is_value_based')) {
      is_installed = false;
      is_installable = feature.get('available');
      is_upgradable = feature.get('upgradable');
      if (is_installable
          && feature.has('value_int') && feature.has('last_metric.value_int')
          && feature.get('last_metric.value_int') >= feature.get('value_int')
      ) {
        is_installable = false;
        is_upgradable = true;

        console.warn('App limit is reached', {
          last_metric: feature.get('last_metric.value_int'),
          limit: feature.get('value_int'),
          app_name: feature.get('feature_type.name'),
        });
        limit_reached_text = this.getLimitReachedText();
      }
    } else {
      is_installable = !feature.get('is_more_than_one')
          && !feature.get('feature_type.is_aggregated')
          && feature.get('available');
      is_upgradable = !is_installed && feature.get('upgradable');
    }
    if (is_installed) {
      is_uninstallable = !feature.get('active_when_available')
          && !feature.get('feature_type.is_aggregated')
          && !feature.get('is_more_than_one');
    }
    const is_not_in_subscription = !feature.get('available');
    const is_visible = is_installed
        || feature.get('upgradable')
        || feature.get('available');
    return {
      is_auth_passed,
      is_not_in_subscription,
      is_installed,
      is_installable,
      is_uninstallable,
      is_upgradable,
      is_visible,
      is_aggregated,
      limit_reached_text,
    };
  },

  calculatePropertiesForFeature() {
    const calculated = this.calculateSingleFeature(this.feature);
    this.set(calculated);
  },

  hasFavouriteButton() {
    return this.get('is_installed');
  },

  isFavourite() {
    return FavouriteApplication.isFavourite(this.get('id'));
  },

  toggleFavourite() {
    const def = new $.Deferred();
    const id = this.get('id');
    if (this.isFavourite()) {
      FavouriteApplication.removeFavourite(id)
        .then(def.resolve, def.reject);
    } else {
      FavouriteApplication.addFavourite(id)
        .then(def.resolve, def.reject);
    }
    def.then(() => {
      this.trigger('change:is_favourite');
    });
    return def.promise();
  },

  canQuickActions() {
    return this.get('is_installed') || this.get('is_installable') || this.get('is_upgradable');
  },

  getQuickActionText() {
    if (this.get('is_installed')) {
      return Locale.translate('open');
    }
    if (this.get('is_installable')) {
      return Locale.translate('install_and_open');
    }
    if (this.get('is_upgradable')) {
      return Locale.translate('upgrade');
    }
    return Locale.translate('install_and_open');
  },

  getQuickActionLink() {
    if (
      (this.get('is_installed') || this.get('is_installable'))
        && this.get('link_open')
    ) {
      return this.get('link_open');
    }
    return null; // null will open the popup instead
  },

  getSalesEmail() {
    return 'info@storekeeper.nl';
  },

  getExtraButtons() {
    const def = new $.Deferred();
    def.resolve([]);
    return def.promise();
  },

  buildExtraButton({
    title, iconCls = null, btnCls = 'btn-info', link = null,
  }) {
    return {
      title, iconCls, link, btnCls,
    };
  },

  getSalesTitle() {
    return Locale.translate('request_for_enabling_{title}_for_{account}', {
      title: this.get('title'),
      account: Upx.getAccountName(),
    });
  },

  getSalesMessage() {
    return Locale.translate('hello_storekeepern_i_m_interested_in_{title}_app_for_my_storekeeper_account_naccount_name_is_{account}', {
      title: this.get('title'),
      account: Upx.getAccountName(),
    });
  },

  getSalesMailUrl() {
    return `mailto:${this.getSalesEmail()}?subject=${encodeURIComponent(this.getSalesTitle())}&body=${encodeURIComponent(this.getSalesMessage())}`;
  },

  install() {
    const def = new $.Deferred();
    Feature.changeFeatureStatus(this.get('app_name'), true)
      .then(
        () => {
          Toastr.success(
            Locale.translate('app_{title}_was_installed_successfully', {
              title: this.get('title'),
            }),
          );
          this.restore();
          this.setFeature();
          this.configureModel();
          def.resolve();
        },
        (error) => {
          Toastr.error(
            Locale.translate('failed_to_install_{title}_app', {
              title: this.get('title'),
            }),
            error.error || error.mgs || error.mes,
          );
          def.reject(error);
        },
      );

    return def.promise();
  },

  loadUninstallVars() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  getUninstallContent(uninstallVars = {}) {
    return Locale.translate('are_you_sure_question');
  },

  getUninstallPopupOptions(uninstallVars = {}) {
    return {
      titleText: Locale.translate('are_you_sure_you_want_to_remove_{title}',
        { title: this.get('title') }),
    };
  },

  uninstallPopupWithVars(uninstallVars = {}) {
    const def = new $.Deferred();
    Popups.confirmRemove(
      this.getUninstallContent(uninstallVars),
      this.getUninstallPopupOptions(uninstallVars),
    ).then(
      () => this.uninstall().then(
        def.resolve, def.reject,
      ),
      def.reject,
    );
    return def.promise();
  },

  uninstallPopup() {
    const def = new $.Deferred();
    this.loadUninstallVars().then(
      (uninstallVars) => {
        this.uninstallPopupWithVars(uninstallVars || {})
          .then(def.resolve, def.reject);
      },
      def.reject,
    );
    return def.promise();
  },

  infoPopup(popupOptions = {}, link = null) {
    const def = new $.Deferred();
    const buttonsDef = new $.Deferred();

    let extraButtons = [];
    this.getExtraButtons().then(
      (btns) => {
        extraButtons = btns;
        buttonsDef.resolve();
      },
      (error) => {
        buttonsDef.resolve();
        Toastr.error(
          Locale.translate('failed_to_load_app_configuration'),
          error.error || error.mgs || error.mes,
        );
      },
    );

    buttonsDef.always(
      () => {
        const view = new AppPopupView({
          model: this,
          extraButtons,
          link,
        });
        view.on('action:open', def.resolve);
        Popups.information(view, $.extend(
          {
            titleText: this.get('title'),
            modalSize: 'modal-lg',
            showFooter: false,
            showCancelTop: true,
          },
          popupOptions,
        )).always(def.reject);
      },
    );

    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    Feature.changeFeatureStatus(this.get('app_name'), false)
      .then(
        () => {
          Toastr.success(
            Locale.translate('app_{title}_was_uninstalled_successfully', {
              title: this.get('title'),
            }),
          );
          this.restore();
          this.setFeature();
          this.configureModel();
          def.resolve();
        },
        (error) => {
          Toastr.error(
            Locale.translate('failed_to_uninstall_{title}_app', {
              title: this.get('title'),
            }),
            error.error || error.mgs || error.mes,
          );
          def.reject(error);
        },
      );
    return def.promise();
  },

}));
