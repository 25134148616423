define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/locale',
  'modules/common/components/browserDetection',
  'modules/shop/components/popups',
  'modules/shop.common/components/webMode',
  'modules/shop/components/mode',
], (
  $, _, Backbone, Locale, BrowserDetection, Popups, WebModeComponent, Mode,
) => ({

  check() {
    // Only if the browser is not chrome, is in web mode, or has no mode selected
    if (!BrowserDetection.isChrome && (WebModeComponent.isInWebMode() || Mode.getMode() === null)) {
      let downloadUrl = 'https://www.google.com/chrome/';
      const downloadText = Locale.translate('click_to_download_google_chrome');
      const warning = Locale.translate('storekeeper_works_best_within_the_google_chrome_webbrowser_we_kindly_recommend_you_to_use_google_chrome_for_the_best_user_experience_exclamation');

      if (Locale.getLocale() === 'nl') {
        downloadUrl = 'https://www.google.nl/chrome/';
      }

      const $row = $('<div>').addClass('row');
      $row.append(
        $('<div>').addClass('col-md-3 text-center').append(
          $('<img>').attr('src', 'images/chrome_icon.png').css('width', 100),
        ),
      );

      const text = `${warning}
				<br>
				<br>
				<a class="btn btn-primary" href="${downloadUrl}" target="_blank">${downloadText}</a>`;

      $row.append($('<div>').addClass('col-md-9').append(text));

      // Check if the modal has been shown before
      if (!getCookie('modalShown')) {
        // Show the modal
        Popups.information(() => $row, {
          titleText: Locale.translate('unsupported_browser'),
          showCancel: true,
          showFooter: false,
        });
        // Set the cookie (expires in 2 days)
        setCookie('modalShown', 'true', 2);
      }

      // Function to set a cookie
      function setCookie(name, value, days) {
        const expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
      }

      // Function to get a cookie value
      function getCookie(name) {
        const cookieString = document.cookie;
        const cookies = cookieString.split('; ');
        for (const cookie of cookies) {
          const [cookieName, cookieValue] = cookie.split('=');
          if (cookieName === name) {
            return cookieValue;
          }
        }
        return null;
      }
    }
  },

}));
