define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
  'modules/shop/components/feature',
], (AclRouter, LoggedIn, AppAuthAcl, Feature) => AclRouter.extend({

  appRoutes: {
    conversations: 'list',
    'conversations/create': 'create',
    'conversations/details/:id(/:swapto)': 'details',

    'conversations-type': 'types',
    'conversations-type/create': 'typeCreate',
    'conversations-type/edit/:id': 'typeEdit',
    'conversations-type/delete/:id': 'typeDelete',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl({
      FEATURE_NAME: Feature.APP_NAME_CORE_SUPPORT_MODULE_SUPPORT_REQUEST,
    }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/serviceRequests'], callback);
  },
}));
