define([
  'jquery',
  'underscore',
  'module',
  'upx.modules/BlogModule/collections/TranslatedAttribute',
  'upx.modules/BlogModule/models/Attribute',

  'modules/common/components/locale',
  'modules/common/components/moment',

  'modules/shop/collections/AttributeSetAssigns.js',

  'upx.modules/ImportExportModule/models/SimpleImportJob',
],
(
  $, _, Module, UpxCollection, AttributeModel,
  Locale, Moment,
  AttributeSetAssignCollection,
  SimpleImportJob,
) => {
  const Collection = UpxCollection.extend({

    offline: false,

    lastUpdateField: 'date_updated',

    loadParameters: {
      start: 0,
      limit: 1000,
      lang: Locale.getLocale(),
      sort: [
        { name: 'order', dir: 'asc' },
        { name: 'id', dir: 'asc' },
      ],
    },

    model: AttributeModel,

    loadEncodedAliases() {
      const def = new $.Deferred();
      const attributedDef = new $.Deferred();

      attributedDef.then(() => {
        if (this.loadedEncodedAliases) {
          def.resolve();
        } else {
          const simpleImportJob = new SimpleImportJob();
          simpleImportJob.encodePathStrings({
            strings: this.pluck('name'),
          }).then((encodedStrings) => {
            _.each(encodedStrings, (encodedString, attributeName) => {
              const model = this.findWhere({ name: attributeName });
              if (model) {
                model.set('name_encoded', encodedString);
              }
            });
            this.loadedEncodedAliases = true;
            def.resolve();
          }, def.reject);
        }
      }, def.reject);

      if (this.loaded) {
        attributedDef.resolve();
      } else {
        this.load()
          .then(attributedDef.resolve, attributedDef.resolve);
      }

      return def;
    },
    /**
     * Checks if the attribute exists with a same name, returns false when not loaded.
     * @return {boolean}
     */
    existsByName(name) {
      if (!this.loaded) {
        return false;
      }

      return !!this.findWhere({
        name,
      });
    },

    fetchAttributesByAttributeSet(attribute_set_id) {
      const def = new $.Deferred();
      $.when(
        this.load(),
        AttributeSetAssignCollection.load(),
      ).then(() => {
        def.resolve(this.filterAttributesByAttributeSet(attribute_set_id));
      }, def.reject);

      return def;
    },

    filterAttributesByAttributeSet(attribute_set_id) {
      const filtered = new Collection();
      AttributeSetAssignCollection
        .getAttributesIdForSet(attribute_set_id)
        .forEach((attribute_id) => {
          const model = this.get(attribute_id);
          if (model) {
            filtered.push(model);
          }
        });
      return filtered;
    },

    getFilteredCollection(filter) {
      return new Collection(
        this.filter(filter),
      );
    },

    getIsOptionsCollection() {
      return this.getFilteredCollection(
        (attribute) => attribute.get('is_options') === true,
      );
    },

    makeSelectOptionText(model) {
      const label = model.get('label') || '';
      const name = model.get('name') || '';
      const text = name.toLocaleLowerCase() !== label.toLocaleLowerCase()
        ? `${label} (${name})`
        : label;
      return text;
    },

    makeAttributeSelectOption(model) {
      const text = this.makeSelectOptionText(model);
      return {
        id: model.get('id'),
        text,
      };
    },
  });

  return new Collection();
});
