define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
], (
  $, _, LinkModel,
  Locale, Feature,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: 'new-sitedish-shop',
      logo: '/images/apps/sitedish.jpg',
      title: Locale.translate('new_sitedish_sale_channel'),
      summary: Locale.translate('connect_your_sitedish_account_and_fulfill_the_order_from_pos'),
      link_open: 'apps/create-sitedish-shop',
      app_name: Feature.APP_CORE_ONLINE_FOOD_ORDER_MODULE_SITE_DISH,
    },
  ),

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
