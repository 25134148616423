define([
  'jquery',
  'modules/upx/components/upx',

  'upx.modules/ShopModule/models/LoyaltyProgram',

  'modules/shop/components/feature',
], (
  $, Upx,
  LoyaltyProgramModel,
  Feature,
) => {
  const Model = LoyaltyProgramModel.extend({

    loaded: false,

    loadIfActive(force) {
      let def = new $.Deferred();

      $.when(Feature.load()).then(() => {
        if (Feature.isLoyaltyFeatureEnabled()) {
          def = this.load(force);
        } else {
          def.resolve();
        }
      }, def.reject);

      return def;
    },

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ShopModule', 'listLoyaltyPrograms',
          {
            start: 0,
            limit: 1,
            sort: [{
              name: 'id',
              dir: 'asc',
            }],
          })).then((response) => {
          self.unload();
          if (response.data.length) {
            self.set(response.data[0]);
          }
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load LoyaltyProgramModel!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    isActive() {
      return !!this.get('active');
    },

    get(name) {
      if (!this.loaded) {
        throw new Error('LoyaltyProgramModel is queried before loading');
      }
      return LoyaltyProgramModel.prototype.get.call(this, name);
    },

    unload() {
      this.clear();
    },

    setFromModel(model) {
      this.set({
        cents_per_point_on_invoice: model.get('cents_per_point_on_invoice'),
        automatic_on_invoice: model.get('automatic_on_invoice'),
        automatic_join: model.get('automatic_join'),
        points_for_discounted_rows: model.get('points_for_discounted_rows'),
        allow_points_as_payment: model.get('allow_points_as_payment'),
        points_per_cent_with_payment: model.get('points_per_cent_with_payment'),
        usage_explanation: model.get('usage_explanation'),
      });
    },
  });

  return new Model();
});
