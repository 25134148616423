define([
  'modules/common/components/component.js',
  'backbone',

  'modules/shop/models/shopConfiguration',
  'modules/blog/models/configuration',
  'modules/profile/models/profile',

  'modules/common/components/locale',
],
(
  Component, Backbone,
  ShopConfiguration, BlogConfiguration, ProfileModel,
  Locale,
) => {
  /**
   * This component was initially created to override configs for testability
   */
  const Configuration = Component.extend({

    initialize() {
      this.overwrite = new Backbone.Model({});
    },

    /**
     * should only be used in the e2e and unit tests
     * @return Backbone.Model
     */
    getOverwrites() {
      return this.overwrite;
    },

    /**
     * should only be used in the e2e and unit tests
     * @param overwrites
     */
    setOverwrites(overwrites = {}) {
      this.overwrite.set(overwrites);
    },
    /**
     * should only be used in the e2e and unit tests
     */
    clearOverwrites() {
      this.overwrite.clear();
    },
    getCurrencyIso3() {
      if (this.overwrite.has('currency_iso3')) {
        return this.overwrite.get('currency_iso3');
      }
      return ShopConfiguration.getCurrencyIso3();
    },

    getTranslatorLocale() {
      if (this.overwrite.has('translator_locale')) {
        return this.overwrite.get('translator_locale');
      }

      return Locale.getLocale();
    },

    getCurrencyLocale() {
      if (this.overwrite.has('currency_locale')) {
        return this.overwrite.get('currency_locale');
      }
      let locale = BlogConfiguration.getMainLang();
      if (!locale) {
        locale = 'nl';
        console.warn(`[CURRENCY] No locale loaded, falling back to ${locale}`);
      }
      let countryIso2 = ProfileModel.getCountryIso2();
      if (!countryIso2) {
        countryIso2 = 'NL';
        console.warn(`[CURRENCY] No countryIso2 loaded, falling back to ${locale}`);
      }
      return `${locale}-${countryIso2}`;
    },

    getMainLang() {
      if (this.overwrite.has('lang')) {
        return this.overwrite.get('lang');
      }

      let lang = BlogConfiguration.getMainLang();
      if (!lang) {
        lang = 'nl';
        console.warn(`[LANGUAGE] No language code loaded, falling back to ${lang}`);
      }

      return lang;
    },

  });

  window.MasterConfiguration = new Configuration();

  return window.MasterConfiguration;
});
