define([
  '@storekeeper/sentry',
  'application',
], (SKSentry, Application) => {
  Application.on('config:loaded', (settings) => {
    const sentryConfig = {
      dsn: 'https://30d8f49ae03a46b8ad81c03d66dc336e@sentry.io/1819929',
    };
    const replayConfig = {
      maskAllText: false,
      blockAllMedia: false,
      mutationBreadcrumbLimit: 20000,
      mutationLimit: 25000,
    };

    SKSentry.enableSentry(sentryConfig, replayConfig);
  });

  // Make a public reference
  window.App = Application;

  // Start the application
  Application.start();
});
