define([
  'jquery',
  'module',
  'upx.modules/ProductsModule/collections/PriceListType',
],
(
  $, Module, UpxCollection, Locale, Moment,
) => {
  const model = UpxCollection.extend({

    offline: false,

    lastUpdate: false,

    load() {
      const self = this;
      const def = new $.Deferred();
      // Check if there is a lastUpdate date. if not it has never been fetched
      $.when(
        this.fetch(this._getParams()),
      ).then(
        () => {
          self.loaded = true;
          def.resolve();
        },
        () => {
          def.reject(Locale.translate('failed_to_load_the_pricelisttypes'));
        },
      );

      return def.promise();
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) {
        this.unload();
      }

      $.when(
        this.load(),
      ).then(
        () => {
          def.resolve();
        },
        (response) => {
          def.reject(response);
        },
      );

      return def.promise();
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      };
    },

    /**
         * Unload the collection
         */
    unload() {
      this.loaded = false;
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup(); // clean the cache to not load old profile
      }
    },

    getBasic() {
      return this.findWhere({
        alias: 'Basic',
        module_name: 'ProductsModule',
      });
    },

    getBasicId() {
      const model = this.getBasic();
      if (model) {
        return model.get('id');
      }
    },

    getSingleRelationAssigned() {
      return this.findWhere({
        alias: 'SingleRelationAssigned',
        module_name: 'ProductsModule',
      });
    },

    getSingleRelationAssignedId() {
      const model = this.getSingleRelationAssigned();
      if (model) {
        return model.get('id');
      }
    },

  });

  return new model();
});
