define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/capabilityAuth',
], (AclRouter, LoggedIn, CapabilityAuthAcl) => AclRouter.extend({

  appRoutes: {
    'company-info(/:tabName)': 'companyInfo',
  },

  acls: [
    new LoggedIn(),
    new CapabilityAuthAcl({
      name: CapabilityAuthAcl.COMPANY_INFO_CAPABILITY,
    }),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/companyInformation'], callback);
  },

}));
