define([
  'jquery',
  'upx.modules/ShopModule/models/ShopAddress',
  'modules/upx/components/upx',
  'upx.modules/RelationsModule/models/Relation',
], ($, UpxModule, Upx, RelationModel) => {
  const model = UpxModule.extend({

    offline: true,

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('ShopModule', 'listShopAddresses',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'is_default__=',
              val: true,
            }],
          })).then((response) => {
          self.set(response.data[0]);

          // Fetch the relation data details (this will contain the contact_set)
          const relationDateId = response.data[0].relation_data_id;
          const relationDataModel = new RelationModel({ id: relationDateId, data_id: relationDateId });
          relationDataModel.fetch()
            .then((response) => {
              self.set('relation_data', response);
              self.loaded = true;
              def.resolve();
            }, def.reject);
        }, () => {
          def.reject({
            error: 'Failed to load model!',
          });
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    getCompanyEmail() {
      let email = '';

      if (this.has('relation_data.contact_set.email')) {
        email = this.get('relation_data.contact_set.email');
      }

      email = email.trim();
      return email;
    },

    hasCompanyEmail() {
      return !!this.getCompanyEmail();
    },

    getBillingEmail() {
      let email = '';

      if (this.has('relation_data') && this.has('relation_data.address_billing.contact_set.email')) {
        email = this.get('relation_data.address_billing.contact_set.email');
      }

      email = email.trim();
      return email;
    },

    hasBillingEmail() {
      return !!this.getBillingEmail();
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },
  });

  return new model();
});
