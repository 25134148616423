define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/TodoModule/collections/TaskCategory',
  'modules/common/components/locale',
], (
  $, _, Backbone,
  Collection, Locale,
) => {
  const collection = Collection.extend({

    offline: true,

    loaded: false,

    general: 'General',
    warehouse: 'Warehouse',
    management: 'Management',
    support: 'Support',
    finance: 'Finance',
    sales: 'Sales',

    load() {
      const def = new $.Deferred();

      if (this.loaded) {
        def.resolve();
      } else {
        this.fetchAll()
          .then(def.resolve, def.reject);

        const self = this;
        def.then(
          () => {
            self.processCollection();
            self.loaded = true;
          },
          (response) => {
            self.loaded = false;
            response = Locale.translate('failed_to_load_task_categories');
          },
        );
      }

      return def.promise();
    },

    processCollection() {
      const self = this;
      this.each((model) => {
        self._setIcon(model);
        self._setTitle(model);
      });
    },

    _setIcon(model) {
      const name = model.get('name');
      switch (name) {
        case this.general:
          return model.set('icon', 'fa-folder');
        case this.warehouse:
          return model.set('icon', 'fa-home');
        case this.management:
          return model.set('icon', 'fa-tasks');
        case this.support:
          return model.set('icon', 'fa-question-circle');
        case this.finance:
          return model.set('icon', 'fa-line-chart');
        case this.sales:
          return model.set('icon', 'fa-bar-chart');
        default:
          return model.set('icon', 'fa-folder');
      }
    },

    _setTitle(model) {
      const name = model.get('name');
      switch (name) {
        case this.general:
          return model.set('title', Locale.translate('general'));
        case this.warehouse:
          return model.set('title', Locale.translate('warehouse'));
        case this.management:
          return model.set('title', Locale.translate('management'));
        case this.support:
          return model.set('title', Locale.translate('support'));
        case this.finance:
          return model.set('title', Locale.translate('finance'));
        case this.sales:
          return model.set('title', Locale.translate('sales'));
        default:
          return model.set('title', Locale.translate('task_category'));
      }
    },

    reload() {
      if (this.loaded) {
        this.unload();
      }

      return this.load();
    },

    unload() {
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup();
      }

      this.reset();

      this.loaded = false;
    },

    fetchAll() {
      return this.fetch({
        params: {
          start: 0,
          limit: 0,
        },
        add: true,
        remove: false,
        merge: true,
      });
    },

  });

  return new collection();
});
