define([
        'modules/upx/models/model',
        './structs/CcvLabGiftCardProvider'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'provider_id',
            object: 'CcvLabGiftCardProvider',
            testConnection: function (parameters, ajaxOptions) {
                return this.callObjectMethod('testCcvLabGiftCardProviderConnection',parameters, ajaxOptions);
            },
            getStats: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getCcvLabGiftCardProviderStats',parameters, ajaxOptions);
            },
            getAuthorizationUrl: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getCcvLabGiftCardProviderAuthorizationUrl',parameters, ajaxOptions);
            }
        });
    }
);