define([
        'modules/upx/collections/collection',
        '../models/Parcel',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'ParcelModule',
            object: 'Parcel',
            collection_method: 'listParcels',
            model: Model
        });
    }
);