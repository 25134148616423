define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',
  'modules/upx/components/upx',

  'upx.modules/ShopMarketingModule/collections/MarketingIntegration',
  'upx.modules/ShopMarketingModule/models/MailchimpIntegration',

  'toastr',
], (
  $, _, LinkModel,
  Locale, Feature, AppsComponent, Upx,
  MarketingIntegrationCollection, MailchimpIntegrationModel,
  Toastr,
) => LinkModel.extend({

  ALIAS_MAILCHIMP: 'Mailchimp',

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.MAILCHIMP_APP_ID,
      logo: '/images/apps/mailchimp.jpg',
      icon: 'fab fa-mailchimp',
      app_name: Feature.APP_NAME_CORE_SHOP_MARKETING_MODULE_MAILCHIMP,
      title: Locale.translate('mailchip_email_marketing'),
      summary: Locale.translate('synchronize_all_your_customers_to_mailchip'),
      link_open: 'apps/mailchimp',
    },
  ),

  install() {
    const def = new $.Deferred();

    this.ensureIntegration().then(() => {
      LinkModel.prototype.install.call(this).then(
        def.resolve,
        def.reject,
      );
    }, ({
      error, message, err, msg,
    }) => {
      Toastr.error(error || message || err || msg);
      def.reject();
    });

    return def.promise();
  },

  connect(integration_id) {
    const def = new $.Deferred();

    Upx.call('ShopMarketingModule', 'getMailchimpIntegrationAuthorizationUrl',
      {
        integration_id,
        redirect_url: window.location.href,
      }).then(
      (url) => {
        window.location = url;
        def.resolve();
      },
      def.reject,
    );

    return def.promise();
  },

  testConnection(integration_id) {
    const def = new $.Deferred();

    if (integration_id) {
      Upx.call('ShopMarketingModule', 'testMailchimpIntegrationConnection',
        {
          integration_id,
        }).then(
        def.resolve,
        def.reject,
      );
    } else {
      def.resolve();
    }

    return def.promise();
  },

  ensureIntegration() {
    const def = new $.Deferred();

    $.when(
      this.getIntegration(),
    ).then((mailchimpIntegration) => {
      if (mailchimpIntegration) {
        const model = new MailchimpIntegrationModel(mailchimpIntegration.toJSON());
        def.resolve(model);
      } else {
        const model = new MailchimpIntegrationModel();
        model.save().then(
          (id) => {
            model.set({ id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  getIntegration() {
    const def = new $.Deferred();
    const collection = new MarketingIntegrationCollection();

    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'marketing_integration_type/alias__=',
        val: this.ALIAS_MAILCHIMP,
      }],
    };

    collection.fetch({
      params,
    }).then(() => {
      def.resolve(collection.first());
    }, def.reject);

    return def.promise();
  },

  getUninstallContent() {
    return Locale.translate('are_you_sure_you_want_to_disconnect_this_mailchimp_integration_question');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getIntegration(),
    ).then((integrationModel) => {
      if (integrationModel) {
        const model = new MailchimpIntegrationModel(integrationModel.toJSON());
        $.when(
          !model || model.destroy(),
        )
          .then(
            () => LinkModel.prototype.uninstall.call(this).then(
              def.resolve,
              def.reject,
            ),
            def.reject,
          );
      } else {
        LinkModel.prototype.uninstall.call(this).then(
          def.resolve,
          def.reject,
        );
      }
    });
    return def.promise();
  },
}));
