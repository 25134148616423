define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/customerAuth',
], (AclRouter, LoggedIn, CustomerAuthAcl) => AclRouter.extend({

  appRoutes: {
    suppliers: 'listSuppliers',
    'suppliers/add': 'addSupplier',
    'suppliers/add/fashion-united/:glnId': 'addFashionUnitedSupplier',
    'suppliers/add/order-writer/:glnId': 'addOrderWriterSupplier',

    customers: 'listCustomers',
    'consumers/add': 'addConsumer',
    'companies/add': 'addCompany',

    contacts: 'listContacts',
    'contacts/addConsumer': 'addContactConsumer',
    'contacts/addCompany': 'addContactCompany',

    'relations/details/:id': 'details',
    'relations/details/:id/reload': 'detailsReload', // with advise from Ian
    'relations/details/:id/:tabName': 'details',
    'relations/details/:id/:tabName(/:tabParam1)(/:tabParam2)': 'details',
    'relations/password/:id': 'changePassword',
    'relations/delete/:id': 'remove',
  },

  acls: [
    new LoggedIn(),
    new CustomerAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/relations'], callback);
  },
}));
