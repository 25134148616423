define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/shop/components/apps',
  'modules/upx/components/upx',

  'upx.modules/PaymentModule/collections/CcvLabGiftCardProvider',
  'upx.modules/PaymentModule/models/CcvLabGiftCardProvider',
], (
  $, _, LinkModel,
  Locale, Feature, AppsComponent, Upx,
  CcvLabGiftCardProviderCollection, CcvLabGiftCardProviderModel,
) => LinkModel.extend({

  ALIAS_GROUPCARD: 'GroupCard',

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.CCV_LAB_GIFT_CARD_APP_ID,
      logo: '/images/apps/ccv-lab.jpg',
      icon: 'fal fa-gift-card',
      app_name: Feature.APP_NAME_CORE_PAYMENT_MODULE_CCV_LAB_GIFT_CARD,
      title: Locale.translate('ccv_lab_gift_cards'),
      summary: Locale.translate('accept_payments_from_ccv_lab_gift_cards'),
      link_open: 'apps/ccv-lab-gift-card',
    },
  ),

  testConnection(provider_id) {
    const def = new $.Deferred();

    if (provider_id) {
      Upx.call('PaymentModule', 'testCcvLabGiftCardProviderConnection',
        {
          provider_id,
        }).then(
        def.resolve,
        def.reject,
      );
    } else {
      def.resolve();
    }

    return def.promise();
  },

  connect(provider_id) {
    const def = new $.Deferred();

    Upx.call('PaymentModule', 'getCcvLabGiftCardProviderAuthorizationUrl',
      {
        provider_id,
        redirect_url: window.location.href,
      }).then(
      (url) => {
        window.location = url;
        def.resolve();
      },
      def.reject,
    );

    return def.promise();
  },

  ensureProvider() {
    const def = new $.Deferred();

    $.when(
      this.getProvider(),
    ).then((provider) => {
      if (provider) {
        def.resolve(provider);
      } else {
        const model = new CcvLabGiftCardProviderModel();
        model.save().then(
          (provider_id) => {
            model.set({ provider_id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  getProvider() {
    const def = new $.Deferred();
    const collection = new CcvLabGiftCardProviderCollection();

    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'provider_id',
        dir: 'asc',
      }],
      filters: [{
        name: 'provider/deleted__=',
        val: 0,
      }],
    };

    collection.fetch({
      params,
    }).then(() => {
      def.resolve(collection.first());
    }, def.reject);

    return def.promise();
  },

  getUninstallContent() {
    return Locale.translate('are_you_sure_you_want_to_disconnect_your_ccv_lab_gift_cards_integration_question_mark');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getProvider(),
    ).then((model) => {
      $.when(
        !model || model.destroy(),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },
}));
