define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'company-feed': 'details',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/companyFeed'], callback);
  },
}));
