define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/BillingIntegrationModule/collections/TwinfieldIntegration',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  IntegrationCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.TWINFIELD_INTEGRATION_APP_ID,
      logo: '/images/apps/twinfield-logo.png',
      title: Locale.translate('twinfield_integration'),
      summary: Locale.translate('use_twinfield_for_your_bookkeeping'),
      link_open: 'apps/twinfield',
      app_name: Feature.APP_NAME_CORE_TWINFIELD_INTEGRATION,
    },
  ),

  getUninstallContent() {
    return Locale.translate('it_will_remove_twinfield_connection_together_with_all_configuration_set_on_it_it_will_not_remove_any_data_already_synchronized_to_twinfield_account');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getIntegration(),
    ).then((model) => {
      $.when(
        !model || Upx.call('BillingIntegrationModule', 'deactivateTwinfieldIntegration',
          {
            id: model.get('id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  ensureIntegration() {
    const def = new $.Deferred();

    $.when(
      this.getIntegration(),
    ).then((integrationModel) => {
      if (integrationModel) {
        def.resolve(integrationModel); // there is model
      } else {
        // create new model
        const model = new (IntegrationCollection.prototype.model)();
        model.save().then(
          (id) => {
            model.set({ id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  connect() {
    const def = new $.Deferred();
    this.ensureIntegration()
      .then(
        (model) => {
          Upx.call('BillingIntegrationModule', 'getTwinfieldAuthorizationUrl',
            {
              twinfield_integration_id: model.get('id'),
              redirect_url: window.location.href,
            }).then(
            (url) => {
              window.location = url;
              def.resolve();
            },
            def.reject,
          );
        },
        def.reject,
      );
    return def.promise();
  },

  getIntegration() {
    const def = new $.Deferred();

    const collection = new IntegrationCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
      filters: [{
        name: 'active__=',
        val: '1',
      }],
    };

    collection
      .fetch({ params })
      .then(
        () => {
          def.resolve(collection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
