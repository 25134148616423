define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/productAuth',
], (AclRouter, LoggedIn, ProductAuthAcl) => AclRouter.extend({

  appRoutes: {
    'product-addon-groups': 'list',
    'product-addon-groups/create': 'create',
    'product-addon-groups/delete/:id': 'delete',
    'product-addon-groups/details/:id': 'details',
  },

  acls: [
    new LoggedIn(),
    new ProductAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/productAddonGroups'], callback);
  },

}));
