define([
        'modules/upx/models/model',
        './structs/MarketingIntegration'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopMarketingModule',

            object: 'MarketingIntegration'
        });
    }
);