define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
], (AclRouter, LoggedIn) => AclRouter.extend({

  appRoutes: {
    'order-writer(/:swapTo)': 'orderWriter',
    'order-writer/:swapTo/:glnId(/relation/:supplierId)': 'orderWriter',
  },

  acls: [
    new LoggedIn(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/orderWriter'], callback);
  },
}));
