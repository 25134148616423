define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'upx.modules/BillingIntegrationModule/collections/SnelStartIntegration',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  SnelStartIntegrationCollection,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.SNELSTART_INTEGRATION_APP_ID,
      logo: '/images/apps/snelstart.png',
      title: Locale.translate('snelstart_integration'),
      summary: Locale.translate('synchronize_daily_totals_to_snelstart'),
      link_open: 'apps/snelstart',
      app_name: Feature.APP_NAME_CORE_SNELSTART_INTEGRATION,
    },
  ),

  getUninstallContent() {
    return Locale.translate('disconnecting_will_remove_the_connection_to_snelstart_it_won_t_remove_any_data_from_snelstart_it_needs_to_be_cleaned_manually');
  },

  uninstall() {
    const def = new $.Deferred();
    $.when(
      this.getIntegration(),
    ).then((model) => {
      $.when(
        !model || Upx.call('BillingIntegrationModule', 'deleteSnelStartIntegration',
          {
            id: model.get('id'),
          }),
      )
        .then(
          () => LinkModel.prototype.uninstall.call(this).then(
            def.resolve,
            def.reject,
          ),
          def.reject,
        );
    });
    return def.promise();
  },

  ensureIntegration() {
    const def = new $.Deferred();

    $.when(
      this.getIntegration(),
    ).then((integrationModel) => {
      if (integrationModel) {
        def.resolve(integrationModel); // there is model
      } else {
        // create new model
        const model = new (SnelStartIntegrationCollection.prototype.model)({ client_key: '' });
        model.save().then(
          (id) => {
            model.set({ id });
            def.resolve(model);
          },
          def.reject,
        );
      }
    }, def.reject);
    return def;
  },

  connect() {
    const def = new $.Deferred();
    this.ensureIntegration()
      .then(
        (model) => {
          model.getOauthRedirectUrl({
            id: model.get('id'),
            success_url: window.location.href,
          }).then(
            (url) => {
              window.location = url; // redirect to snelstart
              def.resolve();
            },
            def.reject,
          );
        },
        def.reject,
      );
    return def.promise();
  },

  getIntegration() {
    const def = new $.Deferred();

    const collection = new SnelStartIntegrationCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [{
        name: 'id',
        dir: 'asc',
      }],
    };

    collection
      .fetch({ params })
      .then(
        () => {
          def.resolve(collection.first());
        },
        def.reject,
      );

    return def.promise();
  },

}));
