define([
  'jquery',
  'underscore',
  'upx.modules/ShopModule/collections/ConfigurationAttachedGroup',
  'modules/shop/models/shopConfiguration',

], ($, _, UpxCollection, ShopConfigurationModel) => {
  const collection = UpxCollection.extend({

    offline: true,

    loaded: false,

    /**
         * Load the collection on login
         */
    load() {
      const def = $.Deferred();
      const self = this;

      $.when(
        this.fetch({
          params: {
            start: 0,
            limit: 0,
            filters: [{
              name: 'configuration_id__=',
              val: ShopConfigurationModel.get('id'),
            }],
          },
        }),
      ).then(() => {
        self.loaded = true;
        def.resolve();
      }, () => {
        def.reject('Failed to load the ConfigurationAttachedGroup');
      });
      return def.promise();
    },

    reload() {
      const def = new $.Deferred();

      if (this.loaded) {
        this.unload();
      }

      $.when(
        this.load(),
      ).then(
        () => {
          def.resolve();
        },
        (response) => {
          def.reject(response);
        },
      );
      return def.promise();
    },

    /**
         * Removes collection from cache on logout
         */
    unload() {
      this.loaded = false;
      this.reset();
      this.getCacheStorage().cleanup();
    },

    /**
         * Gets the group_id by alias
         * @param alias
         * @returns group_id
         */
    getIdByAlias(alias) {
      const group = this.getByAlias(alias);
      return group ? group.get('group_id') : null;
    },
    /**
         * Gets the group_id by alias
         * @param alias
         * @returns group_id
         */
    getByAlias(alias) {
      let group = null;
      this.each((model) => {
        const types = model.get('group_types') || [];
        types.forEach((type) => {
          if (alias === type.alias) {
            group = model;
            return false;
          }
        });
        if (group) {
          return false;
        }
      });
      return group;
    },
  });

  return new collection();
});
