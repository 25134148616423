define([
  'modules/profile/models/profile',
  'modules/common/components/historyBreadcrumb',
  'modules/common/components/locale',
  'modules/shop/components/feature.js',
  'modules/shop.common/components/commerceAuth.js',
  'application',
], (
  ProfileModel, HistoryBreadcrumb, Locale, Feature, CommerceAuth, App,
) => ({
  init(KEY) {
    // the
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v; let w; let x; let y; let z; o = p[d] = p[d] || {}; o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
          }(v[w]));
        }
        y = e.createElement(n); y.async = !0; y.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      }(window, document, 'script', 'pendo'));
    }(KEY));
  },

  setUser() {
    try {
      const role = CommerceAuth.getUserLevelRole();
      let accountCreateDate = Feature.getInstallDate();
      const relationCreateDate = new Date(ProfileModel.get('relation_data.date_created'));
      if (accountCreateDate > relationCreateDate) {
        accountCreateDate = relationCreateDate;
      }
      // see docs: https://developers.pendo.io/docs/?bash#functions
      // guide: https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo#initialization-0-6
      pendo.initialize({
        visitor: {
          id: ProfileModel.get('relation_data_profile.shortname'),
          email: ProfileModel.getProfileEmail(),
          full_name: ProfileModel.getFullProfileName(),
          role,
          app_locale: Locale.getLocale(),
          creationDate: new Date(ProfileModel.get('relation_data_profile.date_created')),
        },

        account: {
          id: App.settings.upx.account,
          name: ProfileModel.getProfileCompanyName(),

          subscription_name: Feature.getSubscriptionName(),
          subscription_id: Feature.getSubscriptionTechnicalName(),

          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel: Feature.getSubscriptionName(),
          // planPrice:    // Optional
          creationDate: accountCreateDate,
        },
      });
      console.log('[Pendo.io] initialized');
    } catch (e) {
      console.error('[Pendo.io] initialize failed', e);
    }
  },
}));
