define([
  'jquery',
  'upx.modules/BillingModule/models/Configuration',
  'modules/upx/components/upx',
  'modules/common/components/moment',
], ($, CommerceConfig, Upx, Moment) => {
  const model = CommerceConfig.extend({

    offline: true,

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        $.when(Upx.call('BillingModule', 'listConfigurations',
          {
            start: 0,
            limit: 1,
            filters: [{
              name: 'published__=',
              val: true,
            }],
          })).then((response) => {
          self.unload();
          self.set(response.data[0]);
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    calculateFromWt() {
      return this.get('default_calculate_from_wt');
    },

    defaultToWithTax() {
      return this.calculateFromWt();
    },

    setCalculateFromWt(calculate_from_wt) {
      this.set('default_calculate_from_wt', calculate_from_wt);
      return Upx.call('BillingModule', 'updateConfiguration',
        {
          fields: { default_calculate_from_wt: calculate_from_wt },
          id: this.get('id'),
        });
    },

    getExpirationDate(formatting) {
      formatting = formatting || '';
      const date = Moment().add(this.get('default_invoice_expiration_days'), 'days');
      return date.format(formatting);
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },
  });

  return new model();
});
