define([
        'modules/upx/models/model',
        './structs/Payment'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'PaymentModule',
            idAttribute: 'id',
            object: 'Payment',
            getByTrx: function (parameters, ajaxOptions) {
                return this.callObjectMethod('getPaymentByTrx',parameters, ajaxOptions);
            },
            ensureLink: function (parameters, ajaxOptions) {
                return this.callObjectMethod('ensureLinkPayment',parameters, ajaxOptions);
            },
            ensureLinkWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('ensureLinkPaymentWithReturn',parameters, ajaxOptions);
            },
            newCash: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newCashPayment',parameters, ajaxOptions);
            },
            newDirectMerchantBalanceSubtraction: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newDirectMerchantBalanceSubtractionPayment',parameters, ajaxOptions);
            },
            newWeb: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newWebPayment',parameters, ajaxOptions);
            },
            newBankTransactionImport: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newBankTransactionImportPayment',parameters, ajaxOptions);
            },
            newWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newPaymentWithReturn',parameters, ajaxOptions);
            },
            newCustom: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newCustomPayment',parameters, ajaxOptions);
            },
            syncPayment: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncPayment',parameters, ajaxOptions);
            },
            syncWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncPaymentWithReturn',parameters, ajaxOptions);
            },
            cancel: function (parameters, ajaxOptions) {
                return this.callObjectMethod('cancelPayment',parameters, ajaxOptions);
            },
            markExpired: function (parameters, ajaxOptions) {
                return this.callObjectMethod('markPaymentsExpired',parameters, ajaxOptions);
            },
            processProviderWebhookNotifications: function (parameters, ajaxOptions) {
                return this.callObjectMethod('processProviderWebhookNotifications',parameters, ajaxOptions);
            },
            syncOld: function (parameters, ajaxOptions) {
                return this.callObjectMethod('syncOldPayments',parameters, ajaxOptions);
            },
            refund: function (parameters, ajaxOptions) {
                return this.callObjectMethod('refundPayment',parameters, ajaxOptions);
            }
        });
    }
);