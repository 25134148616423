define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'suppliers/order-suggestion/:mode': 'listOrderSuggestion',
    'supplier-orders': 'listOrders',
    'supplier-orders/details/:id': 'viewOrder',
    'supplier-orders/dropshipment/bulk/:id': 'bulkCreateDropshipment',
    'supplier-products': 'listProducts',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/suppliers'], callback);
  },
}));
