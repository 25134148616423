define([
  'application',
  'jquery',
  'modules/common/subscribers/subscriber',
  'modules/profile/events/profile/load',
  'modules/profile/events/profile/afterLoad',
  'modules/upx/collections/users',
  'modules/common/components/locale',
  'modules/shop.common/components/localeSetter',

  'modules/blog/models/configuration',
  'modules/shop/models/shopConfiguration',
  'modules/shop/models/commerceConfiguration',
  'modules/shop/models/AccountInformation.js',

  'modules/shop/models/billingConfiguration',

  'modules/shop/collections/upx/Shops',

  'modules/shop/collections/TranslatedAttribute',
  'modules/shop/collections/TranslatedAttributeSet',

  'modules/shop/collections/ShopConfigurationAttachedGroup',
  'modules/shop/collections/TaxRate',

  'modules/shop/collections/PriceListType',
  'modules/shop/collections/ProductPriceType',
  'modules/shop.common/models/upx/ProductConfiguration',

  'modules/shop/collections/TaskType',
  'modules/shop/collections/TaskCategory',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.common/collections/upx/FeaturedAttribute',

  'modules/shop.common/components/commerceAuth',
  'modules/common/components/historyBreadcrumb',
  'modules/shop/models/upx/DefaultShopAddress',
  'modules/shop/components/googleAnalytics',
  'modules/shop/components/pendo.js',
  'modules/shop/components/feature',
  'modules/shop/collections/upx/Location',

],
(
  App, $, Subscriber, ProfileLoadEvent, ProfileAfterLoadEvent, UserCollection, Locale, LocaleSetter,
  BlogConfigurationModel, ShopConfigurationModel, CommerceConfigurationModel, AccountInformation,
  BillingConfigurationModel,
  ShopsCollection,
  TranslatedAttributeCollection, TranslatedAttributeSetCollection,
  ShopConfigurationAttachedGroup, TaxRateCollection,
  PriceListTypesCollection, ProductPriceTypeCollection, ProductConfiguration,
  TaskTypeCollection, TaskCategoryCollection, DeviceConfig, FeaturedAttributeCollection,
  CommerceAuth, HistoryBreadcrumb, DefaultShopAddressModel, GoogleAnalytics, Pendo, Feature, Location,
) => {
  const onLoggedIn = function (event) {
    const { profile } = event.options;
    const { preloadDef } = this.options;
    const subDef = $.Deferred();
    const user = UserCollection.findWhere({
      active: true,
    });

    event.subDef = subDef;

    if (user !== undefined) {
      const companyName = profile.getProfileCompanyName() || 'Commerce';
      $(document).attr('title', `StoreKeeper | ${companyName}`);

      const onResolve = () => {
        if (AccountInformation.showSpecialAccountBar()) {
          $('body').addClass('header-notice-bar-show');
        }
        subDef.resolve();
      };
      const canLogin = CommerceAuth.hasRole(CommerceAuth.FULL_LOGIN_CAPABILITY);

      if (canLogin) {
        LocaleSetter.setLocaleFromProfile(profile);

        // Initial load
        $.when(
          BlogConfigurationModel.load(),
          ShopConfigurationModel.load(),
          CommerceConfigurationModel.load(),
          BillingConfigurationModel.load(),
          ProductConfiguration.load(),
          FeaturedAttributeCollection.load(),
          AccountInformation.load(),
        ).then(
          () => {
            // Secondary load
            $.when(
              ShopsCollection.load(),
              TaxRateCollection.load(),
              TranslatedAttributeCollection.load(),
              TranslatedAttributeSetCollection.load(),
              ShopConfigurationAttachedGroup.load(),
              PriceListTypesCollection.load(),
              ProductPriceTypeCollection.load(),
              DefaultShopAddressModel.load(),
              Feature.load(),
              Location.load(),
            ).then(
              onResolve,
              subDef.reject,
            );
          },
          subDef.reject,
        );
      } else {
        LocaleSetter.setLocaleFromProfile(profile);
        $.when(Feature.load(), AccountInformation.load()).then(
          onResolve,
          subDef.reject,
        );
      }
    } else {
      subDef.reject();
    }

    $.when(subDef).then(
      preloadDef.resolve,
      preloadDef.reject,
    );
  };

  function afterLogon() {
    // Check default index
    const indexRoute = CommerceAuth.getIndexRoute();
    HistoryBreadcrumb.setIndex(`#${indexRoute}`);

    // Load functions that are not initially needed
    TaskTypeCollection.load();
    TaskCategoryCollection.load();

    GoogleAnalytics.setUser();
    Pendo.setUser();
  }

  return Subscriber.extend({
    events: [{
      event: ProfileLoadEvent,
      action: onLoggedIn,
    }, {
      event: ProfileAfterLoadEvent,
      action: afterLogon,
    }],
  });
});
