define([
  'underscore',
  'hbs/handlebars',
  'modules/common/components/appUrl',
], (_, Handlebars, { getAppRoot }) => {
  const root = getAppRoot();
  function getBase() {
    return root;
  }

  Handlebars.registerHelper('common$appBaseUrl', getBase);
  return getBase;
});
