define([
  'backbone',
  'modules/admin/templates/tasks/progress',
  'modules/common/components/historyBreadcrumb',
],
(Backbone, Template, HistoryBreadcrumb) => Backbone.Marionette.ItemView.extend({
  template: Template,

  ui: {
    percentage: '#percentage',
    message: '#message',
    progressBar: '.progress-bar',
  },

  onShow() {
    const self = this;
    this.refreshInterval = window.setInterval(() => {
      $.when(self.model.fetch()).then(
        () => {
          self.setProgress();
        },
      );
    }, 1000);
  },

  taskDone() {
    HistoryBreadcrumb.goBack();
  },

  setProgress() {
    const self = this;
    const percentage = this.model.get('task_execution_last.perdone');
    const timeEnded = this.model.get('task_execution_last.time_ended');
    const success = this.model.get('task_execution_last.success');

    this.ui.percentage.html(`${percentage} %`);

    if (percentage >= 100 && success) {
      window.clearInterval(this.refreshInterval);
      window.setTimeout(() => {
        self.ui.percentage.html('Task complete!');
        self.ui.message.html('You will be redirected in a brief moment...');

        window.setTimeout(() => {
          self.taskDone();
        }, 3500);
      }, 1000);
    } else if (timeEnded !== undefined && !success) {
      window.clearInterval(this.refreshInterval);
      window.setTimeout(() => {
        self.ui.percentage.html('Task failed!');
        self.ui.message.html(self.model.get('task_execution_last.message'));
        self.ui.progressBar.removeClass('progress-bar-info').addClass('progress-bar-danger');
      }, 1000);
    }
  },

  onDestroy() {
    window.clearInterval(this.refreshInterval);
  },
}));
