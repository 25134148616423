define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'gift-cards': 'list',
    'gift-cards/configure-online': 'configureOnline',
    'gift-cards/add': 'add',
    'gift-cards/:id/edit': 'edit',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/giftCards'], callback);
  },

}));
