define([
  'backbone',

  'modules/common/subscribers/subscriber',
  'modules/admin/events/redirect/homePage',
], (
  Backbone,
  Subscriber, HomePageEvent,
) => {
  const redirectToDashboard = function () {
    Backbone.history.navigate('/', { trigger: true });
  };

  return Subscriber.extend({
    events: [{
      event: HomePageEvent,
      action: redirectToDashboard,
    }],
  });
});
