define([
  'application',
  'jquery',
  'underscore',
  'backbone',
  'modules/upx/components/apiClient',

  'modules/upx/events/user/loggedIn',
  'modules/upx/events/user/beforeLoggedOut',
  'modules/upx/events/user/loggedOut',
  'modules/upx/events/user/refreshSession',

  'modules/common/components/managers/configuration',
  'modules/common/models/idle',
],
(App, $, _, Backbone, Upx,
  LoggedInEvent, BeforeLoggedOutEvent, LoggedOutEvent, RefreshSessionEvent,
  ConfigurationManager, IdleModel) => Backbone.Model.extend({
  /**
             * Default properties
             */
  defaults: {
    user: 'anonymous',
    rights: 'anonymous',
    mode: 'none',
    fully_authenticated: false,
  },

  /**
             * Initalize the model and UPX wrapper
             */
  initialize() {
    this.upx = new Upx();
    this.refreshSessionDeffer = $.Deferred();
    this.refreshSessionDeffer.resolve();
  },

  /**
             * Set the user
             * @param user
             */
  setUser(user) {
    this.set({
      user,
      rights: 'user',
    });
  },

  /**
             * Set the user
             * @param user
             */
  setFullyAuthenticated(authenticated) {
    this.set({
      fully_authenticated: !!authenticated,
    });
  },
  /**
             * Set the subuser
             * @param subaccount
             * @param user
             */
  setSubuser(subaccount, user) {
    this.set({
      subaccount,
      user,
      rights: 'subuser',
    });
  },

  /**
             * Set the e-mail
             * @param email
             * @param tree_root_shortname
             */
  setEmail(email, tree_root_shortname) {
    this.set({
      email,
      tree_root_shortname,
      rights: 'subuser',
    });
  },

  /**
             *
             * @param hash
             * @param longExpiry
             */
  setHash(hash, longExpiry) {
    this.set({
      hash,
      mode: 'hash',
      last_refresh_date: new Date(this.hexdec(hash.substr(0, 8)) * 1000),
      longExpiryHash: !!longExpiry,
    });
  },

  shouldRefreshSession() {
    const model = new IdleModel();
    const lastActive = model.getLastActive();
    const lastRefreshed = this.getLastRefreshDate();

    if (this.get('longExpiryHash')) {
      // daily refreshes if active in the past 30 days
      return this._getDateDiff(lastActive, new Date(), 'days') < 30
                        && this._getDateDiff(lastRefreshed, new Date(), 'days') > 25;
    }

    return this._getDateDiff(lastActive, new Date(), 'minutes') < 30
                    && this._getDateDiff(lastRefreshed, new Date(), 'minutes') > 15;
  },

  _getDateDiff(date1, date2, interval) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    date1 = new Date(date1).getTime();
    date2 = (date2 == 'now') ? new Date().getTime() : new Date(date2).getTime();
    const timediff = date2 - date1;
    if (isNaN(timediff)) return NaN;
    switch (interval) {
      case 'years':
        return date2.getFullYear() - date1.getFullYear();
      case 'months':
        return ((date2.getFullYear() * 12 + date2.getMonth()) - (date1.getFullYear() * 12 + date1.getMonth()));
      case 'weeks':
        return Math.floor(timediff / week);
      case 'days':
        return Math.floor(timediff / day);
      case 'hours':
        return Math.floor(timediff / hour);
      case 'minutes':
        return Math.floor(timediff / minute);
      case 'seconds':
        return Math.floor(timediff / second);
      default:
        return undefined;
    }
  },

  getLastRefreshDate() {
    if (this.has('last_refresh_date')) {
      return new Date(this.get('last_refresh_date'));
    }
    return false;
  },

  hexdec(hexString) {
    hexString = (`${hexString}`)
      .replace(/[^a-f0-9]/gi, '');
    return parseInt(hexString, 16);
  },
  /**
             * Set the password
             * @param password
             */
  setPassword(password) {
    this.set({
      password,
      mode: 'password',
    });
  },

  /**
             * Set the api key
             * @param apikey
             */
  setApiKey(apikey) {
    this.set({
      apikey,
      mode: 'apikey',
    });
  },

  /**
             * Gets the tree_root_shortname
             * @returns {*|String}
             */
  getTreeRootShortname() {
    return this.get('tree_root_shortname');
  },

  save() {
    if (this.collection) {
      // save only if there is collection lined to it
      Backbone.Model.prototype.save.call(this);
      return true;
    }
    return false;
  },
  /**
             *
             * @returns {*}
             */
  getUpxConfiguration() {
    return App.com(ConfigurationManager).get('upx');
  },
  /**
             *
             */
  setServerFromUpxConfiguration() {
    const configuration = this.getUpxConfiguration();
    this.upx.setServer(configuration.get('server'));
    this.upx.setAccount(configuration.get('account'));
    if (configuration.has('client_name')) {
      this.upx.setClientName(configuration.get('client_name'));
    }
  },

  sendEventsOnSuccessfulLogin(deferred, options) {
    const self = this;
    deferred.done(() => {
      self.setFullyAuthenticated(true);
      if (!options.silent) {
        const event = new LoggedInEvent({ user: self });
        event.trigger();
      }
    });
  },
  /**
             * Login
             */
  login(options) {
    options = options || {};
    const self = this;
    const deferred = $.Deferred();
    this.setServerFromUpxConfiguration();

    if (this.has('email')) {
      if (this.get('mode') === 'hash') {
        this._subUserHashLogin(deferred, options);
      } else if (this.get('mode') === 'password') {
        this._subUserPasswordLogin(deferred, options);
      } else {
        this._subUserPasswordLogin(deferred, options);
      }
    } else {
      this.upx.setUser(this.get('user'));

      if (this.get('rights') == 'subuser') {
        this.upx.setSubaccount(this.get('subaccount'));
      }

      if (this.get('mode') == 'password') {
        this.upx.setPassword(this.get('password'));
        $.when(this.upx.call('ManagementModule', 'startSession')).then(
          (response) => {
            self.setHash(response);

            if (self.has('password')) {
              self.unset('password');
            } else if (self.has('apikey')) {
              self.unset('apikey');
            }
            self.save();
            deferred.resolve();
          },
          (error) => {
            deferred.reject(error);
          },
        );
      } else if (this.get('mode') == 'hash') {
        this.upx.setHash(this.get('hash'));
        self.save();
        deferred.resolve();
      } else if (this.get('mode') == 'apikey') {
        this.upx.setApikey(this.get('apikey'));
        self.save();
        deferred.resolve();
      }
      this.sendEventsOnSuccessfulLogin(deferred, options);
    }

    return deferred.promise();
  },

  _extendHash() {
    this.upx.setUser(this.get('user'));
    this.upx.setSubaccount(this.get('subaccount'));
    this.upx.setHash(this.get('hash'));

    const self = this;
    const def = $.Deferred();
    $.when(this.upx.call('ManagementModule', 'refreshSessionWithLongExpiry')).then(
      (response) => {
        self.setHash(response, true);
        def.resolve(response);
      },
      (error) => {
        def.reject(error);
      },
    );
    return def;
  },

  _subUserPasswordLogin(deferred, options) {
    options = options || {};
    this.upx.setAnonymous();

    const self = this;
    const fields = {
      email: this.get('email'),
      password: this.get('password'),
    };

    if (this.has('tree_root_shortname')) {
      fields.tree_root_shortname = this.get('tree_root_shortname');
    }

    $.when(this.upx.call('RelationsModule', 'startSessionByEmail', fields)).then(
      (response) => {
        self.setFullyAuthenticated(true);
        self.setSubuser(response.subaccount, response.user);
        self.setHash(response.hash);
        self.unset('password');
        self.save();

        let def = true;
        if (options.longExpiryHash) {
          def = self._extendHash(deferred);
        }
        $.when(def).then(() => {
          self.save();

          if (!options.silent) {
            const event = new LoggedInEvent({ user: self });
            event.trigger();
          }

          deferred.resolve(response);
        }, deferred.reject);
      },
      (error) => {
        deferred.reject(error);
      },
    );
  },

  _subUserHashLogin(deferred, options) {
    options = options || {};
    this.upx.setAnonymous();

    const self = this;
    const fields = {
      email: this.get('email'),
      hash: this.get('hash'),
    };

    if (this.has('tree_root_shortname')) {
      fields.tree_root_shortname = this.get('tree_root_shortname');
    }

    $.when(this.upx.call('RelationsModule', 'startSessionByEmailWithHash', fields)).then(
      (response) => {
        self.setFullyAuthenticated(true);
        self.setSubuser(response.subaccount, response.user);
        self.setHash(response.hash);
        self.unset('password');
        self.save();

        let def = true;
        if (options.longExpiryHash) {
          def = self._extendHash(deferred);
        }
        $.when(def).then(() => {
          self.save();

          if (!options.silent) {
            const event = new LoggedInEvent({ user: self });
            event.trigger();
          }

          deferred.resolve(response);
        }, deferred.reject);
      },
      (error) => {
        deferred.reject(error);
      },
    );
  },

  /**
             * Logout
             */
  logout(options) {
    options = options || {};
    const deferred = $.Deferred();

    const beforeEvent = new BeforeLoggedOutEvent({
      previousRoute: Backbone.history.location.hash,
    });

    beforeEvent.trigger();

    const eventOptions = {
      previousRoute: Backbone.history.location.hash,
    };

    let shortname = this.get('tree_root_shortname');
    if (undefined !== options.shortname) {
      shortname = options.shortname;
    }
    if (undefined !== shortname) {
      eventOptions.tree_root_shortname = shortname;
    }

    this.destroy();

    const afterEvent = new LoggedOutEvent(eventOptions);
    afterEvent.trigger();
    deferred.resolve(true);

    return deferred.promise();
  },

  isRefreshing() {
    this.fetch(); // synchronous for localstorage
    const refreshing = this.get('refreshingSession');
    if (typeof refreshing === 'number') {
      // check if not permanently locked
      if (refreshing + 30000 < new Date().getTime()) {
        // longer than 30s ago
        this.unlockRefreshing();
        return false;
      }
    }
    return !!refreshing;
  },

  fetch() {
    // only fetch if attached to localStorage
    if (this.collection && this.collection.localStorage) {
      return Backbone.Model.prototype.fetch.apply(this, arguments);
    }
    return true;
  },

  waitUntilDoneRefreshing() {
    return this.refreshSessionDeffer.promise();
  },

  unlockRefreshing() {
    this.set({
      refreshingSession: false,
    });
    this.save();
  },

  lockRefreshing(def) {
    this.set({
      refreshingSession: new Date().getTime(),
    });
    this.save();
    def = def || new $.Deferred();
    def.always(() => {
      this.unlockRefreshing();
    });
    return def;
  },
  /**
             * Refresh the session
             */
  refreshSession() {
    const self = this;
    const deferred = new $.Deferred();

    if (!this.isRefreshing()) {
      // lock the refresh
      this.lockRefreshing(deferred);
      if (this.get('mode') == 'hash') {
        self.setServerFromUpxConfiguration();

        this.upx.setUser(this.get('user'));
        this.upx.setHash(this.get('hash'));

        if (this.get('rights') == 'subuser') {
          this.upx.setSubaccount(this.get('subaccount'));
        }

        let sessionDef;
        if (this.get('longExpiryHash')) {
          sessionDef = this.upx.call('ManagementModule', 'refreshSessionWithLongExpiry');
        } else {
          sessionDef = this.upx.call('ManagementModule', 'refreshSession');
        }

        $.when(sessionDef).then(
          (response) => {
            self.setHash(response);
            self.save();

            const event = new RefreshSessionEvent({ user: self });
            event.trigger();

            deferred.resolve({ user: self });
          },
          (error) => {
            deferred.reject(error);
            console.error(`Refresh session failed: ${error.error}`);
          },
        );
      } else {
        deferred.reject('Could not refresh the session, because the user does not have hash set.');
      }
    } else {
      // wait 5s for other tab to refresh
      let i = 0;
      var timeout = function () {
        setTimeout(() => {
          if (!self.isRefreshing()) {
            deferred.resolve({ user: self });
          } else {
            i++;
            if (i < 50) {
              // wait more
              timeout();
            } else {
              // unlock the refresh session
              deferred.reject('Failed to get the other tab refresh');
            }
          }
        }, 100);
      };
    }
    return this.refreshSessionDeffer = deferred.promise();
  },
}));
