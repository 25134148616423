define([
  'backbone',
  'underscore',
  '@storekeeper/translate',
  'modules/common/events/locale/loaded',
  'translate.json',
],
(Backbone, _,
  { Translator, LiveJSTranslator, WebEditor },
  LoadedEvent, TranslateJson) => {
  const locale = Backbone.Marionette.Controller.extend({
    initialize() {
      const supportedLocales = this.getSupportedLanguages();
      console.log(`[Locale] Supported locales: ${supportedLocales.join(',')}`);

      const live = WebEditor.getModeFromUrlQuery();
      if (live.enabled) {
        this.translator = new LiveJSTranslator(supportedLocales);
        console.log('[LiveJSTranslator] Enabled');
        // no need to add loader in the live mode
        if (live.language) {
          this.translator.forceLocale(live.language);
          console.log(`[LiveJSTranslator] Locale set to ${live.language}`);
        }
        this.editor = new WebEditor(this.translator);
        this.attachEditor();
      } else {
        this.translator = new Translator(supportedLocales);
        this.translator.addLocaleLoader((lang) => require(`locale/${lang}.json`));
      }
    },

    getSupportedLanguages() {
      const supportedLanguages = [TranslateJson.baseLanguage];
      if (TranslateJson.supportedLanguages) {
        for (const i in TranslateJson.supportedLanguages) {
          const lang = TranslateJson.supportedLanguages[i];
          if (supportedLanguages.indexOf(lang) === -1) {
            supportedLanguages.push(lang);
          }
        }
      }
      return supportedLanguages;
    },

    getSupportedLanguageName(lang) {
      if (lang === 'en') return 'English';
      if (lang === 'nl') return 'Nederlands';
      if (lang === 'de') return 'Deutsch';
      if (lang === 'fr') return 'Français (in progress)';
      if (lang === 'it') return 'Italiano (in progress)';
      if (lang === 'pl') return 'Polski (in progress)';
      if (lang === 'es') return 'Español (in progress)';

      console.warn('Unknown locale', lang);
      return lang;
    },

    getSupportedLanguagesForSelect() {
      const langs = this.getSupportedLanguages();
      const list = [];
      langs.forEach(
        (lang) => {
          list.push(
            {
              id: lang,
              text: this.getSupportedLanguageName(lang),
            },
          );
        },
      );
      return list;
    },

    async loadLocale(defaultLocale) {
      let locale = localStorage.getItem('locale');
      if (locale === null || locale === 'null' || locale === 'undefined') {
        if (defaultLocale) {
          locale = defaultLocale;
        } else {
          locale = this.translator.getLocale();
        }
      }
      return this._setTranslatorLocale(locale);
    },
    attachEditor() {
      this.editor.attachLiveEditor(TranslateJson.projectId);
    },
    syncEditor: _.debounce(function () {
      if (this.editor) {
        // there is something to sync
        this.editor.syncHtmlState();
      }
    }, 50),

    async _setTranslatorLocale(locale) {
      this.translator.setLocale(locale);
      this.translator.loadTranslations(locale);
      this.syncEditor();
    },

    async setLocale(locale) {
      localStorage.setItem('locale', locale);
      await this._setTranslatorLocale(locale);

      const ev = new LoadedEvent();
      ev.setOptions({
        locale,
      });
      ev.trigger();
    },

    getLocale() {
      return this.translator.getLocale();
    },

    translate(value, params, locale) {
      if (locale) {
        const locales = this.getSupportedLanguages();
        if (locales.indexOf(locale) !== -1) {
          this.translator.loadTranslations(locale);
        } else {
          console.warn('Unsupported locale requested', locale, 'Supported', locales);
          locale = this.getLocale();
        }
      }

      return this.translator.translate(value, params, locale);
    },

  });

  return new locale();
});
