define([], () => ({

  CASH_MUTATIONS_APP_ID: 'cash-mutations',
  ACTIVITIES_APP_ID: 'activities',
  CCV_PIN_APP_ID: 'ccv-pin',
  FEATURED_BRANDS_APP_ID: 'featured/brands',
  CONTACTS_APP_ID: 'contacts',
  COUPON_CODES_APP_ID: 'coupon-codes',
  CUSTOMER_IMPORT_APP_ID: 'customer-import',
  CUSTOMER_NOTES_IMPORT_APP_ID: 'customer-notes-import',
  LOYALTY_CUSTOMER_POINTS_IMPORT_APP_ID: 'loyalty-customer-points-import',
  GIFT_CARD_IMPORT_APP_ID: 'gift-card-import',
  GIFT_CARDS_APP_ID: 'gift-cards',
  IMPORT_EXPORT_CENTER_APP_ID: 'import-&-export-center',
  INTERNAL_FILES_APP_ID: 'internal-files',
  INTRACOMMUNITY_REPORT_APP_ID: 'intracommunity-report',
  INVOICE_APP_ID: 'invoice',
  COMPANY_FEED_APP_ID: 'company-feed',
  MARGIN_REPORT_APP_ID: 'margin-report',
  PRODUCT_ATTRIBUTE_MERGE_INTO_APP_ID: 'product-attribute/merge-into',
  MERGE_INTO_CONFIGURABLE_APP_ID: 'merge-into-configurable',
  NARROWCAST_TV_APP_ID: 'narrowcast-tv',
  NEW_RETAIL_POS_APP_ID: 'new-retail-pos',
  NEW_FOOD_POS_APP_ID: 'new-food-pos',
  HOSPITALITY_SETTINGS_APP_ID: 'hospitality-settings',
  WINKELSTRAAT_INTEGRATION_APP_ID: 'winkelstraat-integration',
  OUTSTANDING_INVOICES_APP_ID: 'outstanding-invoices',
  PARCEL_OVERVIEW_APP_ID: 'parcel-overview',
  PAYMENT_STATISTICS_APP_ID: 'payment-statistics',
  PAYMENTS_APP_ID: 'payments',
  POS_JOURNALS_APP_ID: 'pos-journals',
  PRODUCT_LABELS_PRINT_APP_ID: 'product-labels-print',
  PRODUCT_ADD_ON_GROUPS_APP_ID: 'product-add-on-groups',
  PRODUCT_ATTRIBUTES_APP_ID: 'product-attributes',
  PRODUCT_ATTRIBUTE_CATEGORIES_APP_ID: 'product-attribute-categories',
  PRODUCT_IMAGE_CATEGORIES_APP_ID: 'product-image-categories',
  PRODUCT_BLUEPRINTS_APP_ID: 'product-blueprints',
  PRODUCT_IMAGE_IMPORT_APP_ID: 'product-image-import',
  PRODUCT_IMPORT_APP_ID: 'product-import',
  PRODUCT_SERIALS_APP_ID: 'product-serials',
  QUOTES_APP_ID: 'quotes',
  REPAIRS_APP_ID: 'repairs',
  SALES_CHANNEL_TURNOVER_OVERVIEW_APP_ID: 'sales-channel-turnover-overview',
  SALES_CHANNELS_APP_ID: 'sales-channels',
  SCHEDULED_DISCOUNTS_APP_ID: 'scheduled-discounts',
  FEATURED_SEASONS_APP_ID: 'featured/seasons',
  STOCK_MANAGER_APP_ID: 'stock-manager',
  STOCK_MUTATIONS_APP_ID: 'stock-mutations',
  REPORT_STOCK_BY_ATTRIBUTE_OPTION_APP_ID: 'report/stock-by-attribute-option',
  SHARED_FILES_APP_ID: 'shared-files',
  STOCK_TURNOVER_APP_ID: 'stock-turnover',
  SUPPLIER_ORDERS_APP_ID: 'supplier-orders',
  SUPPLIER_PRODUCTS_APP_ID: 'supplier-products',
  SUPPLIERS_APP_ID: 'suppliers',
  SUPPORT_APP_ID: 'support',
  TRANSLATION_CENTER_APP_ID: 'translation-center',
  TURNOVER_GROUPS_APP_ID: 'turnover-groups',
  USED_GOODS_APP_ID: 'used-goods',
  OUTSIDE_EUROPE_REPORT_APP_ID: 'outside-europe-report',
  IMAGE_CDN_ID: 'image-cdn-id',

  VELOYD_APP_ID: 'veloyd',
  PARCEL_NL_APP_ID: 'parcel-nl',
  POST_NL_APP_ID: 'post-nl',
  MY_PARCEL_NL_APP_ID: 'my-parcel-nl',
  PAY_NL_APP_ID: 'paynl',
  RABOBANK_SMART_PAY_APP_ID: 'rabobank-smart-pay',
  RABOBANK_LOAN_APP_ID: 'rabobank-loan',
  TWINFIELD_INTEGRATION_APP_ID: 'twinfield-integration',
  FISKALY_APP_ID: 'fiskaly',
  LOYALTY_PROGRAM_APP_ID: 'loyalty-program',
  BOOKKEEPING_EMAIL_INTEGRATION_APP_ID: 'bookkeeping-email-integration',
  SUM_UP_APP_ID: 'sum-up',
  MAILCHIMP_APP_ID: 'mailchimp',
  SNELSTART_INTEGRATION_APP_ID: 'snelstart-integration',
  EXACT_ONLINE_INTEGRATION_APP_ID: 'exact-online-integration',
  FLORA_AT_HOME_INTEGRATION_APP_ID: 'flora-at-home-integration',
  EDC_INTEGRATION_APP_ID: 'edc-integration',
  FASHION_UNITED_INTEGRATION_APP_ID: 'fashion-united-integration',
  PRODUCT_VALUATION_APP_ID: 'product-valuation',
  REPORTS_APP_ID: 'reports',

  NEW_WEBSHOP_WITH_SYNC_APP_ID: 'new-webshop-with-sync',
  NEW_WEBSHOP_WITH_SYNC_MAGENTO2_APP_ID: 'new-webshop-with-sync-magento2',
  NEW_WEBSHOP_WITH_SYNC_WOOCOMMERCE_APP_ID: 'new-webshop-with-sync-woocommerce',
  NEW_WEBSHOP_WITH_SYNC_SHOPTRADER_APP_ID: 'new-webshop-with-sync-shop-trader',
  NEW_MARKETPLACE_BOL_VIA_FLUSE_APP_ID: 'new-marketplace-bol',

  GROUP_CARD_APP_ID: 'group-card',
  CCV_LAB_GIFT_CARD_APP_ID: 'ccv-lab-gift-card',

  SHOP_PRODUCT_FEED_APP_ID: 'shop-product-feed',
  BESTSELLER_INTEGRATION_APP_ID: 'bestseller-integration',
  ORDER_WRITER_INTEGRATION_APP_ID: 'order-writer-integration',

  FEATURED_FASHION_COLLECTIONS_APP_ID: 'featured-fashion-collections',
  FEATURED_ATTRIBUTE_CATEGORIES_APP_ID: 'featured-attribute-categories',
  FEATURED_STYLE_ID_APP_ID: 'featured-style-id',

  ORDER_REQUEST_APP_ID: 'new-order-request',

  STOCK_BALANCING_APP_ID: 'stock-balancing',
  LOCATIONS_APP_ID: 'locations',

  SENDY_NL_APP_ID: 'sendy-nl',
  ORDER_PICKING_APP_ID: 'order-picking',
}));
