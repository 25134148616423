define([
        'modules/upx/collections/collection',
        '../models/RaboBankSmartPayProvider',
    ],
    function (UpxCollection, Model) {
        return UpxCollection.extend({
            module: 'PaymentModule',
            object: 'RaboBankSmartPayProvider',
            collection_method: 'listRaboBankSmartPayProvider',
            model: Model
        });
    }
);