define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
  'modules/shop/acls/capabilityAuth',
  'modules/common/acls/anyAcl.js',
], (AclRouter, LoggedIn, AppAuthAcl, CapabilityAuthAcl, AnyAcl) => AclRouter.extend({

  appRoutes: {
    'sales-channels': 'overview',
    'sales-channels/create-retail-store': 'createRetailStore',
    'sales-channels/create-food-store': 'createFoodStore',
    'sales-channels/details/:shop_id(/:swapTo)': 'details',
    'sales-channels/navigations/:shop_id': 'navigations',
    'sales-channels/reports(/:shop_id)': 'reports',
    'sales-channels/dashboard(/:shop_id)': 'dashboard',
    'sales-channels/pos-buttons(/:shop_id)': 'posButtons',
    'sales-channels/connect_integrations/:sales_channel_uuid/:sales_channel_api_token': 'connectIntegrations',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],
  overwriteAcls: [
    {
      routes: [
        'sales-channels/details/:shop_id(/:swapTo)',
        'sales-channels/reports(/:shop_id)',
        'sales-channels/dashboard(/:shop_id)',
        'sales-channels/pos-buttons(/:shop_id)',
      ],
      acls: [
        new LoggedIn(),
        new AnyAcl({
          acls: [
            new CapabilityAuthAcl({
              name: CapabilityAuthAcl.WEBSHOPS_CAPABILITY,
            }),
            new CapabilityAuthAcl({
              name: CapabilityAuthAcl.STORES_CAPABILITY,
            }),
          ],
        }),
      ],
    },
  ],

  getController(callback) {
    return require(['modules/shop/controllers/salesChannels'], callback);
  },
}));
