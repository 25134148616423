define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/feature',
  'modules/upx/components/upx',
  'modules/shop/components/apps',

  'modules/shop/models/DefaultAdapter.js',
], (
  $, _, LinkModel,
  Locale, Feature, Upx, AppsComponent,
  DefaultAdapterModel,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.IMAGE_CDN_ID,
      icon: 'fa-solid fa-images',
      title: Locale.translate('image_cdn_for_product_files'),
      summary: Locale.translate('use_our_image_cdn_with_automatic_scaling_and_optimization_in_your_webshop'),
      link_open: 'product-image-cdn',
      app_name: Feature.APP_CORE_FILE_MODULE_IMAGE_CDN,
    },
  ),

  getUninstallContent() {
    return Locale.translate('all_image_variants_will_become_unavailable_within_24h_webshops_using_them_will_download_images_locally_from_the_which_can_result_in_higher_webshop_disk_usage');
  },

  calculatePropertiesForFeature() {
    LinkModel.prototype.calculatePropertiesForFeature.call(this);
    const adapter = this.getAdapter();
    if (!adapter) {
      this.set({
        is_visible: false,
      });
      console.warn('ImageCdn app is not visible, because no suitable file adapter was found');
    }
  },

  install() {
    const def = new $.Deferred();
    const adapter = this.getAdapter();
    if (!adapter) {
      def.reject({ error: Locale.translate('no_suitable_file_adapter_found') });
    } else {
      $.when(
        adapter.get('image_scale_cdn_id') || Upx.call('FileModule', 'newS3BaseAdapterCdn',
          {
            id: adapter.get('id'),
          }),
      )
        .then(
          () => {
            $.when(
              DefaultAdapterModel.load({ reload: true }),
              LinkModel.prototype.install.call(this),
            ).then(
              def.resolve,
              def.reject,
            );
          },
          def.reject,
        );
    }
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    const adapter = this.getAdapter();
    if (!adapter) {
      def.reject({ error: Locale.translate('no_suitable_file_adapter_found') });
    } else {
      $.when(
        !adapter.get('image_scale_cdn_id') || Upx.call('FileModule', 'deleteS3BaseAdapterCdn',
          {
            id: adapter.get('id'),
          }),
      )
        .then(
          () => {
            $.when(
              DefaultAdapterModel.load({ reload: true }),
              LinkModel.prototype.uninstall.call(this),
            ).then(
              def.resolve,
              def.reject,
            );
          },
          def.reject,
        );
    }
    return def.promise();
  },

  getAdapter() {
    if (DefaultAdapterModel.get('adapter_type.alias') === 'S3Base') {
      return DefaultAdapterModel;
    }
    return null;
  },

}));
