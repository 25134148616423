define([
  'modules/common/components/currency',
], (Currency) => ({

  earnOnInvoiceConvertForSave(pointsForEuro) {
    if (!pointsForEuro) {
      return 0;
    }
    const pointForCent = pointsForEuro / 100;
    const pointsPerCent = 1 / pointForCent;

    return pointsPerCent;
  },

  earnOnInvoiceConvertForDisplay(centsPerPoint) {
    if (!centsPerPoint) {
      return 0;
    }
    const pointForCent = 1 / centsPerPoint;
    const pointForEuro = pointForCent * 100;

    return +pointForEuro.toFixed(4);
  },

  asPaymentConvertForSave(euroForPointCurrency) {
    const euroForPoint = Currency.currencyToFloat(euroForPointCurrency);
    if (!euroForPoint) {
      return 0;
    }
    const centForPoint = euroForPoint * 100;
    const pointPerCent = 1 / centForPoint;

    return pointPerCent;
  },
  asPaymentConvertForDisplay(pointPerCent) {
    if (!pointPerCent) {
      return (0.0).toFixed(2);
    }
    const centPerPoint = Math.round(1 / pointPerCent);
    const euroPerPoint = centPerPoint / 100;

    return euroPerPoint.toFixed(2);
  },

  pointsValueInEuro(pointPerCent, pointAmount) {
    if (!pointAmount || pointAmount < 0) {
      return (0.0).toFixed(2);
    }
    const cents = Math.floor(pointAmount / pointPerCent);

    return (cents / 100).toFixed(2);
  },

  getSpendToEarRatioInEuro(centsSpend, centsPerPointEarned, pointPerCentExchanged) {
    if (!centsPerPointEarned || !pointPerCentExchanged || !centsSpend || centsSpend < 0) {
      return 0;
    }

    const points = Math.floor(centsSpend / centsPerPointEarned);
    const earnedInCents = Math.floor(points / pointPerCentExchanged);
    const earnedInEuro = earnedInCents / 100;

    return +earnedInEuro.toFixed(2);
  },

}));
