define([
  'jquery',
  'underscore',
  'modules/shop/models/App/LinkApp.js',

  'modules/common/components/locale',
  'modules/shop/components/apps',
  'modules/shop/components/feature',
], (
  $, _, LinkModel,
  Locale, AppsComponent, Feature,
) => LinkModel.extend({

  defaults: _.extend(
    {},
    LinkModel.prototype.defaults,
    {
      id: AppsComponent.NEW_MARKETPLACE_BOL_VIA_FLUSE_APP_ID,
      logo: '/images/apps/bol-com.jpeg',
      title: Locale.translate("app[core.ShopModule.BolViaFluseShopType]title"),
      summary: Locale.translate("app[core.ShopModule.BolViaFluseShopType]summary"),
      description: Locale.translate("app[core.ShopModule.BolViaFluseShopType]description"),
      link_open: 'apps/create-bol-com',
      app_name: Feature.APP_CORE_SHOP_MODULE_BOL_VIA_FLUSE,
    },
  ),

  hasFavouriteButton() {
    return false; // sales channels cannot go to favourites
  },

  install() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

  uninstall() {
    const def = new $.Deferred();
    def.resolve();
    return def.promise();
  },

}));
