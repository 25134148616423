define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.common/components/floatingRegion',
  'modules/common/components/locale',

  'modules/shop/views/modals/simple/view',
], (
  $, _, Backbone,
  FloatingRegion, Locale,
  SimpleModal,
) => ({

  mergeOptions(defaults, content, options) {
    options = options || {};
    defaults = defaults || {};
    content = content || {};
    return $.extend({}, defaults, content, options);
  },

  getContentOptions(content) {
    const contentOptions = {
      contentText: '',
    };
    if (_.isString(content)) {
      // just a text put it inside
      contentOptions.contentText = content;
    } else if (_.isFunction(content)) {
      // treat it as template for  the content
      contentOptions.contentView = new (
        Backbone.Marionette.ItemView.extend({
          template: content,
        })
      )();
    } else if (_.isObject(content) && _.isFunction(content.render)) {
      // looks like a view
      contentOptions.contentView = content;
    } else {
      throw new Error('Cannot detect content for popup. Not a string, function or view');
    }
    return contentOptions;
  },

  /**
   *
   * @param content
   * @param options
   */
  information(content, options) {
    return this.popup(this.mergeOptions({
      showCancel: false,
      titleIcon: false,
      titleText: Locale.translate('information'),
      acceptClass: 'btn btn-success',
      acceptIconCls: 'fal fa-check',
      acceptText: Locale.translate('ok'),
    }, this.getContentOptions(content), options));
  },

  warning(content, options) {
    return this.popup(this.mergeOptions({
      showCancel: false,
      titleIcon: 'fa-solid fa-triangle-exclamation',
      titleText: Locale.translate('warning'),
      acceptClass: 'btn btn-success',
      acceptIconCls: 'fal fa-check',
      acceptText: Locale.translate('ok'),
    }, this.getContentOptions(content), options));
  },
  /**
   *
   * @param content
   * @param options
   */
  confirm(content, options) {
    return this.popup(this.mergeOptions({
      showCancelTop: false,
      titleIcon: false,
      titleText: Locale.translate('confirm'),
      cancelIconCls: 'fal fa-ban',
      cancelText: Locale.translate('cancel'),
      acceptClass: 'btn btn-success',
      acceptIconCls: 'fal fa-check',
      acceptText: Locale.translate('ok'),
    }, this.getContentOptions(content), options));
  },

  edit(content, options) {
    return this.popup(this.mergeOptions({
      showCancelTop: false,
      titleIcon: false,
      titleText: Locale.translate('edit'),
      cancelIconCls: 'fal fa-arrow-left',
      cancelText: Locale.translate('cancel'),
      acceptClass: 'btn btn-success',
      acceptIconCls: 'fal fa-save',
      acceptText: Locale.translate('save'),
    }, this.getContentOptions(content), options));
  },
  /**
   *
   * @param content
   * @param options
   */
  confirmRemove(content, options) {
    return this.popup(this.mergeOptions({
      showCancelTop: false,
      titleIcon: false,
      titleText: Locale.translate('confirm_removal'),
      cancelIconCls: 'fal fa-ban',
      cancelText: Locale.translate('cancel'),
      acceptClass: 'btn btn-danger',
      acceptIconCls: 'fal fa-trash-alt',
      acceptText: Locale.translate('remove'),
    }, options, this.getContentOptions(content)));
  },

  popup(options, ModalView = null) {
    const def = new $.Deferred();

    const regionName = FloatingRegion.makeRegionNameUnique('popup');
    const region = FloatingRegion.getRegion(regionName);
    const view = new (ModalView || SimpleModal)(options);
    region.show(view);
    view.open();

    def.always(() => {
      // Ensure the popup closes to preview disappearing popups
      view.close();
      // Timeout gives the modal time to close, after 500ms it should be gone.
      // and the regions is ready to be cleaned
      setTimeout(() => FloatingRegion.cleanRegion(regionName), 500);
    });

    view.on('modal:accept', () => def.resolve());
    view.on('modal:cancel', () => def.reject());

    return def;
  },

  options(title, options) {
    options = options || [];
    const def = new $.Deferred();

    // Create a simple view on the fly
    const inputView = new (Backbone.Marionette.ItemView.extend({
      template() {
        return `<select class="form-control">${options.map((item) => `<option value="${item.id}">${item.label}</option>`)}</select>`;
      },
      ui: { select: 'select' },
      getInput() {
        const value = this.ui.select.val();
        if (_.isString(value)) {
          return value.trim();
        }
      },
    }))();

    const regionName = FloatingRegion.makeRegionNameUnique('options-popup');
    const region = FloatingRegion.getRegion(regionName);
    const view = new SimpleModal({
      showCancelTop: false,
      titleIcon: false,
      titleText: title,
      contentView: inputView,
      acceptText: Locale.translate('ok'),
    });
    region.show(view);
    view.open();

    def.always(() => {
      // Timeout gives the modal time to close, after 500ms it should be gone.
      // and the regions is ready to be cleaned
      setTimeout(() => FloatingRegion.cleanRegion(regionName), 500);
    });

    view.on('modal:accept', () => {
      def.resolve(inputView.getInput());
    });
    view.on('modal:cancel', () => { def.reject(); });

    return def;
  },

  input(title, value) {
    value = value || '';
    const def = new $.Deferred();

    // Create a simple view on the fly
    const inputView = new (Backbone.Marionette.ItemView.extend({
      template() {
        return `<input class="form-control" placeholder="${title}" value="${value}">`;
      },
      ui: { input: 'input' },
      getInput() {
        const value = this.ui.input.val();
        if (_.isString(value)) {
          return value.trim();
        }
      },
    }))();

    const regionName = FloatingRegion.makeRegionNameUnique('input-popup');
    const region = FloatingRegion.getRegion(regionName);
    const view = new SimpleModal({
      showCancelTop: false,
      titleIcon: false,
      titleText: title,
      contentView: inputView,
      acceptText: Locale.translate('ok'),
    });
    region.show(view);
    view.open();

    def.always(() => {
      // Timeout gives the modal time to close, after 500ms it should be gone.
      // and the regions is ready to be cleaned
      setTimeout(() => FloatingRegion.cleanRegion(regionName), 500);
    });

    view.on('modal:accept', () => {
      def.resolve(inputView.getInput());
    });
    view.on('modal:cancel', () => { def.reject(); });

    return def;
  },

}));
