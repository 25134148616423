define([
  'jquery',
  'upx.modules/CommerceModule/models/AccountInformation',
  'modules/upx/components/upx',
  'modules/common/components/locale',

], ($, MasterModel, Upx, Locale) => {
  const TYPE_ERROR = 'error';
  const TYPE_UNKNOWN = 'unknown';
  const TYPE_TRIAL = 'trial';
  const TYPE_LIVE = 'live';
  const TYPE_DEMO = 'demo';
  const TYPE_STAGING = 'staging';

  const ALL_TYPES = {
    TYPE_ERROR,
    TYPE_UNKNOWN,
    TYPE_TRIAL,
    TYPE_LIVE,
    TYPE_DEMO,
    TYPE_STAGING,
  };

  const Model = MasterModel.extend({
    offline: true,

    loaded: false,

    ...ALL_TYPES,
    ALL_TYPES,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        this.set('type');
        Upx.call('CommerceModule', 'getAccountInformation')
          .then(
            (response) => {
              this.set(response);
              this.loaded = true;
              def.resolve();
            },
            () => {
              this.set('type', TYPE_ERROR);
              this.loaded = true;
              def.resolve();
            },
          );
      } else {
        def.resolve();
      }
      return def.promise();
    },

    getAccountType() {
      return this.get('type') || TYPE_UNKNOWN;
    },

    isLive() {
      return this.getAccountType() === TYPE_LIVE;
    },

    showSpecialAccountBar() {
      return !!this.getSpecialAccountBarText();
    },

    getSpecialAccountBarText() {
      const type = this.getAccountType();
      if (type === TYPE_TRIAL) {
        return Locale.translate('this_is_a_storekeeper_trial_please_contact_us_if_you_have_any_questions');
      }
      if (type === TYPE_ERROR) {
        return Locale.translate('there_was_an_error_loading_account_details_please_contact_us_if_it_continues');
      }
      if (type === TYPE_DEMO) {
        return Locale.translate('you_are_using_a_storekeeper_demonstration_account');
      }
      if (type === TYPE_STAGING) {
        return Locale.translate('you_are_using_a_storekeeper_staging_account');
      }

      return null; // nothing to display
    },

    getSpecialAccountBarLevel() {
      const type = this.getAccountType();
      if (type === TYPE_TRIAL) {
        return 'warning';
      }
      if (type === TYPE_ERROR) {
        return 'error';
      }
      if (type === TYPE_DEMO) {
        return 'default';
      }
      if (type === TYPE_STAGING) {
        return 'warning';
      }

      return null; // nothing to display
    },
    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
      this.loaded = false;
    },
  });

  return new Model();
});
