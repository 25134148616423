define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/productAuth',
], (AclRouter, LoggedIn, ProductAuthAcl) => AclRouter.extend({

  appRoutes: {
    'product-attributes': 'productAttributeOverview',
    'product-attributes/create': 'productAttributeCreate',
    'product-attributes/details/:id': 'productAttribute',
    'product-attributes/details/:id/add-attribute-images': 'productImageAttribute',

    'product-attribute-groups': 'productAttributeGroupOverview',
    'product-attribute-groups/create': 'productAttributeGroupCreate',
    'product-attribute-groups/details/:id': 'productAttributeGroup',

    'product-attribute-categories': 'productAttributeCategoriesOverview',

    'product-attribute/merge-into': 'productAttributeMergeInto',
    'product-attribute/merge-into/process/:cacheId': 'productAttributeMergeIntoProcess',
  },

  acls: [
    new LoggedIn(),
    new ProductAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/productAttributes'], callback);
  },
}));
