define([
  'modules/common/routers/aclRouter',
  'modules/upx/acls/loggedIn',
  'modules/shop/acls/appAuth',
], (AclRouter, LoggedIn, AppAuthAcl) => AclRouter.extend({

  appRoutes: {
    'merge-into-configurable': 'overview',
    'merge-into-configurable/process/:cacheId': 'process',
  },

  acls: [
    new LoggedIn(),
    new AppAuthAcl(),
  ],

  getController(callback) {
    return require(['modules/shop/controllers/mergeIntoConfigurable'], callback);
  },

}));
